// import { decodeJWT } from '../_helpers/auth-jwt';
import { authHeader } from '../_helpers/auth-header';
import Resource from '../_helpers/resource';
const resource = new Resource(process.env.REACT_APP_API_URL);

export const orderService = {

  create: (userId, body = {}) => {
    return resource.post(
      `user/${userId}/order`,
      body,
      authHeader()
    ).then(user => {
      return user;
    });
  },

}
