// import { decodeJWT } from '../_helpers/auth-jwt';
import { authHeader } from '../_helpers/auth-header';
import Resource from '../_helpers/resource';
const resource = new Resource(process.env.REACT_APP_API_URL);

export const creditService = {
  history: (userId) => {
    return resource.get(
      `user/${userId}/history`,
      authHeader()
    ).then(user => {
      return user;
    });
  },
  amount: (userId) => {
    return resource.get(
      `user/${userId}/credit`,
      authHeader(),
      false
    ).then(user => {
      return user;
    });
  },

  crateTransaction: (userId, provider, request = {}) => {
    return resource.post(
      `user/${userId}/credit/${provider}/transaction`,
      request,
      authHeader()
    ).then(user => {
      return user;
    });
  },

}
