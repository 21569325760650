import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Form, Button, Input, Alert } from 'reactstrap';

import classnames from 'classnames';

import { analyticsService } from '../../../_services/analytics.service';
import ECommerceTable from './ECommerceTable';

export default class CompetitorsCustom extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      minsanAnalisys: null,
      minsans: null,
      activeTab: '1',
      minsanRetriveDisable: false,
    };

    this.handleMinsanAnalisys = this.handleMinsanAnalisys.bind(this);
    this.handleMinsanReset = this.handleMinsanReset.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    window.removeLoader();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async handleMinsanAnalisys(e) {
    e.preventDefault();

    const { user, shop } = this.props;
    const { minsans } = this.state;
    const self = this;

    if (!!user.id && !!shop.id && minsans && minsans.length > 6) {
      await this.setState({ minsanRetriveDisable: true });
      await this.setState({ minsanAnalisys: null });

      let codes = minsans.split('\n');
      codes = codes.map((string) => string.split(' ').shift());
      codes = { minsans: codes.slice(0, 100) };

      try {
        let { data: minsanAnalisys } = await analyticsService.minsanAnalisys(user.id, shop.id, codes);
        if (self._isMounted) {
          this.setState({ minsanAnalisys });
        }
      } catch (error) {
        console.error(error);
      } finally {
        await this.setState({ minsans: null });
        await this.setState({ minsanRetriveDisable: false });
      }
    }
  }

  async handleChange(e) {
    await this.setState({ minsans: e.target.value });
    if (this.state.minsans.length > 6) {
      await this.setState({ minsanRetriveDisable: false });
    }
  }

  async handleMinsanReset() {
    await this.setState({ minsans: null });
    await this.setState({ minsanAnalisys: null });
    await this.setState({ minsanRetriveDisable: true });
  }

  render() {
    const { user, shop, searching, paging, info } = this.props;
    const { activeTab, minsans, minsanAnalisys, minsanRetriveDisable } = this.state;

    const payLoad = {
      minsanAnalisys,
      searching,
      paging,
      info,
      activeTab,
    };

    const toggle = (tab) => {
      if (activeTab !== tab) {
        this.setState({ activeTab: tab });
      }
    };

    const renderNavLink = (payLoad, tabId, title) => {
      return (
        <NavItem>
          <NavLink
            className={classnames({
              active: payLoad.activeTab === tabId,
              'text-primary': payLoad.activeTab === tabId,
              disabled: !(!!payLoad.minsanAnalisys && payLoad.minsanAnalisys.length > 1),
            })}
            onClick={() => toggle(tabId)}
            href="#"
          >
            {title}
          </NavLink>
        </NavItem>
      );
    };

    const renderTable = (payLoad, tabId, name, own, other, type) => {
      return (
        <TabPane tabId={tabId}>
          <Row>
            <Col sm="12" className="mT-20">
              {!!payLoad.minsanAnalisys && payLoad.activeTab === tabId ? (
                <ECommerceTable
                  name={name}
                  own={own}
                  other={other}
                  type={type}
                  data={payLoad.minsanAnalisys}
                  searching={payLoad.searching}
                  paging={payLoad.paging}
                  info={payLoad.info}
                />
              ) : (
                <div>
                  <i className="fas fa-spinner fa-spin" /> Caricamento dati
                </div>
              )}
            </Col>
          </Row>
        </TabPane>
      );
    };

    return !user.id || !shop.id ? (
      <Alert color="warning">
        Non hai ancora un negozio associato. <Link to="/shop">Vai al form di creazione.</Link>
      </Alert>
    ) : (
      <div className="row">
        <div className="col-12 mT-10">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title text-muted">Analisi dei prezzi</h4>
              <h6>La consultazione è possibile solo con 100 prodotti per volta.</h6>

              <Form onSubmit={this.handleMinsanAnalisys}>
                <Input
                  id="exampleText"
                  name="text"
                  value={minsans === null ? '' : minsans}
                  onChange={(e) => this.handleChange(e)}
                  type="textarea"
                  placeholder="Copia qui 100 codici minsan"
                />
                <Button className="mT-10" color="success" disabled={minsanRetriveDisable}>
                  Analizza
                </Button>
              </Form>
            </div>

            <div className="card-body">
              {!!minsanAnalisys ? (
                <>
                  <Nav className="mT-10" tabs>
                    {renderNavLink(payLoad, '1', 'Tutti i prodotti')}
                    {renderNavLink(payLoad, '2', 'Prodotti con Miglior prezzo')}
                    {renderNavLink(payLoad, '3', 'Prodotti Vicino al miglior prezzo')}
                    {renderNavLink(payLoad, '4', 'Prodotti Vicino al prezzo medio')}
                    {renderNavLink(payLoad, '5', 'Prodotti Vicino al prezzo massimo')}
                  </Nav>

                  <TabContent activeTab={activeTab}>
                    {renderTable(payLoad, '1', 'all', true, true)}
                    {renderTable(payLoad, '2', 'bestPrice', true, false, '0')}
                    {renderTable(payLoad, '3', 'nearBestPrice', true, false, '1')}
                    {renderTable(payLoad, '4', 'nearAvgPrice', true, false, '2')}
                    {renderTable(payLoad, '5', 'nearMaxPrice', true, false, '3')}
                  </TabContent>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
