import React, { Component } from 'react';

import { Alert } from 'reactstrap';
import { Link } from 'react-router-dom';

import moment from 'moment-timezone';

import { bannerService } from '../../_services/banner.service';
import { commerceUserProductsService } from '../../_services/commerceUserProducts.service';

import BannerStat from './BannerStat';
import BannerElement from './BannerElement';

class Banner extends Component {

  constructor(props) {
    super(props);

    this.state = {
      subscription: undefined,
      products: undefined,
      stats: undefined,
      userProducts: undefined,
      banners: [],
      adding: false,
      activeBanners: 0,
    };

    this.updateBanners = this.updateBanners.bind(this);
    this.deleteBanner = this.deleteBanner.bind(this);
    this.addBanner = this.addBanner.bind(this);
    this.closeAddBanner = this.closeAddBanner.bind(this);
    this.getExpireDate = this.getExpireDate.bind(this);

  }

  addBanner() {
    this.setState({adding: true});
  }

  updateBanners(banner) {
    let banners = this.state.banners;
    if (this.state.banners.find((element, key) => {
      if (element.id === banner.id) {
        banners[key] = banner;
        return true;
      }
      return false;
    }) === undefined) {
      banners.push(banner);
    }
    
    this.setState({banners: banners});
  }

  deleteBanner(id) {
    this.setState({
      banners: this.state.banners.filter( banner => banner.id !== id )
    });
  }

  closeAddBanner(banner = undefined) {
    this.setState({adding: false});
    if (banner !== undefined) {
      const banners = this.state.banners;
      this.setState({banners: banners});
    }
  }

  async componentDidMount() {

    if(this.props.user.id && this.props.shop.id) {

      let [stats, banners, userProducts] = [        
        await bannerService.stats(this.props.user.id, this.props.shop.id),
        await bannerService.list(this.props.user.id, this.props.shop.id),
        await commerceUserProductsService.userProducts(this.props.user.id)
      ];

      banners = banners.map(banner => {
        if (!!!banner.pending) {
          banner.pending = moment().isAfter(banner.to);
        }
        return banner;
      });

      this.setState({ stats });
      this.setState({ banners });
      this.setState({ userProducts });
      window.removeLoader();

    } else {
      window.removeLoader();
    }
  }

  getExpireDate() {
    try {
      if (!!this.state.userProducts.banners.active.data.expire.to) {
        return moment.utc(this.state.userProducts.banners.active.data.expire.to).format('DD/MM/YYYY');
      }      
    } catch (error) {}

    try {
      if (!!this.state.userProducts.subscriptions.expiryAt) {
        return moment.utc(this.state.userProducts.subscriptions.expiryAt).format('DD/MM/YYYY');
      }
    } catch (error) {}

    return null;
  }

  render() {
    return (
      <div>
        {
          this.props.shop.id
            ? <div className="row">
                <div className="col-12 col-md-4 order-md-8 mB-30 mB-md-0">
                  {
                    this.state.stats
                      ? <BannerStat 
                          getExpireDate = { this.getExpireDate }
                          userProducts={ this.state.userProducts }
                          activeBanners={ this.state.banners.filter(value => !!value.active).length }
                          bannersCount={ this.state.banners.length }
                          stats={this.state.stats}
                          />
                      : ''
                  }        
                  <div className="mT-20">
                    {
                      this.state.adding || this.state.banners.length <= 0
                        ? ''
                        : this.state.banners.length >= this.state.stats.max
                            ? <Alert>Numero massimo di banner raggiunto</Alert>
                            : <button className="btn btn-outline-primary btn-block" 
                                onClick={this.addBanner}
                                >
                                <i className="fas fa-plus-circle"></i>&nbsp;
                                Aggiungi banner
                              </button>
                    }                    
                  </div>        
                </div>
                <div className="col-12 col-md-8 order-md-4">
                  <div className="row">
                    {
                      this.state.adding
                        ? <div className="col-12 mB-20">
                            <BannerElement
                              userProducts={ this.state.userProducts }
                              user={ this.props.user }
                              shop={ this.props.shop }
                              from={ this.props.from }
                              to={ this.props.to } 
                              activeBanners={ this.state.banners.filter(value => !!value.active).length }
                              notify={ this.props.notify }
                              stats={this.state.stats}
                              banner={{}}
                              add={ true }
                              closeAddBanner={ this.closeAddBanner }
                              updateBanners={ this.updateBanners } 
                              getExpireDate = { this.getExpireDate }/>
                          </div>
                        : ''
                    }
                    {
                      this.state.banners.length
                        ? this.state.banners
                            .sort((a, b) => a.from - b.from)
                            .sort((a, b) => a.pending - b.pending)
                            .sort((a, b) => b.active - a.active)
                            .map((banner, key) => 
                            <div className="col-12 mB-20" key={banner.id ? banner.id : key}>
                              <BannerElement
                                userProducts={ this.state.userProducts }
                                user={ this.props.user }
                                shop={ this.props.shop }
                                from={ this.props.from }
                                to={ this.props.to } 
                                activeBanners={ this.state.banners.filter(value => !!value.active).length }
                                notify={ this.props.notify }
                                stats={this.state.stats}
                                banner={ banner }
                                updateBanners={ this.updateBanners }
                                deleteBanner={ this.deleteBanner } 
                                getExpireDate = { this.getExpireDate }/>
                            </div>
                          )                  
                        : <div className="col-12 mB-20">
                            <Alert color="info">Crea ora il tuo primo banner.</Alert>
                            <BannerElement
                              userProducts={ this.state.userProducts }
                              user={ this.props.user }
                              shop={ this.props.shop }
                              from={ this.props.from }
                              to={ this.props.to } 
                              activeBanners={ this.state.banners.filter(value => !!value.active).length }
                              notify={ this.props.notify }
                              stats={this.state.stats}
                              banner={{}}
                              add={ true }
                              force={ true }
                              closeAddBanner={ this.closeAddBanner }
                              updateBanners={ this.updateBanners } 
                              getExpireDate = { this.getExpireDate }/>
                          </div>
                    }
                  </div>
                </div>                
              </div> 
            : 
              <div className="row">
                <div className="col">
                  <Alert color="warning">
                    Non hai ancora un negozio associato. <Link to="/shop">Vai al form di creazione.</Link>
                  </Alert>
                </div>
              </div>
        }       
      </div>
    )
  }
}

export default Banner;