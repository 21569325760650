import { authHeader } from '../_helpers/auth-header';
import Resource from '../_helpers/resource';

const resource = new Resource(process.env.REACT_APP_API_URL);

export const shopService = {
  get: (userId, id) => {
    const url = id ? `user/${userId}/shop/${id}` : `user/${userId}/shop`;

    return resource.get(url, authHeader()).then((shop) => {
      if (shop.data) {
        return shop.data;
      }

      return shop;
    });
  },

  create: (userId, request) => {
    return resource.post(`user/${userId}/shop`, request, authHeader()).then((shop) => {
      return shop;
    });
  },

  update: (userId, id, request) => {
    return resource.put(`user/${userId}/shop/${id}`, request, authHeader()).then((shop) => {
      return shop;
    });
  },
};
