import React, { Component } from 'react';

import { Table } from 'reactstrap';
import moment from 'moment';


export default class PriceTable extends Component {

  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      datatables: {
        data: !!this.props.productHistory.data ? this.props.productHistory.data : [],
        fixedHeader: {
          headerOffset: window.$('.navbar').outerHeight()
        },
        columns: [
          {
            data: {
              _:    "data.display",
              sort: "data.timestamp"
            },
          },
          { data: 'minprice', },                  
          { data: 'avgprice', },
          { data: 'maxprice', },  
          { data: 'shopprice', },  
          { data: 'totcount', },
        ],
        searching: !!this.props.searching ? this.props.searching : false,
        paging: !!this.props.paging ? this.props.paging : false,
        info: !!this.props.info ? this.props.info : false,

        columnDefs: [ {
          searchable: false,
          // orderable: false,
          targets: 0
        } ],

        order: [[ 0, 'desc' ]],

        language: {
          "sEmptyTable": "Nessun dato presente nella tabella",
          "sInfo": "Vista da _START_ a _END_ di _TOTAL_ elementi",
          "sInfoEmpty": "Vista da 0 a 0 di 0 elementi",
          "sInfoFiltered": "(filtrati da _MAX_ elementi totali)",
          "sInfoPostFix": "",
          "sInfoThousands": ".",
          "sLengthMenu": "Visualizza _MENU_ elementi",
          "sLoadingRecords": "Caricamento...",
          "sProcessing": "Elaborazione...",
          "sSearch": "Cerca:",
          "sZeroRecords": "La ricerca non ha portato alcun risultato.",
          "oPaginate": {
              "sFirst": "Inizio",
              "sPrevious": "Precedente",
              "sNext": "Successivo",
              "sLast": "Fine"
          },
          "oAria": {
              "sSortAscending": ": attiva per ordinare la colonna in ordine crescente",
              "sSortDescending": ": attiva per ordinare la colonna in ordine decrescente"
          }
        }
      },
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.$.fn.dataTable.ext.errMode = 'none';
    window.$('#productPriceTable')
      .DataTable()
      .destroy(true);
  }

  async componentDidMount() {
    this._isMounted = true;
    window.$.fn.dataTable.ext.errMode = 'none';
    window.$('#productPriceTable').DataTable(this.state.datatables);    
  }  

  shouldComponentUpdate(props) {
    if (!!!props.productHistory.data) {
      return false;
    }

    const table = window.$('#productPriceTable').DataTable();
    let products = props.productHistory.data
      .map((item) => {
        item.data = {
          "display": moment(item.pricedate ).format('DD/MM/YYYY'),
          "timestamp": moment(item.pricedate ).unix()
        };
        item.avgprice = Number((item.avgprice).toFixed(2));
        return item;
      });

    table.clear();
    table.rows.add(products);
    table.draw();
    return false;
  }

  render() {
    return (
      <Table id="productPriceTable" bordered striped hover responsive>
          <thead>
            <tr>
              <th className="text-center">Data</th>
              <th className="text-center">Prezzo minimo</th>              
              <th className="text-center">Prezzo medio</th>
              <th className="text-center">Prezzo massimo</th>
              <th className="text-center">Il tuo prezzo</th>
              <th className="text-center">Numero di offerte</th>
            </tr>
          </thead>
        </Table>
    )
  }

}