import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import { paymentService } from '../../_services/payment.service';
import { shopTaxDetailService } from '../../_services/shopTaxDetail.service';

export class PaymentButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      subscription: false,
      noIva: false,
      provider: 'paypal',
      shopTaxDetail: null,
    };

    this.toggle = this.toggle.bind(this);
    this.paymentSumbit = this.paymentSumbit.bind(this);
    this.handleChangeSubscription = this.handleChangeSubscription.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.getTaxDetail();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async getTaxDetail() {
    if (this._isMounted && this.props.user.id) {
      try {
        let response = await shopTaxDetailService.get(this.props.user.id, this.props.user.id, false);
        this.setState({ shopTaxDetail: response });
        this.setState({ noIva: response.no_iva === '1' ? true : false });
      } catch (error) {
        console.error(error);
        this.setState({ noIva: false });
      }
    }
  }

  async toggle(e) {
    this.setState({ modal: !this.state.modal });
  }

  async paymentSumbit() {
    const { user, unitPrice } = this.props;
    const { modal, provider, subscription, noIva } = this.state;

    if (!!modal && provider) {
      try {
        let payment = await paymentService.cratePayment(user.id, {
          provider: provider,
          quantity: unitPrice.toFixed(2),
          subscription: subscription,
          noIva: noIva,
          userEmail: user.email,
        });

        payment.links.forEach((link, i) => {
          if (link.rel === 'approve') {
            window.location.href = link.href;
          }
        });
      } catch (error) {
        console.error(error);
      }
    }

    this.setState({ modal: !modal });
  }

  async handleChangeSubscription() {
    await this.setState({ subscription: !this.state.subscription });
  }

  render() {
    const { description, quantity, unitPrice, disabled } = this.props;
    const { modal, subscription, noIva, shopTaxDetail } = this.state;

    const Allerts = () => {
      return (
        <div className="sticky-top">
          {!shopTaxDetail && (
            <Alert color="warning">
              Non hai ancora inserito i tuo dati fiscali!&nbsp;
              <Link to="/shop/tax-detail">Fai Click qui per aggiornarli</Link>
            </Alert>
          )}
        </div>
      );
    };

    const CreateModalBody = () => {
      const price = quantity * unitPrice;
      const tax = noIva ? 0.0 : price * process.env.REACT_APP_PAYMENT_IVA;

      return (
        <ModalBody>
          <div className="row">
            <div className="col-5 font-weight-bold">Prodotto:</div>
            <div className="col-7">{description}</div>
            {quantity > 1 && (
              <div>
                <div className="col-5 font-weight-bold">Quantità:</div>
                <div className="col-7">{quantity}</div>
                <div className="col-5 font-weight-bold">Prezzo unitario:</div>
                <div className="col-7">{unitPrice.toFixed(2)} €</div>
                <div className="col-5 font-weight-bold">Prezzo unitario con IVA:</div>
                <div className="col-7">{(unitPrice * process.env.REACT_APP_PAYMENT_IVA).toFixed(2)} €</div>
              </div>
            )}

            <div className="col-12">
              <br />
            </div>

            <div className="col-5 font-weight-bold">Totale:</div>
            <div className="col-7">{price.toFixed(2)} €</div>
            <div className="col-5 font-weight-bold">IVA:</div>
            <div className="col-7">{tax.toFixed(2)} €</div>
            <div className="col-5 font-weight-bold">Totale IVA inclusa:</div>
            <div className="col-7">{(price + tax).toFixed(2)} €</div>
          </div>
          <hr />
          <CreateSubscription />
          <br />
        </ModalBody>
      );
    };

    const CreateSubscription = () => {
      return (
        <>
          <label>
            <input
              type="checkbox"
              id="subscription"
              name="subscription"
              checked={subscription}
              onChange={this.handleChangeSubscription}
            />
            &nbsp;Ripeti questa ricarica ogni 30 giorni
          </label>
        </>
      );
    };

    const CreateModalFooter = () => {
      return (
        <ModalFooter>
          <Button className="btn btn-block" color="primary" onClick={this.paymentSumbit}>
            Acquista
          </Button>
          <Button color="secondary" onClick={this.toggle}>
            Annulla
          </Button>
        </ModalFooter>
      );
    };

    return (
      <div>
        <Allerts />
        <Button color="primary" className="btn btn-primary" onClick={this.toggle} disabled={disabled}>
          Pagamento
        </Button>

        <Modal isOpen={modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Conferma acquisto</ModalHeader>
          <CreateModalBody />
          <CreateModalFooter />
        </Modal>
      </div>
    );
  }
}
