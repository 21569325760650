import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Blockquote from '../../Blockquote/Blockquote';
import { Table, Alert } from 'reactstrap';
import moment from 'moment';
import { UncontrolledTooltip } from 'reactstrap';
import { analyticsService } from '../../../_services/analytics.service';

export default class Transactions extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      transaction_order_limit: 100, //numero di ordini mostrati nella tabella
      datatables: {
        config: {
          fixedHeader: {
            headerOffset: window.$('.navbar').outerHeight(),
          },
          searching: !!this.props.searching ? this.props.searching : false,
          paging: !!this.props.paging ? this.props.paging : false,
          info: !!this.props.info ? this.props.info : false,

          columnDefs: [
            {
              searchable: false,
              orderable: true,
              targets: 0,
            },
          ],
          order: [[0, 'desc']],
          language: {
            sEmptyTable: 'Nessun dato presente nella tabella',
            sInfo: 'Vista da _START_ a _END_ di _TOTAL_ elementi',
            sInfoEmpty: 'Vista da 0 a 0 di 0 elementi',
            sInfoFiltered: '(filtrati da _MAX_ elementi totali)',
            sInfoPostFix: '',
            sInfoThousands: '.',
            sLengthMenu: 'Visualizza _MENU_ elementi',
            sLoadingRecords: 'Caricamento...',
            sProcessing: 'Elaborazione...',
            sSearch: 'Cerca:',
            sZeroRecords: 'La ricerca non ha portato alcun risultato.',
            oPaginate: {
              sFirst: 'Inizio',
              sPrevious: 'Precedente',
              sNext: 'Successivo',
              sLast: 'Fine',
            },
            oAria: {
              sSortAscending: ': attiva per ordinare la colonna in ordine crescente',
              sSortDescending: ': attiva per ordinare la colonna in ordine decrescente',
            },
          },
        },
        rows: [],
      },
    };

    this.retrieveAnalyticsTransactions = this.retrieveAnalyticsTransactions.bind(this);
    this.reinitialiseDataTables = this.reinitialiseDataTables.bind(this);
    this.renderDataTables = this.renderDataTables.bind(this);
    this.checkOrigin = this.checkOrigin.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    this.reinitialiseDataTables();
    await this.retrieveAnalyticsTransactions();
    this.renderDataTables();
    window.removeLoader();
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.$('#productsClickTable').DataTable().destroy(true);
  }

  reinitialiseDataTables() {
    if (window.$.fn.dataTable.isDataTable('#productsClickTable')) {
      let table = window.$('#productsClickTable').DataTable();
      table.destroy();
      return;
    }
  }

  renderDataTables() {
    window.$('#productsClickTable').DataTable(this.state.datatables.config).draw();
  }

  async retrieveAnalyticsTransactions() {
    const self = this;
    if (!!this.props.user.id && !!this.props.shop.id) {
      try {
        let transactions = await analyticsService.transactions(
          this.props.user.id,
          this.props.shop.id,
          this.props.from.toISOString(),
          this.props.to.toISOString(),
          !!this.props.limit ? this.props.limit : null
        );

        let transactions_total = transactions.data.reduce((sum, value) => sum + value.total, 0).toFixed(2);

        let clicks = await analyticsService.click(
          this.props.user.id,
          this.props.shop.id,
          this.props.from.toISOString(),
          this.props.to.toISOString()
        );

        clicks = !!clicks.data ? clicks.data : [];

        let [google_shopping_clicks, prezzifarmaco_clicks] = [
          clicks.reduce((sum, value) => sum + value.jumpto_gs, 0),
          clicks.reduce((sum, value) => sum + value.click - value.jumpto_gs, 0),
        ];

        if (self._isMounted) {
          let datatables = this.state.datatables;
          datatables.rows = transactions.data;

          transactions.info.date.from = moment.utc(transactions.info.date.from);
          transactions.info.date.to = moment.utc(transactions.info.date.to);

          this.setState({ prezzifarmaco_clicks: prezzifarmaco_clicks });
          this.setState({ google_shopping_clicks: google_shopping_clicks });

          this.setState({ transactions: transactions.data });
          this.setState({ transactions_total: transactions_total });
          this.setState({ datatables: datatables });
          this.setState({ date: transactions.info.date });

          let total = transactions.data.length;
          this.setState({ total: total });
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  checkOrigin(origin) {
    switch (origin) {
      case 'google_shopping':
        return (
          <div className="small">
            Da Google Shopping <i className="fas fa-check text-success" />
          </div>
        );

      default:
        return (
          <div className="small">
            Da PrezziFarmaco <i className="fas fa-check text-success" />
          </div>
        );
    }
  }

  render() {
    return (
      <div>
        <UncontrolledTooltip placement="right" target="click">
          Indica il numero di ordini totali nel periodo selezionato
        </UncontrolledTooltip>
        <UncontrolledTooltip placement="right" target="transazionipf">
          Indica le vendite generate grazie a PrezziFarmaco e il totale complessivo dei diversi carrelli.
        </UncontrolledTooltip>
        <UncontrolledTooltip placement="right" target="transazionigs">
          Indica le vendite generate grazie a Google Shopping gestito da PrezziFarmaco e il totale complessivo dei
          diversi carrelli.
        </UncontrolledTooltip>
        <div className="row">
          <div className="col-sm-6 col-md-6 col-lg-4 mT-10">
            <Blockquote color="primary" icon="mouse-pointer">
              <h6>Transazioni totali</h6>
              {!!this.props.limit
                ? `${this.props.limit} dal ${moment(this.props.from).format('DD/MM/YYYY')} al ${moment(
                    this.props.to
                  ).format('DD/MM/YYYY')}`
                : <span>
                    <b>{this.state.total?this.state.total:''}</b> dal {moment(this.props.from).format('DD/MM/YYYY')} al {moment( this.props.to ).format('DD/MM/YYYY')}
                  </span>
              }
              <br />
              { this.state.transactions_total 
                ? <span>Per un totale di&nbsp;<b>{parseFloat(this.state.transactions_total).toLocaleString('it-IT', {style: 'currency',currency: 'EUR'})}</b></span>
                : <span></span>
              }
              <span className="text-info tootTipTopRight" href="#" id="click">
                <i className="far fa-question-circle"></i>
              </span>
            </Blockquote>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-4 mT-10">
            <Blockquote color="success" icon="comment-dollar">
              <h6>Transazioni da PrezziFarmaco</h6>
              {this.state.transactions && this.state.transactions.length && this.state.transactions.length > 0 ? (
                <span>
                  <b>
                    {!!this.state.transactions
                      ? this.state.transactions.reduce((sum, value) => {
                          return value.source === 'prezzifarmaco' ? sum + 1 : sum;
                        }, 0)
                      : 0}
                  </b>
                  , per un tot. di&nbsp;
                  <b>
                    {!!this.state.transactions
                      ? this.state.transactions
                          .reduce((sum, value) => {
                            return value.source === 'prezzifarmaco' ? sum + value.total : sum;
                          }, 0)
                          .toLocaleString('it-IT', {style: 'currency',currency: 'EUR'})
                      : 0}
                    
                  </b>
                  <br />
                  Click {this.state.prezzifarmaco_clicks}, Conversion rate{' '}
                  {!!this.state.transactions && this.state.prezzifarmaco_clicks > 0
                    ? (
                        (this.state.transactions.reduce((sum, value) => {
                          return value.source === 'prezzifarmaco' ? sum + 1 : sum;
                        }, 0) /
                          this.state.prezzifarmaco_clicks) *
                        100
                      ).toFixed(2)
                    : 0}
                  %
                </span>
              ) : (
                <span>Non sono presenti transazioni</span>
              )}
              <span className="text-info tootTipTopRight" href="#" id="transazionipf">
                <i className="far fa-question-circle"></i>
              </span>
            </Blockquote>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-4 mT-10">
            <Blockquote color="success" icon="comment-dollar">
              <h6>Transazioni da Google Shopping</h6>
              {this.state.transactions && this.state.transactions.length && this.state.transactions.length > 0 ? (
                <span>
                  <b>
                    {!!this.state.transactions
                      ? this.state.transactions.reduce((sum, value) => {
                          return value.source === 'google_shopping' ? sum + 1 : sum;
                        }, 0)
                      : 0}
                  </b>
                  , per un tot. di&nbsp;
                  <b>
                    {!!this.state.transactions
                      ? this.state.transactions
                          .reduce((sum, value) => {
                            return value.source === 'google_shopping' ? sum + value.total : sum;
                          }, 0)
                          .toLocaleString('it-IT', {style: 'currency',currency: 'EUR'})
                      : 0}
                    
                  </b>
                  <br />
                  Click {this.state.google_shopping_clicks}, Conversion rate{' '}
                  {!!this.state.transactions && this.state.google_shopping_clicks > 0
                    ? (
                        (this.state.transactions.reduce((sum, value) => {
                          return value.source === 'google_shopping' ? sum + 1 : sum;
                        }, 0) /
                          this.state.google_shopping_clicks) *
                        100
                      ).toFixed(2)
                    : 0}
                  %
                </span>
              ) : (
                <span>Non sono presenti transazioni</span>
              )}
              <span className="text-info tootTipTopRight" href="#" id="transazionigs">
                <i className="far fa-question-circle"></i>
              </span>
            </Blockquote>
          </div>
          {this.props.from.isBetween('2020-07-15', '2020-09-15') ||
          this.props.to.isBetween('2020-07-15', '2020-09-15') ? (
            <div className="col-12 mT-10">
              <Alert color="warning">
                A seguito delle modifiche sulla policy della privacy dei cookie imposto da Google dal 15 Luglio al 15
                Settembre gli indicatori delle transazioni da Prezzifarmaco e Google Shopping potrebbero essere
                inferiori al valore reale.
              </Alert>
            </div>
          ) : null}
        </div>
        <div className="row mT-20">
          <div className="col-12">
            <h4>
              {this.state.datatables.rows.length > this.state.transaction_order_limit
                ? `Ultimi ${this.state.transaction_order_limit} di ${this.state.datatables.rows.length} ordini ricevuti`
                : `Ultimi ${this.state.datatables.rows.length} ordini ricevuti`}
            </h4>
            <Table id="productsClickTable" bordered striped hover responsive>
              <thead>
                <tr>
                  <th className="text-center">Data</th>
                  <th>Prodotti</th>
                  <th className="text-center">Numero prodotti</th>
                  <th className="text-center">Totale</th>
                  <th className="text-center">Sorgente</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.datatables.rows.slice(0, this.state.transaction_order_limit)
                    .map((item, key) => 
                      <tr key={key}>
                        <td className="text-center">N°{ item.shoppingCart }<br/>{ moment(item.date).format('DD/MM/YYYY') }</td>                      
                        <td className="align-middle ">
                          {
                            item.products
                              .map((product, key) =>
                                !!product.description
                                  ? <div key={key} className="row mB-5">
                                      <div className="col-2 text-center">
                                        <img className="productimg" alt={ product.description.nome_prodotto } src={ `https://static.fogliettoillustrativo.net/ws/it/${product.description.nome_immagine}` } />
                                      </div>
                                      <div className="col-10">
                                        <Link to={`/analytics/product/${product.description.codice_ministeriale}`}>
                                          <span> { product.description.nome_prodotto }</span>
                                          {
                                            !!product.description.descrizione_forma
                                              ?
                                                <span className="small">&nbsp; { product.description.descrizione_forma.toLowerCase() }</span>
                                              : null
                                          }
                                          <span className="small text-muted">&nbsp; ({ product.description.codice_ministeriale })</span>
                                          <span className="small text-muted">&nbsp; { product.quantity } x { product.price.toLocaleString('it-IT', {style: 'currency',currency: 'EUR'}) }€</span>
                                          <br/>
                                          <span className="small">{ product.description.descrizione_ditta_1_produttrice }</span>
                                        </Link>
                                      </div>
                                      <hr />
                                    </div>
                                  : <div key={key} className="row mB-5">
                                      <div className="col-2 text-center">
                                        <img className="productimg" alt="prodotto generico" src={ `https://static.prezzifarmaco.it/submodules/prezzifarmaco/assets/images/prodotto_generico_125.png` } />
                                      </div>
                                      <div className="col-10">
                                        <small>Codice Ministeriale non riconosciuto: {product.product}</small>
                                      </div>
                                      <hr />
                                    </div>
                              )
                          }
                        </td>
                        <td className="text-center">{ item.quantity }</td>
                        <td className="text-center">{ Number((item.total).toFixed(2)) }€</td>
                        <td className="text-center">{
                          !!item.source   
                            ? this.checkOrigin(item.source  )
                            : '-' 
                        }</td>
                      </tr>
                    )
                }
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    );
  }
}
