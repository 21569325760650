import React, { Component } from 'react';

import { Alert } from 'reactstrap';
class BannerStat extends Component {

  render() {
    const { stats, activeBanners, bannersCount} = this.props;

    return (
      <div className="bgc-white bd bdrs-3 p-20 mB-20">
        <h6 className="c-grey-900">Banner Nativi</h6>
        <div className="">
          <div className="row">
            <div className="col-12">
              Il banner nativo è uno strumento unico che consente di <b>acquisire visibilità </b>
              anche quando il prodotto consultato dal visitatore <b>non è disponibile in catalogo</b>.<br/>
              Il nostro avanzato motore di ricerca selezionerà 3 prodotti dal catalogo del negozio <b>simili</b> a quello visualizzato dal visitatore.
              <br/>
              Questo sistema consente ad esempio di proporre al visitatore prodotti alternativi come 
              pacchetti promozionali oppure <b>formati diversi</b>.
              <br/>
              <br/>
            </div>
            <div className="col-9">Banner Pubblicati:</div>
            <div className="col-3 text-right">
              { !!activeBanners ? activeBanners : 0} / { stats.activable ? stats.activable : 0}
            </div>            

            <div className="col-9">Banner in Bozza:</div>
            <div className="col-3 text-right">
              { bannersCount ? bannersCount : 0} / { stats.creable ? stats.creable : 0}
            </div>

            {/* <hr />

            <div className="col-9">Massimo numero di banner attivabili:</div>
            <div className="col-3 text-right">
              { stats.activable ? stats.activable : 0}
            </div>

            <div className="col-9">Massimo numero di banner:</div>
            <div className="col-3 text-right">
              { stats.creable ? stats.creable : 0}
            </div> */}
          </div>          
        </div>
        { stats.activable === 0 ? 
        <Alert className="col-12 mT-20 warning">
          Attiva una campagna PayPerClick per poter pubblicare i tuoi banner.
        </Alert>
        : <div className="col-12"></div>
      }
      </div>
    )
  }
}

export default BannerStat;
