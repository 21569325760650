import React from 'react';

import Products from './Products';

export default (props) => (
  <div className="row">
    <div className="col-12">
      <div className="bgc-white bd bdrs-3 p-20 mB-20">
        <h6 className="c-grey-900">Resoconto click </h6>
        <div className="">
          <Products 
            searching
            paging
            info
            blockquote
            table
            { ...props } />
        </div>
      </div>
    </div>
    
  </div>      
)