import { authHeader } from '../_helpers/auth-header';

import Resource from '../_helpers/resource';
const resource = new Resource(process.env.REACT_APP_API_URL);


export const developService = {
  _getUserShop,
  _developSwitchUser,
};

function _getUserShop() {
  return resource.get(
    `_developer/user`,
    authHeader()      
  )
    .catch((error) => {
      return Promise.reject(error);
    });
}

function _developSwitchUser(id) {
  return resource.post(
    `_developer/auth/strategy/password`,
    {shopId: id},
    authHeader()      
  )
    .then(auth => {
      if (auth) {
        localStorage.setItem('auth', JSON.stringify(auth));
      }
      return auth;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
