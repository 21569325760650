import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { shopDetailService } from '../../_services/shopdetail.service';

const ShopBlocked = (props) => {
  const { user, shop, notify } = props;
  const [service, setService] = useState({});

  useEffect(() => {
    if (user.id && shop.id) go();
    window.removeLoader();
  }, []);

  const go = async () => {
    const shopdetail = await shopDetailService.get(user.id, shop.id, 'prezzifarmaco-plus');
    if (!shopdetail.id) return;

    setService({
      id: shopdetail.id,
      requesttime: shopdetail.details.requesttime,
    });
  };

  const requestService = () => {
    sendRequestService({
      details: {
        requesttime: new Date(),
      },
    });
  };

  const cancelRequestService = () => {
    sendRequestService({
      details: {
        requesttime: null,
      },
    });
  };

  const sendRequestService = async (shopdetail) => {
    let resource = () => shopDetailService.create(user.id, shop.id, 'prezzifarmaco-plus', shopdetail);
    if (service.id) resource = () => shopDetailService.update(user.id, shop.id, 'prezzifarmaco-plus', shopdetail);

    try {
      let shopdetail = await resource();
      setService({
        id: shopdetail.id,
        requesttime: shopdetail.details.requesttime,
      });

      notify.success(`Le informazioni sono state aggiornate e inviate allo staff.`);
    } catch (error) {
      console.error(error);
      notify.error(error.message);
    }
  };

  const rendereHeader = () => {
    const style = {
      backgroundBlendMode: 'overlay',
      backgroundImage:
        'url(https://www.prezzifarmaco.it/blog/wp-content/uploads/2020/05/geometric-bg-overlay-01.jpg), linear-gradient(131deg, #58b110 0%, rgba(88, 177, 16, 0.27) 100%)',
    };

    return (
      <div className="bd bdrs-3 pY-20" style={style}>
        <div className="row container mX-a">
          <div className="col-12 col-md-6 align-content-center text-center ">
            <h1 className="text-white">
              <b>Fai crescere la tua farmacia online con Prezzifarmaco</b>
            </h1>

            <p className="text-white mT-30">
              {service.requesttime ? (
                <>
                  <button onClick={cancelRequestService} className="btn btn-outline-warning">
                    Cancella la richiesta inviata il {`${moment(service.requesttime).format('DD/MM/YYYY HH:mm:ss')} `}
                    <i className="fa fa-ban"></i>
                  </button>
                </>
              ) : (
                <button className="btn btn-outline-light" onClick={requestService}>
                  Richiedi l'attivazione al nostro staff <i className="fa fa-arrow-right"></i>
                </button>
              )}
            </p>
          </div>

          <div className="col-12 col-md-6 align-content-center">
            <img
              className="img-fluid mT-20"
              width="362"
              height="250"
              src="https://www.prezzifarmaco.it/blog/wp-content/uploads/2020/07/ecommerce-shopfiy-facebook-ads.png"
              title="ecommerce-shopfiy-facebook-ads"
              alt="ecommerce-shopfiy-facebook-ads"
            />
          </div>
        </div>
      </div>
    );
  };

  const rendereBody = () => {
    return (
      <div className="bgc-white bd bdrs-3 c-grey-900 text-center mB-20">
        <div className="container mX-a">
          {rendereBodyInfo()}
          {rendereBodyAnalytics1()}
          {rendereBodyAnalytics2()}

          <p className="mT-30">
            {service.requesttime ? (
              <>
                Richiesta inviata il {`${moment(service.requesttime).format('DD/MM/YYYY HH:mm:ss')}`}
                <br />
                <button onClick={cancelRequestService} className="btn btn-warning">
                  Cancella la richiesta <i className="fa fa-ban"></i>
                </button>
              </>
            ) : (
              <button className="btn btn-success" onClick={requestService}>
                Richiedi l'attivazione al nostro staff <i className="fa fa-arrow-right"></i>
              </button>
            )}
          </p>
        </div>
      </div>
    );
  };

  const rendereBodyInfo = () => {
    return (
      <div className="mT-30 mB-50">
        <h2 className="h1 mB-30">
          <b>Ottieni il massimo da Prezzifarmaco</b>
        </h2>

        <h3 className="h4 mB-30">
          <b>
            Tutti gli strumenti più avanzati per l’aumento dell'analisi e il web marketing della tua farmacia online in
            un’unica dashboard
          </b>
        </h3>

        <p>
          Ottieni strumenti efficaci per migliorare la gestione del tuo shop e Analizzare i competitor della tua
          farmacia online.
          <br />
          Mettiamo a disposizione dei servizi avanzati, efficaci e flessibili, perché possono essere attivati o
          disattivati in qualsiasi momento e puoi gestirli comodamente dalla tua dashboard, un pannello di gestione
          studiato su misura per te.
        </p>

        <p>
          Raggiungi più persone con i nostri servizi di marketing per farmacie online. Il nostro team di esperti
          fornisce supporto continuo per la crescita degli e commerce dedicati a farmaci e parafarmaci.
        </p>
      </div>
    );
  };

  const rendereBodyAnalytics1 = () => {
    return (
      <div className="row mT-70">
        <div className="col-12 col-md-4">
          <h4 className="h1 mB-50">
            <b>Analisi del tuo negozio</b>
          </h4>

          <p className="h4 mB-30">Controlla e valuta le performance del tuo e-commerce</p>
        </div>

        <div className="col-12 col-md-4 mB-30">
          <div className="h-100 bg-light bd bdrs-3 p-15">
            <img
              className="img-fluid"
              width="250"
              height="250"
              src="https://www.prezzifarmaco.it/blog/wp-content/uploads/2020/07/conversioni-prezzifarmaco-google-shopping-2.png"
              title="conversioniprezzifarmaco"
              alt="conversioniprezzifarmaco"
            />
            <p className="h5 mT-20 mB-10">Conversioni</p>
            <p className="m-0">
              Tieni traccia degli acquisti e analizza il successo del tuo ecommerce in termini di transazioni effettuate
              rispetto ai click.
            </p>
          </div>
        </div>

        <div className="col-12 col-md-4 mB-30">
          <div className="h-100 bg-light bd bdrs-3 p-15">
            <img
              className="img-fluid"
              width="250"
              height="250"
              src="https://www.prezzifarmaco.it/blog/wp-content/uploads/2020/07/analisi-click.png"
              title="conversioniprezzifarmaco"
              alt="conversioniprezzifarmaco"
            />
            <p className="h5 mT-20 mB-10">Analisi dei click</p>
            <p className="m-0">Analizza l’andamento e la sorgente dei tuoi click.</p>
          </div>
        </div>
      </div>
    );
  };

  const rendereBodyAnalytics2 = () => {
    return (
      <div className="row">
        <div className="col-12 col-md-4">
          <h4 className="h1 mB-50">
            <b>Analisi di mercato</b>
          </h4>

          <p className="h4 mB-30">Osserva la concorrenza e studia il tuo posizionamento</p>
          <p className="h4 mB-30">Gestisci le tue offerte in base all’andamento dei competitor</p>
        </div>

        <div className="col-12 col-md-4 mB-30">
          <div className="h-100 bg-light bd bdrs-3 p-15">
            <img
              className="img-fluid"
              width="250"
              height="250"
              src="https://www.prezzifarmaco.it/blog/wp-content/uploads/2020/07/analisi-catalogo-ecommerce-1.png"
              title="conversioniprezzifarmaco"
              alt="conversioniprezzifarmaco"
            />
            <p className="h5 mT-20 mB-10">Analisi del Pricing</p>
            <p className="m-0">Analizza la concorrenza e il posizionamento dei tuoi prezzi sul portale.</p>
          </div>
        </div>

        <div className="col-12 col-md-4 mB-30">
          <div className="h-100 bg-light bd bdrs-3 p-15">
            <img
              className="img-fluid"
              width="250"
              height="250"
              src="https://www.prezzifarmaco.it/blog/wp-content/uploads/2020/07/analisi-spese-di-spedizione.png"
              title="conversioniprezzifarmaco"
              alt="conversioniprezzifarmaco"
            />
            <p className="h5 mT-20 mB-10">Spese di Spedizione</p>
            <p className="m-0">Confronta i tuoi costi di consegna con la concorrenza sul portale.</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="row">
        <div className="col-12 mT-10">
          {rendereHeader()}
          {rendereBody()}
        </div>
      </div>
    </div>
  );
};

export default ShopBlocked;
