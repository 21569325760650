import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import Color from 'color';

import moment from 'moment';

class FeedChart extends Component {
  constructor(props) {
    super(props);

    this.chartColors = {
      red: 'rgb(255, 99, 132)',
      orange: 'rgb(255, 159, 64)',
      yellow: 'rgb(255, 205, 86)',
      green: 'rgb(75, 192, 192)',
      blue: 'rgb(54, 162, 235)',
      purple: 'rgb(153, 102, 255)',
      grey: 'rgb(201, 203, 207)',
    };

    this.state = {
      rendered: false,

      chart: {
        labels: [],
        datasets: [
          {
            label: 'Prodotti validi',
            fill: false,
            borderColor: this.chartColors.blue,
            backgroundColor: Color(this.chartColors.blue).lighten(0.2).rgb(),
          },
          {
            label: 'Offerte pubblicate',
            fill: false,
            borderColor: this.chartColors.red,
            backgroundColor: Color(this.chartColors.red).lighten(0.2).rgb(),
          },
        ],
      },

      options: {
        responsive: true,
        title: {
          display: true,
          text: 'Storico catalogo',
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        legend: {
          position: 'bottom',
        },
        hover: {
          mode: 'nearest',
          intersect: true,
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Data',
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Prodotti',
              },
            },
          ],
        },
      },
    };
  }

  componentDidUpdate() {
    const { history } = this.props;
    const { rendered, chart } = this.state;

    if (!!!history.length || rendered) {
      return;
    }

    this.setState({ rendered: true });

    let sortHistory = history.sort((a, b) => a.date - b.date);

    chart.labels = sortHistory.map((x) => moment(x.createdAt).format('DD/MM/YYYY'));

    chart.datasets[0].data = sortHistory.map((x) => (!!x.lastImport && !!x.lastImport.valid ? x.lastImport.valid : 0));
    chart.datasets[1].data = sortHistory.map((x) =>
      !!x.lastImport && !!x.lastImport.published ? x.lastImport.published : 0
    );
  }

  render() {
    return (
      <div className="bgc-white bd bdrs-3 p-20 mB-20 ">
        <h6 className="c-grey-900">Storico di elaborazione del file di catalogo</h6>
        <div className="">
          <Line
            ref={(reference) => (this.chartReference = reference)}
            data={this.state.chart}
            options={this.state.options}
            height={100}
          />
        </div>
      </div>
    );
  }
}

export default FeedChart;
