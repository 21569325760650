import { authHeader } from '../_helpers/auth-header';
import Resource from '../_helpers/resource';
const resource = new Resource(process.env.REACT_APP_API_URL);

export const commerceUserProductsService = {
  userProducts: (id) => {
    return resource
      .get(`user/${id}/commerce/products`, authHeader())
      .then((products) => {
        return products;
      });
  },

  consumptions: (userId, product) => {
    let api = `user/${userId}/commerce/consumptions/${product}`;
    let queryString = new URLSearchParams();

    api = `${api}?${queryString.toString()}`;

    return resource.get(api, authHeader(), false).then((consumptions) => {
      return consumptions;
    });
  },

  suspend: (userId, id, tier, product) => {
    let api = `user/${userId}/commerce/suspend/${product}/${id}`;
    let queryString = new URLSearchParams();

    api = `${api}?${queryString.toString()}`;

    return resource.get(api, authHeader(), false).then((consumptions) => {
      return consumptions;
    });
  },
};
