import React, { Component } from 'react';
import moment from 'moment';

import CouponElementEdit from './CouponElementEdit';
import CouponChart from './CouponChart';

import { couponService } from '../../_services/coupon.service';

let state = {};

class CouponElement extends Component {
  constructor(props) {
    super(props);

    this.state = window._.merge(
      {
        coupon: {
          // title: this.props.coupon.title ? this.props.coupon.title : undefined,
          code: this.props.coupon.code ? this.props.coupon.code : undefined,
          text: this.props.coupon.text ? this.props.coupon.text : undefined,
          description: this.props.coupon.description ? this.props.coupon.description : undefined,
          discount: this.props.coupon.discount ? this.props.coupon.discount : undefined,
          url: this.props.coupon.url ? this.props.coupon.url : this.props.shop.base_url,
          active: this.props.coupon.active ? this.props.coupon.active : this.props.shop.active,
          from: this.props.coupon.from ? this.props.coupon.from : undefined,
          to: this.props.coupon.to ? this.props.coupon.to : undefined,
        },
        edit: this.props.add ? true : false,
        _coupon: this.props.coupon ? JSON.parse(JSON.stringify(this.props.coupon)) : {},
        disabled: false,
        showBanner: false,
      },
      state
    );

    this.setCoupon = this.setCoupon.bind(this);
    this.updateCoupon = this.updateCoupon.bind(this);
    this.closeCoupon = this.closeCoupon.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleActivation = this.handleActivation.bind(this);
  }

  componentWillUnmount() {
    // state = this.state;
    this.setState({ coupon: undefined });
  }

  setCoupon(key, value) {
    const coupon = this.state.coupon;
    coupon[key] = value;
    this.setState({ coupon: coupon });
  }

  updateCoupon(coupon) {
    this.setState({ _coupon: coupon });
    const replace = {
      coupon: {
        // title: coupon.title,
        code: coupon.code,
        text: coupon.text,
        description: coupon.description,
        discount: coupon.discount,
        url: coupon.url,
        active: coupon.active,
        from: coupon.from,
        to: coupon.to,
      },
    };

    this.setState(replace);
    this.props.updateCoupons(coupon);
    this.closeCoupon(coupon);
  }

  closeCoupon(coupon = undefined) {
    this.setState({
      // title: this.state._coupon.title,
      code: this.state._coupon.code,
      text: this.state._coupon.text,
      description: this.state._coupon.description,
      discount: this.state._coupon.discount,
      url: this.state._coupon.url,
      active: this.state._coupon.active,
      from: this.state._coupon.from,
      to: this.state._coupon.to,
      edit: false,
    });

    if (this.props.add) {
      this.props.closeAddCoupon(coupon);
    }
  }

  handleEdit(e) {
    e.preventDefault();
    this.setState({ edit: !this.state.edit });
    if (!this.state.edit === false) {
      this.closeCoupon();
    }
  }

  async handleActivation(coupon) {
    this.setState({ disabled: true });
    let target = window.event.target;

    try {
      coupon.active = target.checked;
      coupon = await couponService.update(this.props.user.id, this.props.shop.id, coupon.id, coupon);

      if (target.checked !== coupon.active) {
        throw new Error('Impossible to activate');
      }

      target.checked = coupon.active;
      this.setState({ coupon });

      this.props.notify.success(
        `Coupon '${coupon.code}' ${target.checked ? 'pubblicato' : 'rimosso dalla pubblicazione'}.`
      );

      this.updateCoupon(coupon);
    } catch (error) {
      target.checked = false;
      coupon.active = false;
      this.setState({ coupon });
      this.props.notify.warn(`Non è stato possibile aggiornare il coupon.`);
      console.warn(error);
    }

    this.setState({ disabled: false });
  }

  render() {
    const { coupon } = this.props;
    const { name } = this.props.shop;

    const productItemHolder = {
      minHeight: `110px`,
    };

    let showBanner = this.state.showBanner;

    return (
      <div className={`card ${this.state.edit ? 'border-success' : ''}`}>
        <div className="card-body">
          {!this.props.add ? (
            <div className="row mT-5">
              <div className="col-2 col-md-2 order-md-4 text-right">
                <a href="/" className="text-success" onClick={this.handleEdit}>
                  <i className={`fas fa-lg ${!this.state.edit ? 'fa-pen' : 'fa-times'}`}></i>
                </a>
              </div>

              <div className="col-12 col-md-7 order-md-2">
                <span className="switch switch-xs">
                  <input
                    type="checkbox"
                    className="switch"
                    id={`switch_${coupon.id}`}
                    name="active"
                    disabled={this.state.disabled || moment().isAfter(this.props.coupon.to)}
                    defaultChecked={this.state.coupon.active && moment().isBefore(this.props.coupon.to)}
                    onChange={() => this.handleActivation(coupon)}
                  />
                  <label htmlFor={`switch_${coupon.id}`}></label>
                </span>

                {this.state.coupon.active && moment().isBefore(this.props.coupon.to) ? (
                  <span className="text-success small">
                    <i className="fas fa-check"></i>&nbsp; Pubblicato
                  </span>
                ) : (
                  <span className="text-secondary small">
                    <i className="fas fa-times"></i>&nbsp; Non pubblicato
                  </span>
                )}
              </div>

              <div className="col-12 col-md-3 order-md-3">
                <i className="far fa-calendar-check"></i>&nbsp;
                {moment(coupon.from).format('DD/MM/YYYY')}
                &nbsp; - &nbsp;
                <i className="far fa-calendar-times"></i>&nbsp;
                {moment(coupon.to).format('DD/MM/YYYY')}
              </div>
            </div>
          ) : null}

          <div className="row align-items-center border m-5 mT-10" style={productItemHolder}>
            <div className="col-12 col-md-10 mT-10">
              <span className="h3 text-success">
                {this.state.coupon.code ? this.state.coupon.code : <span className="text-muted">Codice coupon</span>}
              </span>
              <br />

              <span className="text-dark">
                {this.state.coupon.text ? this.state.coupon.text : <span className="text-muted">Testo coupon</span>}
              </span>
              <br />

              <span className="small text-muted mB-10">Coupon offerto da {name}</span>
            </div>

            <div className="col-12 col-md-2 text-center mB-10 mB-md-0">
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                }}
                className="small btn btn-success"
              >
                Usa coupon <i className="fa fa-chevron-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>

          {this.state.edit ? (
            <CouponElementEdit
              id={this.props.coupon.id}
              user={this.props.user}
              shop={this.props.shop}
              notify={this.props.notify}
              userProducts={this.props.userProducts}
              force={this.props.force}
              activeCoupons={this.props.activeCoupons}
              stats={this.props.stats}
              create={!!coupon.id}
              coupon={this.state.coupon}
              setCoupon={this.setCoupon}
              updateCoupon={this.updateCoupon}
              closeCoupon={this.closeCoupon}
              deleteCoupon={this.props.deleteCoupon}
            />
          ) : (
            <div className="mT-20">
              <hr />
              <a
                href="/"
                className="small"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ showBanner: !showBanner });
                }}
              >
                {showBanner ? (
                  <span>
                    <i className="fas fa-angle-down" /> Nascondi statistiche
                  </span>
                ) : (
                  <span>
                    <i className="fas fa-angle-right" /> Mostra statistiche
                  </span>
                )}
              </a>

              {showBanner ? (
                <div className="mT-20">
                  <CouponChart
                    id={this.props.coupon.id}
                    user={this.props.user}
                    shop={this.props.shop}
                    from={this.props.from}
                    to={this.props.to}
                    coupon={this.state.coupon}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CouponElement;
