import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Table, UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';

import { analyticsService } from '../../../_services/analytics.service';
import ClicChart from './ClickChart';

import Blockquote from '../../Blockquote/Blockquote';

export default class Products extends Component {

  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      clicks: [],
      datatables: {
        config: {
          dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>"+
          "<'row'<'col-sm-12 col-md-5'><'col-sm-12 col-md-7'B>>",
          buttons: [
            {
                extend: 'csv',
                text: 'Esporta csv',
                exportOptions: {
                  stripHtml: true,
                  format: {
                      body: function ( data, row, column, node ) {
                          return column === 0 ?
                              window.$(data).attr("data-value") :
                               ( !isNaN(parseFloat(data)) && isFinite(data) )? 
                                data :
                                window.$(data).text() ;
                      }
                  }
                }
            }
          ],
          fixedHeader: {
            headerOffset: window.$('.navbar').outerHeight()
          },
          searching: !!this.props.searching ? this.props.searching : false,
          paging: !!this.props.paging ? this.props.paging : false,
          info: !!this.props.info ? this.props.info : false,

          columnDefs: [ {
            searchable: false,
            orderable: false,
            targets: 0
          } ],
          order: [[ 2, 'desc' ]],
          language: {
            "sEmptyTable": "Nessun dato presente nella tabella",
            "sInfo": "Vista da _START_ a _END_ di _TOTAL_ elementi",
            "sInfoEmpty": "Vista da 0 a 0 di 0 elementi",
            "sInfoFiltered": "(filtrati da _MAX_ elementi totali)",
            "sInfoPostFix": "",
            "sInfoThousands": ".",
            "sLengthMenu": "Visualizza _MENU_ elementi",
            "sLoadingRecords": "Caricamento...",
            "sProcessing": "Elaborazione...",
            "sSearch": "Cerca:",
            "sZeroRecords": "La ricerca non ha portato alcun risultato.",
            "oPaginate": {
                "sFirst": "Inizio",
                "sPrevious": "Precedente",
                "sNext": "Successivo",
                "sLast": "Fine"
            },
            "oAria": {
                "sSortAscending": ": attiva per ordinare la colonna in ordine crescente",
                "sSortDescending": ": attiva per ordinare la colonna in ordine decrescente"
            }
          }
        },
        rows: [],
      }
    };

    this.retrieveAnalyticsProducts = this.retrieveAnalyticsProducts.bind(this);    
    this.reinitialiseDataTables = this.reinitialiseDataTables.bind(this);
    this.renderDataTables = this.renderDataTables.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    this.reinitialiseDataTables();
    await this.retrieveAnalyticsProducts();
    this.renderDataTables();
    window.removeLoader();
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.$('#productsClickTable')
      .DataTable()
      .destroy(true);
  }

  reinitialiseDataTables() {
    if ( window.$.fn.dataTable.isDataTable( '#productsClickTable' ) ) {
      let table = window.$('#productsClickTable').DataTable(); 
      table.destroy();
      return;
    }
  }

  renderDataTables() {
    window.$('#productsClickTable').DataTable(this.state.datatables.config).draw();
  }

  async retrieveAnalyticsProducts() {
    const self = this;
    if (!!this.props.user.id && !!this.props.shop.id) {      
      try {
        let [products , clicks] = [
          await analyticsService.products(this.props.user.id, this.props.shop.id, 
            this.props.from.toISOString(),
            this.props.to.toISOString(),
            !!this.props.limit ? this.props.limit : null
          ),
          await analyticsService.click(this.props.user.id, this.props.shop.id, this.props.from.toISOString(), this.props.to.toISOString())
        ];


        if (self._isMounted) {
          let datatables = this.state.datatables;
              datatables.rows = products.data;

          products.info.date.from = moment.utc(products.info.date.from).local();
          products.info.date.to = moment.utc(products.info.date.to).local();

          // let _from = moment(this.props.from).add(moment(this.props.from).utcOffset(), 'minute');
          // let _to = moment(this.props.to).add(moment(this.props.to).utcOffset(), 'minute');

          let _from = moment(this.props.from).startOf('day');
          let _to = moment(this.props.to).endOf('day');
          
          while (_from.isSameOrBefore(_to)) {

            if(!!!window._.find(clicks.data, {date: _from.format('YYYY-MM-DD')})) {
              clicks.data.push({
                click: 0,
                jumpto: 0,
                jumpto_gs: 0,
                date: _from.format('YYYY-MM-DD'),
              });
            }
            _from.add(1, 'day');
          }

          this.setState({ clicks: clicks.data });   
          this.setState({ datatables: datatables });          
          this.setState({ date: products.info.date });

          let total=0;
          if (typeof products.data !== 'undefined' && products.data.length > 0) {
              total = products.data.map(o => o.click).reduce((a, c) => a + c);
          }

          this.setState({ total: total });      
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  render() {

    let [clicks, google_shopping_clicks, prezzifarmaco_clicks, jumpto, bannerClick, couponClick] = [
      this.state.clicks.reduce((sum, value) => 
        sum + value.click
      , 0),
      this.state.clicks.reduce((sum, value) =>
        sum + value.jumpto_gs
      , 0),
      this.state.clicks.reduce((sum, value) =>
        sum + value.click - value.jumpto_gs
      , 0),
      this.state.clicks.reduce((sum, value) =>
        sum + (!!value.jumpto ? value.jumpto : 0)
      , 0),
      this.state.clicks.reduce((sum, value) =>
        sum + (!!value.bannerClick ? value.bannerClick : 0)
      , 0),
      this.state.clicks.reduce((sum, value) =>
        sum + (!!value.couponClick ? value.couponClick : 0)
      , 0),
    ];

    return(
      <div>
        {
          !!this.props.blockquote
            ? <div className="row">
                <UncontrolledTooltip placement="right" target="click">
                  Indica il numero di click totali del tuo negozio nel periodo selezionato
                </UncontrolledTooltip> 
        
                <UncontrolledTooltip placement="right" target="prezzifarmaco_clicks">
                  Indica il numero di click inviati da PrezziFarmaco al tuo negozio nel periodo selezionato
                </UncontrolledTooltip> 
        
                <UncontrolledTooltip placement="right" target="google_shopping_clicks">
                  Indica il numero di click inviati da Google Shopping al tuo negozio nel periodo selezionato
                </UncontrolledTooltip> 
        
                <div className="col-sm-12 col-md-12 col-lg-4 mB-10">
                  <Blockquote color="primary" icon="mouse-pointer">
                    <h6>Click Totali</h6>
                    <div>
                      <b>{ clicks }</b> click nel perido selezionato
                    </div>
                    <span
                      className="text-info tootTipTopRight"
                      href="#" id="click"
                      ><i className="far fa-question-circle"></i>
                    </span>
                  </Blockquote>
                </div>
        
                <div className="col-sm-12 col-md-12 col-lg-4 mB-10">
                  <Blockquote color="success" icon="mouse-pointer">
                    <h6>Click Prezzifarmaco</h6>
                    <b>{ prezzifarmaco_clicks }</b> click tramite PrezziFarmaco
                    <span
                      className="text-info tootTipTopRight"
                      href="#" id="prezzifarmaco_clicks"
                      ><i className="far fa-question-circle"></i>
                    </span>
                  </Blockquote>
                </div>
        
                <div className="col-sm-12 col-md-12 col-lg-4 mB-10">
                  <Blockquote color="warning" icon="mouse-pointer">
                    <h6>Click Google Shopping</h6>
                    <b>{ google_shopping_clicks }</b> click tramite Google Shopping
                    <span
                      className="text-info tootTipTopRight"
                      href="#" id="google_shopping_clicks"
                      ><i className="far fa-question-circle"></i>
                    </span>
                  </Blockquote>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-4 mB-10">
                  <Blockquote color="success" icon="mouse-pointer">
                    <h6>Click su offerte di prezzo</h6>
                    <b>{ jumpto }</b> click su offerte prodotto
                  </Blockquote>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-4 mB-10">
                  <Blockquote color="secondary" icon="mouse-pointer">
                    <h6>Click dei banner nativi</h6>
                    <div><b>{ bannerClick }</b> click su banner nativi</div>
                    <Link to="/banner">
                      visualizza i banner
                    </Link>
                    <span
                      className="text-info tootTipTopRight"
                      href="#" id="elements"
                      ><i className="far fa-question-circle"></i>
                    </span>
                  </Blockquote>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-4 mB-10">
                  <Blockquote color="danger" icon="mouse-pointer">
                    <h6>Click dei coupon</h6>
                    <div><b>{ couponClick }</b> click su coupon</div>
                    <Link to="/coupon">
                      visualizza i coupon
                    </Link>
                    <span
                      className="text-info tootTipTopRight"
                      href="#" id="elements"
                      ><i className="far fa-question-circle"></i>
                    </span>
                  </Blockquote>
                </div>

              </div>
            : null
        }  
        <div className="row">
          <div className="col-12 mT-20">
            {
              !!this.props.table
                ? <ClicChart 
                    clicks={this.state.clicks}
                  />
                : !!this.props.limit
                    ? `I ${this.props.limit} prodotti più cliccati dal ${ moment.utc(this.props.from).local().format('DD/MM/YYYY') } al ${ moment.utc(this.props.to).local().format('DD/MM/YYYY') }`
                    : `${ this.state.total } click ricevuti dal ${ moment.utc(this.props.from).local().format('DD/MM/YYYY') } al ${ moment.utc(this.props.to).local().format('DD/MM/YYYY') }`
            }
          </div>


          <div className="col-12 mT-20">

            <Table id="productsClickTable" bordered striped hover responsive>
              <thead>
                <tr>
                  <th>Immagine prodotto</th>
                  <th>
                    Prodotto
                    <UncontrolledTooltip placement="right" target="productInfo">
                      Le informazioni del prodotto
                    </UncontrolledTooltip>
                    <span
                      className="text-muted"
                      href="#" id="productInfo"
                      >
                      &nbsp; <i className="far fa-question-circle"></i>
                    </span>
                  </th>
                  <th>
                    Numero click
                    <UncontrolledTooltip placement="right" target="productClic">
                      Click del prodotto relativi al periodo { moment.utc(this.props.from).local().format('DD/MM/YYYY') } - { moment.utc(this.props.to).local().format('DD/MM/YYYY') }
                    </UncontrolledTooltip>
                    <span
                      className="text-muted"
                      href="#" id="productClic"
                      >
                      &nbsp; <i className="far fa-question-circle"></i>
                    </span>
                  </th>
                  <th>Click da PrezziFarmaco</th>
                  <th>Click da Google Shopping</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.datatables.rows
                    .map((item, key) =>
                      !!item.product
                        ? <tr key={key}>
                            <td className=" text-center align-middle ">
                              <img className="productimg" data-value={item.product.codice_ministeriale} title={ item.product.nome_prodotto }  alt={ item.product.nome_prodotto } src={ `https://static.fogliettoillustrativo.net/ws/it/${item.product.nome_immagine}` } />
                            </td>
                            <th className=" align-middle" scope="row">
                              <Link to={`/analytics/product/${item.product.codice_ministeriale}`}>
                                <span>{ item.product.nome_prodotto }</span>
                                {
                                  !!item.product.descrizione_forma
                                    ?
                                      <span className="small">&nbsp; { item.product.descrizione_forma.toLowerCase() }</span>
                                    : null
                                }
                                <span className="small text-muted">&nbsp; ({ item.product.codice_ministeriale })</span>
                                <br/>
                                <span className="small">{ item.product.descrizione_ditta_1_produttrice }</span>
                              </Link>
                            </th>
                            <td className=" text-center align-middle">{ item.click }</td>
                            <td className=" text-center align-middle">{ item.jumpto }</td>
                            <td className=" text-center align-middle">{ item.jumpto_gs }</td>
                          </tr>
                        : null
                    )
                }
              </tbody>
            </Table>
          
          </div>
        
        </div>
      </div>
    )
  }
}