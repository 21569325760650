import Resource from '../_helpers/resource';

const resource = new Resource('https://www.prezzifarmaco.it/ajax/search');



export const prezziFarmacoService = {

  suggest: (query) => {
    let api = `ws/suggest`;
    let queryString = new URLSearchParams();

    if(!!query) {
      queryString.set('query', query);
    }

    queryString.set('rpp', 5);
    queryString.set('page', 1);

    api = `${api}?${queryString.toString()}`; 

    return resource.get(
      api
    );
  },

};
