import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';

import { analyticsService } from '../../../_services/analytics.service';
import IncomeChartsPerc from './Charts/IncomeChartsPerc';
import IncomeChartsEuro from './Charts/IncomeChartsEuro';

export default class ECommerceIncome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      incomesStatsPerc: null,
      incomesStatsEuro: null,
    };
  }

  async componentDidMount() {
    const { user, shop, from, to } = this.props;

    if (!!user.id && !!shop.id) {
      try {
        const incomesStatsPerc = await analyticsService.getIncomesStatsPerc(
          user.id,
          shop.id,
          from.toISOString(),
          to.toISOString()
        );

        const incomesStatsEuro = await analyticsService.getIncomesStatsEuro(
          user.id,
          shop.id,
          from.toISOString(),
          to.toISOString()
        );

        await this.setState({ incomesStatsPerc, incomesStatsEuro });
      } catch (error) {
        console.error(error);
      }
    }

    window.removeLoader();
  }

  render() {
    const { user, shop } = this.props;
    const { incomesStatsPerc, incomesStatsEuro } = this.state;

    return !user.id || !shop.id ? (
      <Alert color="warning">
        Non hai ancora un negozio associato. <Link to="/shop">Vai al form di creazione.</Link>
      </Alert>
    ) : (
      <div>
        <div className="row">
          <div className="col-12 mT-20">
            <IncomeChartsPerc statsHistory={incomesStatsPerc} />
          </div>

          <div className="col-12 mT-20">
            <IncomeChartsEuro statsHistory={incomesStatsEuro} />
          </div>
        </div>
      </div>
    );
  }
}
