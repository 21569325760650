import React, { Component } from 'react';
import Iframe from 'react-iframe';

import Details from './Details';
import PriceChart from './PriceChart';
import PriceTable from './PriceTable';

import Blockquote from '../../Blockquote/Blockquote';

import { productsService } from '../../../_services/products.service';
import { analyticsService } from '../../../_services/analytics.service';
export default class Product extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      product: {},
      productHistory: {},
      productStats: {},
      productPrevision: [],
      price: {},
      fetched: false,
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    if (!this.props.shop.id) {
      return;
    }

    if (!!this.state.fetched) {
      return;
    }

    this.setState({ fetched: true });

    try {
      let product = await productsService.get(this.props.match.params.ean);
      if (this._isMounted) {
        this.setState({ product });
      }
    } catch (error) {
      console.error(error);
    }

    try {
      let price = {};
      if (!!this.props.user.id && !!this.props.shop.id) {
        price = await productsService.getProductPrice(
          this.props.user.id,
          this.props.shop.id,
          this.props.match.params.ean
        );
      }

      if (this._isMounted) {
        this.setState({ price });
      }
    } catch (error) {
      console.error(error);
    }

    try {
      let productHistory = await analyticsService.producthistory(
        this.props.shop.id,
        this.props.match.params.ean,
        this.props.from.toISOString(),
        this.props.to.toISOString(),
        !!this.props.limit ? this.props.limit : null
      );

      if (this._isMounted) {
        this.setState({ productHistory });
      }
    } catch (error) {
      console.error(error);
    }

    try {
      let productStats = await analyticsService.productstats(this.props.match.params.ean);
      if (this._isMounted) {
        this.setState({ productStats });
      }
    } catch (error) {
      console.error(error);
    }

    if (this._isMounted && this.state.productHistory.data.length > 0) {
      let productPrevision = await analyticsService.productforecast(
        this.props.match.params.ean,
        this.state.productHistory.data[this.state.productHistory.data.length - 1].minprice
      );

      this.setState({ productPrevision });
    }

    window.removeLoader();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const gTrends = `https://trends.google.com/trends/embed/explore/TIMESERIES?req={%22comparisonItem%22%3A[{%22keyword%22%3A%22${
      !!this.state.product.nome_prodotto ? this.state.product.nome_prodotto.split(' ')[0] : ''
    }%22%2C%22geo%22%3A%22IT%22%2C%22time%22%3A%22today%2012-m%22}]%2C%22category%22%3A0%2C%22property%22%3A%22%22}&tz=-300&geo%3DIT%26date%3Dtoday%2012-m`;

    return (
      <div>
        <div className="row">
          <div className="col-xs-12 col-lg-6">
            <div className="row">
              <div className="col-12">
                <div className="bgc-white bd bdrs-3 p-20 mB-20">
                  <h4 className="c-grey-900">Analisi prodotto {this.state.product.nome_prodotto} </h4>

                  <div className="">
                    {!!this.state.productHistory && !!this.state.productHistory.data ? (
                      <Details
                        productHistory={this.state.productHistory}
                        product={this.state.product}
                        {...this.props}
                      />
                    ) : null}
                  </div>

                  <div>
                    Valutazione:{' '}
                    {!!this.state.productStats &&
                    !!this.state.productStats.data &&
                    !!this.state.productStats.data.rating &&
                    !!this.state.productStats.data.rating.rating
                      ? Array.apply(null, { length: this.state.productStats.data.rating.rating }).map((x, i) => (
                          <i className="fas fa-star text-warning" key={i} />
                        ))
                      : '-'}{' '}
                    &nbsp;
                  </div>
                </div>
              </div>

              <div className="col-6 mB-20">
                <Blockquote color="info" icon="glasses">
                  <h6>Interesse</h6>
                  <div className="progress" style={{ height: '30px' }}>
                    <div
                      className="progress-bar progress-bar-striped progress-bar-animated bg-info"
                      role="progressbar"
                      style={{
                        width: `${!!this.state.productStats.data ? this.state.productStats.data.trend_views : 0}%`,
                      }}
                      aria-valuenow={!!this.state.productStats.data ? this.state.productStats.data.trend_views : 0}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {!!this.state.productStats.data ? this.state.productStats.data.trend_views : 0}%
                    </div>
                  </div>
                </Blockquote>
              </div>

              <div className="col-6 mB-20">
                <Blockquote color="success" icon="funnel-dollar">
                  <h6>Potenziale di Acquisto</h6>
                  <div className="progress" style={{ height: '30px' }}>
                    <div
                      className="progress-bar progress-bar-striped progress-bar-animated bg-success"
                      role="progressbar"
                      style={{
                        width: `${!!this.state.productStats.data ? this.state.productStats.data.trend_interest : 0}%`,
                      }}
                      aria-valuenow={!!this.state.productStats.data ? this.state.productStats.data.trend_interest : 0}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {!!this.state.productStats.data ? this.state.productStats.data.trend_interest : 0}%
                    </div>
                  </div>
                </Blockquote>
              </div>
            </div>
          </div>

          <div className="col-xs-12 col-lg-6 mB-20">
            <div className="bgc-white bd bdrs-3 p-20 mB-20">
              <Iframe src={gTrends} width="100%" height="320px" frameBorder="0" scrolling="0" showLegend="false" />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-6 col-md-6 col-lg-3 mB-20">
            <Blockquote color="success" icon="file-invoice-dollar">
              <h6>Il tuo prezzo corrente</h6>
              {!!this.state.price.prezzo &&
              !!this.state.productHistory &&
              !!this.state.productHistory.data &&
              this.state.productHistory.data.length > 0 &&
              !!this.state.productHistory.data[this.state.productHistory.data.length - 1] &&
              !!this.state.productHistory.data[this.state.productHistory.data.length - 1].minprice ? (
                <div>
                  <strong>{this.state.price.prezzo} &euro;</strong>&nbsp;
                  {(
                    (Math.abs(
                      this.state.price.prezzo -
                        this.state.productHistory.data[this.state.productHistory.data.length - 1].minprice
                    ) /
                      this.state.productHistory.data[this.state.productHistory.data.length - 1].minprice) *
                    100
                  ).toFixed(2) === 0 ? (
                    <small> è il miglior prezzo online! </small>
                  ) : (
                    <div>
                      <small>
                        {this.state.price.prezzo >
                        this.state.productHistory.data[this.state.productHistory.data.length - 1].minprice.toFixed(2)
                          ? 'più caro'
                          : 'meno caro'}{' '}
                        del miglior prezzo del
                      </small>
                      &nbsp;
                      <strong>
                        {(
                          (Math.abs(
                            this.state.price.prezzo -
                              this.state.productHistory.data[this.state.productHistory.data.length - 1].minprice
                          ) /
                            this.state.productHistory.data[this.state.productHistory.data.length - 1].minprice) *
                          100
                        ).toFixed(2)}
                        %
                      </strong>
                    </div>
                  )}
                </div>
              ) : (
                ' - '
              )}
              In vendita in{' '}
              <strong>
                {!!this.state.productHistory &&
                !!this.state.productHistory.data &&
                this.state.productHistory.data.length > 0
                  ? this.state.productHistory.data[this.state.productHistory.data.length - 1].totcount
                  : 0}{' '}
                ecommerce
              </strong>
            </Blockquote>
          </div>

          <div className="col-xs-6 col-md-6 col-lg-3 mB-20">
            <Blockquote color="success" icon="money-bill-alt">
              <h6>Prezzo minimo</h6>
              <strong>
                {!!this.state.productHistory &&
                !!this.state.productHistory.data &&
                this.state.productHistory.data.length > 0
                  ? this.state.productHistory.data[this.state.productHistory.data.length - 1].minprice.toFixed(2)
                  : 0}{' '}
                €
              </strong>
            </Blockquote>
          </div>

          <div className="col-xs-6 col-md-6 col-lg-3 mB-20">
            <Blockquote color="warning" icon="money-bill">
              <h6>Prezzo medio</h6>
              <strong>
                {!!this.state.productHistory &&
                !!this.state.productHistory.data &&
                this.state.productHistory.data.length > 0
                  ? this.state.productHistory.data[this.state.productHistory.data.length - 1].avgprice.toFixed(2)
                  : 0}{' '}
                €
              </strong>
            </Blockquote>
          </div>

          <div className="col-xs-6 col-md-6 col-lg-3 mB-20">
            <Blockquote color="primary" icon="money-bill-alt">
              <h6>Prezzo massimo</h6>
              <strong>
                {!!this.state.productHistory &&
                !!this.state.productHistory.data &&
                this.state.productHistory.data.length > 0
                  ? this.state.productHistory.data[this.state.productHistory.data.length - 1].maxprice.toFixed(2)
                  : 0}{' '}
                €
              </strong>
            </Blockquote>
          </div>
        </div>

        {this.props.shop.id === 6 ? (
          <div className="row">
            <div className="col-xs-6 col-md-6 col-lg-3 mB-20">
              <Blockquote color="info" icon="brain">
                <h6>Il nostro consiglio</h6>
                La nostra intelligenza artificiale ha analizzato la storia del prezzo di questo prodotto - &nbsp;
                <br />
                <small>Come qualsiasi previsione, tuttavia, non è possibile essere sicuri al 100%.</small>
              </Blockquote>
            </div>

            <div className="col-xs-6 col-md-6 col-lg-3 mB-20">
              <Blockquote
                color={
                  !!this.state.productHistory &&
                  !!this.state.productHistory.data &&
                  this.state.productPrevision['minprice10'] >
                    this.state.productHistory.data[this.state.productHistory.data.length - 1].minprice
                    ? 'danger'
                    : 'success'
                }
                icon={
                  !!this.state.productHistory &&
                  !!this.state.productHistory.data &&
                  this.state.productPrevision['minprice10'] >
                    this.state.productHistory.data[this.state.productHistory.data.length - 1].minprice
                    ? 'level-up-alt'
                    : 'level-down-alt'
                }
              >
                <h6>Previsione prezzo minimo a 10 giorni</h6>
                <strong>
                  {!!this.state.productPrevision['minprice10']
                    ? this.state.productPrevision['minprice10'].toFixed(2)
                    : 0}{' '}
                  €
                </strong>{' '}
                &nbsp;
                {!!this.state.productPrevision['minprice10']
                  ? (
                      ((this.state.productPrevision['minprice10'].toFixed(2) -
                        this.state.productHistory.data[this.state.productHistory.data.length - 1].minprice) /
                        this.state.productHistory.data[this.state.productHistory.data.length - 1].minprice) *
                      100
                    ).toFixed(2)
                  : 0}{' '}
                %
              </Blockquote>
            </div>

            <div className="col-xs-6 col-md-6 col-lg-3 mB-20">
              <Blockquote
                color={
                  !!this.state.productHistory &&
                  !!this.state.productHistory.data &&
                  this.state.productPrevision['minprice20'] >
                    this.state.productHistory.data[this.state.productHistory.data.length - 1].minprice
                    ? 'danger'
                    : 'success'
                }
                icon={
                  !!this.state.productHistory &&
                  !!this.state.productHistory.data &&
                  this.state.productPrevision['minprice20'] >
                    this.state.productHistory.data[this.state.productHistory.data.length - 1].minprice
                    ? 'level-up-alt'
                    : 'level-down-alt'
                }
              >
                <h6>Previsione prezzo minimo a 20 giorni</h6>
                <strong>
                  {!!this.state.productPrevision['minprice20']
                    ? this.state.productPrevision['minprice20'].toFixed(2)
                    : 0}{' '}
                  €
                </strong>{' '}
                &nbsp;
                {!!this.state.productPrevision['minprice20']
                  ? (
                      ((this.state.productPrevision['minprice20'].toFixed(2) -
                        this.state.productHistory.data[this.state.productHistory.data.length - 1].minprice) /
                        this.state.productHistory.data[this.state.productHistory.data.length - 1].minprice) *
                      100
                    ).toFixed(2)
                  : 0}{' '}
                %
              </Blockquote>
            </div>

            <div className="col-xs-6 col-md-6 col-lg-3 mB-20">
              <Blockquote
                color={
                  !!this.state.productHistory &&
                  !!this.state.productHistory.data &&
                  this.state.productPrevision['minprice30'] >
                    this.state.productHistory.data[this.state.productHistory.data.length - 1].minprice
                    ? 'danger'
                    : 'success'
                }
                icon={
                  !!this.state.productHistory &&
                  !!this.state.productHistory.data &&
                  this.state.productPrevision['minprice30'] >
                    this.state.productHistory.data[this.state.productHistory.data.length - 1].minprice
                    ? 'level-up-alt'
                    : 'level-down-alt'
                }
              >
                <h6>Previsione prezzo minimo a 30 giorni</h6>
                <strong>
                  {!!this.state.productPrevision['minprice30']
                    ? this.state.productPrevision['minprice30'].toFixed(2)
                    : 0}{' '}
                  €{' '}
                </strong>{' '}
                &nbsp;
                {!!this.state.productPrevision['minprice30']
                  ? (
                      ((this.state.productPrevision['minprice30'].toFixed(2) -
                        this.state.productHistory.data[this.state.productHistory.data.length - 1].minprice) /
                        this.state.productHistory.data[this.state.productHistory.data.length - 1].minprice) *
                      100
                    ).toFixed(2)
                  : 0}{' '}
                %
              </Blockquote>
            </div>
          </div>
        ) : null}

        <div className="row">
          <div className="col-12">
            <div className="bgc-white bd bdrs-3 p-20 mB-20">
              <div className="">
                {!!this.state.productHistory ? (
                  <PriceChart product={this.state.product} productHistory={this.state.productHistory} {...this.props} />
                ) : null}
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="bgc-white bd bdrs-3 p-20 mB-20">
              <div className="">
                {!!this.state.productHistory && !!this.state.productHistory.data ? (
                  <PriceTable
                    searching
                    paging
                    info
                    product={this.state.product}
                    productHistory={this.state.productHistory}
                    {...this.props}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
