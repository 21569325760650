import React, { Component } from 'react';
import { decode } from 'jsonwebtoken';
import { Link } from 'react-router-dom';
import { parse } from 'query-string';

import { validateEmail } from '../../_utils/regEx';
import { authService } from '../../_services/auth.service';

class Signin extends Component {
  constructor(props) {
    super(props);

    authService.logout();

    this.state = {
      email: '',
      password: '',
      rememberme: true,
      error: '',
      submitted: false,
      loading: false,
      isSubmitDisabled: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { token } = parse(this.props.location.search);

    if (this.props.match.path === `/user/reset` && token) {
      authService
        .loginOneTimePassword(token)
        .then((auth) => {
          console.log('setUser Signin:');
          this.props.setUser(decode(auth.jwt).user);
          window.location.replace('/account?reseted=true');
        })
        .then(
          () => {
            return authService.retrivePublic();
          },
          (error) => {
            console.error('ERRORE', error);
            this.props.notify.error('Token non riconosciuto.');
            this.props.history.push('/');
          }
        );
    }
  }

  componentDidMount() {
    this.canSubmit();
  }

  canSubmit() {
    const { email, password } = this.state;
    this.setState({ isSubmitDisabled: !(validateEmail(email) && password.length) });
  }

  handleChange(event) {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      this.canSubmit
    );

    this.setState({ submitted: false });
  }

  handleCheckboxChange(event) {
    this.setState(
      {
        [event.target.name]: event.target.checked,
      },
      this.canSubmit
    );
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { email, password } = this.state;

    authService
      .login(email, password)
      .then((auth) => {
        const { from } = this.props.location.state || { from: { pathname: '/dashboard' } };
        this.props.history.push(from);
      })
      .then(
        () => {
          return authService.retrivePublicCertificate();
        },
        (error) => {
          console.error('ERRORE', error);
          this.props.notify.error(`Ops.. C'è stato un problema!`);
        }
      );
  }

  handleResetPassword(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { email } = this.state;

    authService.resetPassword(email).then(() => {
      this.props.notify.success('Una nuova password è stata inviata al tuo indirizzo email.');
    });

    this.setState({ submitted: false });
  }

  render() {
    const { email, password, submitted, isSubmitDisabled } = this.state;
    let page;

    if (this.props.match.path === `/user/login`) {
      page = (
        <div>
          <h4 className="fw-300 c-grey-900 mB-40">Login</h4>
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label className="text-normal text-dark">Email</label>
              <input
                type="email"
                className="form-control"
                placeholder="La tua email"
                name="email"
                required
                value={email}
                onChange={(e) => this.handleChange(e)}
              />
            </div>

            <div className={'form-group' + (submitted && !email ? ' has-error' : '')}>
              <label className="text-normal text-dark">Password</label>
              <input
                type="password"
                className="form-control"
                placeholder="La tua password"
                name="password"
                minLength="4"
                required
                value={password}
                onChange={(e) => this.handleChange(e)}
              />
            </div>

            <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
              <div className="peers ai-c jc-sb fxw-nw">
                <div className="peer">
                  <button className="btn btn-primary" disabled={isSubmitDisabled}>
                    Login
                  </button>
                </div>
              </div>
            </div>
          </form>
          <hr />

          <Link to="/user/reset">Non ricordi la password?</Link>
        </div>
      );
    }

    if (this.props.match.path === `/user/reset`) {
      page = (
        <div>
          <h4 className="fw-300 c-grey-900 mB-40">Recupara password</h4>
          <form onSubmit={this.handleResetPassword}>
            <div className="form-group">
              <label className="text-normal text-dark">Email</label>
              <input
                type="email"
                className="form-control"
                placeholder="La tua email"
                name="email"
                required
                value={email}
                onChange={(e) => this.handleChange(e)}
              />
            </div>

            <div className={'form-group' + (submitted ? ' has-error' : '')}>
              <div className="peers ai-c jc-sb fxw-nw">
                <div className="peer">
                  <button className="btn btn-primary" disabled={!email || submitted}>
                    Recupera
                  </button>
                </div>
              </div>
            </div>
          </form>
          <hr />

          <Link to="/user/login">Torna al login</Link>
        </div>
      );
    }

    return (
      <div className="peers ai-s fxw-nw h-100vh">
        <div
          className="d-n@sm- peer peer-greed h-100 pos-r bgr-n bgpX-c bgpY-c bgsz-cv"
          style={{ backgroundImage: `url("/static/images/background.png")` }}
        >
          <div className="">
            <div className="row">
              <div className="col-6 col-md-6 text-center">
                <img
                  className="img-fluid"
                  src="/static/images/sai-come-stabilire-il-prezzo-dei-tuoi-prodotti.png"
                  alt="Sai come stabilire il miglior prezzo per i tuoi prodotti?"
                />
              </div>

              <div className="col-6 col-md-6 text-center">
                <img
                  className="img-fluid"
                  src="/static/images/il-tuo-ecommerce-competitivo.png"
                  alt="Il tuo ecommerce è competitivo ?"
                />
              </div>
            </div>
            {/* <img src="/static/images/logo.png" alt="Cerca, confronta, risparmia in farmacia online con PrezziFarmaco.it"/> */}
          </div>
        </div>

        <div
          className="col-12 col-md-4 peer pX-40 pY-80 h-100 bgc-white scrollable pos-r"
          style={{ minWidth: '320px' }}
        >
          <a href="https://www.prezzifarmaco.it">
            <img src="/static/images/logo.png" alt="Analisi di Mercato online Farmacia e Parafarmacia" />
          </a>

          <p>L'unico strumento per l'analisi di mercato per farmacie e parafarmacie online</p>
          {page}
          <br />

          <h2>Vantaggi</h2>
          <ul>
            <li>Tracciare i click e valutare le relative conversioni;</li>
            <li>
              Ottimizzare l’acquisizione dei dati in ingresso sulla qualità del traffico relativo ai prezzi ed alle
              vendite;
            </li>

            <li>
              Pianificare delle campagne promozionali interne al portale (sia di posizionamento che di native
              advertising), che permetteranno all’azienda di beneficiare di un maggior numero di visualizzazione dei
              propri prodotti e delle proprie offerte.
            </li>
          </ul>
          <br />
          <br />
        </div>
      </div>
    );
  }
}

export default Signin;
