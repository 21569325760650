import { authHeader } from '../_helpers/auth-header';
import Resource from '../_helpers/resource';

const resource = new Resource(process.env.REACT_APP_API_URL);



export const dataService = {

  getCategoryInterests: (query) => {
    let api = `data/category/interests`;

    return resource.get(
      api,
      authHeader()
    );
  },

  getCategoryDiscount: (query) => {
    let api = `data/category/discount`;

    return resource.get(
      api,
      authHeader()
    );
  },

  getManufactorInterests: (query) => {
    let api = `data/manufactor/interests`;

    return resource.get(
      api,
      authHeader()
    );
  },

  getManufactorDiscount: (query) => {
    let api = `data/manufactor/discount`;

    return resource.get(
      api,
      authHeader()
    );
  },
};
