import { authHeader } from '../_helpers/auth-header';
import Resource from '../_helpers/resource';

const resource = new Resource(process.env.REACT_APP_API_URL);

export const searchService = {

  getGooglePlaceId: (userId, shopId, query) => {
    let api = `user/${userId}/shop/${shopId}/googlePlace`;
    let queryString = new URLSearchParams();

    if(!!query) {
      queryString.set('query', query);
    }

    api = `${api}?${queryString.toString()}`; 

    return resource.get(
      api,
      authHeader()
    );

  },

};
