import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
//import { Alert } from 'reactstrap';

import { commerceUserProductsService } from '../../_services/commerceUserProducts.service';

export class SuspendCampaign extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      errors: [],
    };

    this.toggle = this.toggle.bind(this);
    this.suspend = this.suspend.bind(this);
  }

  setAlert(message, level='info') {
    let errors = this.state.errors
    errors.push({
      level,
      message,
    });
    this.setState({ errors });
  }
  
  async toggle() {
    await this.setState({ errors: [] });
    if( !!(!this.state.modal) ) {
    }
    await this.setState({modal: !this.state.modal});    
    //await this.execCallback();
  }

  async execCallback() {
    try {      
      if (!!this.props.onClose && !!!this.state.modal) {    
        await this.props.onClose();
      }
    } catch (error) {
      console.warn('Error in toggle function callback');
    }
  }

  async suspend() {
    this.setState({ errors: [] });
    try {  
      await commerceUserProductsService.suspend(
        this.props.user.id,
        this.props.activeCampaign.transaction,
        this.props.activeCampaign.tier,
        this.props.activeCampaign.product,
      );
      
      this.props.notify.success('Campagna Sospesa.');
      await this.setState({modal: !this.state.modal});
      await this.execCallback();
  
    } catch (error) {

      let code;      

      try {
        code = error.message.code;
      } catch (error) {
        code = undefined;
      }

      switch (code) {
        case 'NOT_ENOUGH_MONEY':

          let alertText = (<div>
            Non hai credito sufficiente per completare l'acquisto.<br />
          </div>);

          this.setAlert(alertText, 'warning');

          break;
      
        default:
          console.error(error);          
          break;
      }

      this.props.notify.warn('Si è verificato un problema durante il processo di sospensione campagna.');
    }
    
  }

  render() {
    return (
      <div>
      { !this.props.disabled
        ?
        <div>
          <Button 
            color="warning" 
            className="btn btn-sm btn-warning" 
            onClick={this.toggle} 
            disabled={ this.props.disabled }
            >
              Sospendi Campagna
          </Button> 

          <Modal isOpen={this.state.modal} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle}>Conferma Sospensione</ModalHeader>

            <ModalBody>

              <div className="row">
                <div className="col-12">
                {
                  !!this.props.activeCampaign
                  ? 
                    <div className="">
                      <h4>Sospendi la Campagna Attiva</h4>
                      <p className="text-left">
                        La campagna <b>{ this.props.activeCampaign.product }</b> attiva sarà terminata. <br/>
                        La visibilità su prezzifarmaco verrà ridotta.
                      </p>
                    </div>
                  : <span>Non ci sono campagne attive da sospendere</span>
                  }
                </div>
              </div>
            </ModalBody>

          
              <ModalFooter>
                <Button color="warning" onClick={ this.suspend }>Sospendi</Button>
                <Button color="secondary" onClick={ this.toggle }>Annulla</Button>
              </ModalFooter>
            
          </Modal>
        
        </div>
        :
        ''
      }
      </div>
    );
  }
}