import React, { useEffect, useState } from 'react';

import Sidebar from '../Sidebar';
import Header from '../Header';
import Footer from '../Footer';

export default ({ component: Component, ...props }) => {
  let { location } = props;
  const [, setCurrentPath] = useState(location.pathname);

  useEffect(() => {
    const { pathname } = location;

    setTimeout(() => {
      const loader = document.getElementById('loader');
      if (!!!loader) return;
      loader.classList.add('fadeOut');
    }, 30000);
    setCurrentPath(pathname);
  }, [location]);

  window.removeLoader = () => {
    const loader = document.getElementById('loader');
    if (!!!loader) return;
    loader.classList.add('fadeOut');
  };

  return (
    <div>
      <div id="loader">
        <div className="spinner"></div>
      </div>

      <Sidebar {...props} />

      <div className="page-container">
        <Header {...props} />

        <main className="main-content bgc-grey-100">
          <div id="mainContent">
            <Component {...props} />
          </div>
        </main>

        <Footer />
      </div>
    </div>
  );
};
