import React, { Component } from 'react';

import moment from 'moment';

export default class DataPicker extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      from: moment().subtract(1, 'day').subtract(29, 'days'), 
      to: moment().subtract(1, 'day'),
    };    

    this.changeDates = this.changeDates.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;

    const date = {
      from: this.props.from,
      to: this.props.to,
    }; 

    this.setState({from: date.from, to: date.to})

    window.moment.locale('it');
    window.$(`#datapicker`).daterangepicker({
      startDate: date.from,
      endDate: date.to,
      maxDate: moment().subtract(1, 'day').endOf('day'),
      ranges: {
        'Ieri': [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
        'Ultimi 7 giorni': [moment().subtract(1, 'day').subtract(6, 'days'), moment().subtract(1, 'day')],
        'Ultimi 30 giorni': [moment().subtract(1, 'day').subtract(29, 'days'), moment().subtract(1, 'day')],
        'Ultimi 60 giorni': [moment().subtract(1, 'day').subtract(59, 'days'), moment().subtract(1, 'day')],
        'Questa settimana': [moment().startOf('week'), moment().subtract(1, 'day').endOf('week')],
        'Questo mese': [moment().startOf('month'), moment().subtract(1, 'day').endOf('month')],
        'Mese precedente': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        'Settimana precedente': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
      },
      locale: {
        format: 'DD/MM/YYYY',
        separator: ' - ',
        applyLabel: 'Applica',
        cancelLabel: 'Cancella',
        fromLabel: 'Da',
        toLabel: 'A',
        customRangeLabel: 'Personalizzato',
        firstDay: 1
      },
      autoUpdateInput: true,
    }, this.changeDates);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async changeDates(from, to) {    
    localStorage.setItem('datapicker', JSON.stringify({
      from: moment(from).format(),
      to: moment(to).format()
    }));

    if (!!this.props.changeDates) {
      this.props.changeDates(from, to);
    }
  }

  render() {
    return (
      <form>        
        <div className="dprow">
          <div className="dpcol">
            <div id={`datapicker`} style={{background: '#fff', cursor: 'pointer', padding: '5px', width: '100%'}}>
              <i className="fa fa-calendar"></i>&nbsp;
              <span>{ moment(this.state.from).format('DD/MM/YYYY') } - { moment(this.state.to).format('DD/MM/YYYY') }</span> <i className="fa fa-caret-down"></i>
            </div>
          </div>
        </div>
      </form>
    );
  }
}
