import React, { Component } from 'react';

import ChartsCategory from './Charts/ChartsCategory';

export default class ECommerce extends Component {
  componentDidMount() {
    window.removeLoader();
  }

  render() {
    return (
      <div className="row">
        <div className="col-12 mT-10">
          <ChartsCategory />
        </div>
      </div>
    );
  }
}
