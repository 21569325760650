import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';

import { integrationService } from '../../_services/integration.service';

class Analytics extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      integration: false,
      checking: false,
    };

    this.checkIntegration = this.checkIntegration.bind(this);
  }

  async checkIntegration() {
    try {
      this.setState({ checking: true });
      const response = await integrationService.productsTrack(this.props.user.id, this.props.shop.id);
      this.setState({ integration: response.integrated });
      this.setState({ checking: false });
    } catch (error) {
      this.setState({ integration: false });
      this.setState({ checking: false });
    }
  }

  async componentDidMount() {
    this._isMounted = true;
    if (!!this.props.user.id && !!this.props.shop.id) {
      await this.checkIntegration();
    }

    window.removeLoader();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <div>
        {!!!this.props.shop.id ? (
          <Alert color="warning">
            <i className="fas fa-surprise" /> Non hai ancora un negozio associato.
          </Alert>
        ) : null}

        {this.state.checking ? (
          <Alert color="info">
            <i className="fas fa-spinner fa-spin" /> Aggiornamento informazioni.
          </Alert>
        ) : !!this.state.integration ? (
          <Alert color="success">
            <i className="fas fa-check" /> Il tuo sito ha questa funzionalità integrata.
          </Alert>
        ) : (
          <Alert color="warning">
            <i className="fas fa-surprise" />
            Sembra che il tuo sito non implementi ancora questa funzionalità perchè non abbiamo ricevuto ordini
            nell'ultima settimana
          </Alert>
        )}

        <div className="bgc-white bd bdrs-3 p-20 mB-20 ">
          <div className="row">
            <div className="col-12">
              <h1>Tracciamenti acquisti</h1>
              <p>
                Per monitorare le statistiche relative alla performance vendite del tuo sito, devi inserire il codice di
                traccimaneto dei prodotti alla fine del processo di vendita nel tuo e-commerce.
              </p>
              <p>
                Questa funzionalità può essere implementata dopo aver integrato{' '}
                <Link to="/integrations/analytics">Analytics</Link>.
              </p>
            </div>
            <div className="col-12 mT-10">
              <h5>Codice</h5>
              <p>
                Inserisci il codice JavaScript prima della chiusura del body nel tuo codice HTML all'indirizzo{' '}
                {this.props.shop.base_url}:
              </p>
              <pre className="border border border-info rounded bg-light p-15 text-secondary">
                <code>
                  {`<script>
                    window.addEventListener('pfa.ready', function(e){
                      window.pfa.token = 'PFA-${!!this.props.shop.id ? this.props.shop.id : '*ID NEGOZIO*'}';
                      window.pfa('trackTransaction', 
                      {
                        products:[
                          { id: "CODICE MINISTERIALE PRODOTTO",  price: IL PREZZO DEL PRODOTTO SINGOLO,  quantity: QUANTITA ACQUISTATA },
                          { id: "CODICE MINISTERIALE PRODOTTO",  price: IL PREZZO DEL PRODOTTO SINGOLO,  quantity: QUANTITA ACQUISTATA }
                        ]
                      },
                      order_id
                      );
                    });
                  </script>`}
                </code>
              </pre>

              <p>
                <strong>
                  <ins>Nota bene:</ins>
                </strong>
              </p>
              <p>
                <span className="pL-10">
                  Il campo <kbd>price</kbd> deve essere un valore numerico, decimale, senza valuta. Es.: 14.50. Il
                  valore indicato si intende espresso in EURO e IVA inclusa.
                </span>
                <br />
                <span className="pL-10">
                  Il campo <kbd>quantity</kbd> deve essere un valore numerico, intero. Es.: 7
                </span>
                <br />
                <span className="pL-10">
                  Il campo <kbd>order_id</kbd> indica il numero d'ordine, è univoco e sarà utilizzato dal sistema per
                  evitare l'invio di ordini doppi.
                </span>
                <br />
                <span className="pL-10">
                  Il campo <kbd>id prodotto</kbd> indica il codice ministeriale del prodotto, numerico, 9 cifre con o
                  senza zeri a sinistra, Es: 012345678 oppure 987654321{' '}
                </span>
                <br />
              </p>
            </div>
          </div>
        </div>

        <div className="bgc-white bd bdrs-3 p-20 mB-20 ">
          <div className="row">
            <div className="col-6">
              <p>
                Attiva questa funzionalità &nbsp;
                <Link to="/integrations/tagmanager">
                  Google Tagmanager
                  <img
                    style={{ width: '120px' }}
                    className="img-fluid"
                    alt="Google Tagmanager"
                    src="/static/images/integrations/gtm.png"
                  />
                </Link>
              </p>
            </div>
            <div className="col-6">
              <p>
                Attiva questa funzionalità con il&nbsp;
                <Link to="/integrations/prestashopIntegration">
                  Modulo PrestaShop
                  <img
                    style={{ width: '120px' }}
                    className="img-fluid"
                    alt="PrestaShop"
                    src="/static/images/integrations/Prestashop-logo-orizz.png"
                  />
                </Link>
              </p>
            </div>
          </div>
        </div>

        <div className="bgc-white bd bdrs-3 p-20 mB-20 ">
          <div className="row">
            <div className="col-12">
              <h5>Informativa sui Cookie dei servizi di Analytics e Traking di Prezzifarmaco</h5>
              <p>
                Non preleviamo informazioni di profilazione in alcun modo e non vengono eseguite azioni di profilazione.
                I dati raccolti sono utilizzati solo a fini statistici in forma aggregata.
                <br />
                <br />
                Il nostro cookie può essere considerato come cookie tecnico di tipo "analytics" secondo la seguente
                definizione.
                <br />
                Cookie analytics: “assimilati ai cookie tecnici laddove utilizzati direttamente dal gestore del sito per
                raccogliere informazioni, in forma aggregata, sul numero degli utenti e su come questi visitano il sito
                stesso”
                <br />
                <br />
                Entrambi gli script ( analytics e tracking conversioni ) possono essere eseguiti anche senza consenso
                dell'utente in quanto eseguono funzioni di marketing o profilazione.
                <br />
                Non preleviamo informazioni sull'indirizzo IP del visitatore, il nostro script anonimizza
                automaticamente l'indirizzo IP.
                <br />
                Cookie gestiti da analytics.prezzifarmaco.it sono denominati: SUI, UID.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Analytics;
