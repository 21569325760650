import React, { Component } from 'react';
import { parse } from 'query-string';

import 'filepond/dist/filepond.min.css';

import { authService } from '../../_services/auth.service';
import { userService } from '../../_services/user.service';

class Account extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      firstname: this.props.user.firstname,
      lastname: this.props.user.lastname,
      email: this.props.user.email,
      password: '',
      checkPassword: '',
      isAccountSubmitDisabled: true,
      isPasswordSubmitDisabled: true,
    };

    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleErrorForm = this.handleErrorForm.bind(this);

    this.handleSubmitAccount = this.handleSubmitAccount.bind(this);
    this.handleSubmitPassword = this.handleSubmitPassword.bind(this);

    this.submitIsDisabled = this.submitIsDisabled.bind(this);
    this.areEqual = this.areEqual.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    const { reseted } = parse(this.props.location.search);
    if (Boolean(reseted) === true && this._isMounted) {
      this.props.history.push('/account');
      this.props.notify.warn('Reimposta la password');
    }

    window.removeLoader();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  submitIsDisabled(form, force = false) {
    for (let button of form.querySelectorAll('button[type=submit]')) {
      button.disabled = !form.checkValidity() || force;
    }
  }

  areEqual(element, checkName) {
    const form = element.form;
    const checkElement = form.querySelector(checkName);
    element.setCustomValidity('');

    if (element.value.localeCompare(checkElement.value) !== 0) {
      let message = 'I valori non coincidono.';
      let customErrorMessage = element.getAttribute('equalerror');
      element.setCustomValidity(customErrorMessage ? customErrorMessage : message);
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });

    this.handleErrorForm(event.target, event.target.parentElement, event.target.form);
  }

  handleFileChange(fileItems) {
    this.setState({
      image: fileItems.map((fileItem) => fileItem.file),
    });

    const element = document.getElementById('shopLogo');
    const form = document.getElementById('shopForm');
    this.handleErrorForm(element.firstChild, element.parentElement, form);
    this.submitIsDisabled(form);
  }

  handleErrorForm(element, parent, form) {
    if (form) {
      for (let el of form.querySelectorAll('[equal]')) {
        this.areEqual(el, el.getAttribute('equal'));
      }
    }

    for (let message of parent.querySelectorAll('.validation-message')) {
      parent.removeChild(message);
    }

    if (element.validationMessage) {
      const message = document.createElement('small');
      message.setAttribute('class', 'validation-message text-danger');
      message.innerHTML = element.validationMessage;
      parent.appendChild(message);
    }
  }

  handleSubmitAccount(e) {
    e.preventDefault();

    userService
      .update({
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        email: this.state.email,
      })
      .then(
        (user) => {
          console.log('setUser: Account');
          this.props.setUser(user);
          this.props.notify.success('Informazioni personali aggiornate.');
        },
        (error) => {
          this.props.notify.error(error.message);
          console.error(error);
        }
      );

    this.submitIsDisabled(e.target, true);
  }

  handleSubmitPassword(e) {
    e.preventDefault();

    authService.updateUserStrategy(this.props.user.id, 'password', this.state.password).then(
      (user) => {
        this.props.setUser(user);
        this.props.notify.success('Password aggiornata.');
      },
      (error) => {
        this.props.notify.error(error.message);
      }
    );

    this.submitIsDisabled(e.target, true);
  }

  render() {
    const { password, checkPassword } = this.state;

    return (
      <div>
        <div className="row">
          <div className="col-md-6 mT-25">
            <div className="bgc-white bd bdrs-3 p-20 mB-20">
              <h6 className="c-grey-900">Informazioni personali</h6>
              <div className="">
                <form onSubmit={this.handleSubmitAccount} onChange={(e) => this.submitIsDisabled(e.target.form)}>
                  <div className="form-group row">
                    <label htmlFor="firstname" className="col col-form-label">
                      Nome
                    </label>

                    <div className="col-9">
                      <input
                        name="firstname"
                        type="text"
                        className="form-control"
                        id="firstname"
                        placeholder="Nome"
                        defaultValue={this.props.user.firstname}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="lastname" className="col col-form-label">
                      Cognome
                    </label>

                    <div className="col-9">
                      <input
                        name="lastname"
                        type="text"
                        className="form-control"
                        id="lastname"
                        placeholder="Cognome"
                        defaultValue={this.props.user.lastname}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="email" className="col col-form-label">
                      Email
                    </label>

                    <div className="col-9">
                      <input
                        name="email"
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Email"
                        defaultValue={this.props.user.email}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-sm-12">
                      <button type="submit" className="btn btn-primary" disabled={true}>
                        Aggiorna
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="col-md-6 mT-25">
            <div className="bgc-white bd bdrs-3 p-20 mB-20">
              <h6 className="c-grey-900">Cambio password</h6>
              <div className="">
                <form onSubmit={this.handleSubmitPassword} onChange={(e) => this.submitIsDisabled(e.target.form)}>
                  <div className="form-group row">
                    <label htmlFor="password" className="col col-form-label">
                      Password
                    </label>

                    <div className="col-9">
                      <input
                        name="password"
                        type="password"
                        className="form-control"
                        id="password"
                        required
                        pattern=".{6,}"
                        placeholder="La nuova assword"
                        value={password}
                        onChange={(e) => this.handleChange(e)}
                      />

                      <small className="form-text text-muted">La password deve essere almeno di 6 caratteri.</small>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="checkPassword" className="col col-form-label">
                      Conferma password
                    </label>

                    <div className="col-9">
                      <input
                        name="checkPassword"
                        type="password"
                        className="form-control"
                        id="checkPassword"
                        required
                        placeholder="Scrivi nuovamente la nuova password per sicurezza"
                        value={checkPassword}
                        equal="input[name=password]"
                        equalerror="Le due password devono coincidere."
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                      />

                      <small className="form-text text-muted">Le due password devono coincidere.</small>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-sm-12">
                      <button type="submit" className="btn btn-primary" disabled={true}>
                        Cambia password
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Account;
