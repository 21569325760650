import React, { Component } from 'react';

import { Table, UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';

class ClickTable extends Component {

  _isMounted = false;

  constructor(props) {
    super(props);
    
    this.state = {
      datatables: {
        data: this.props.clicks,
        fixedHeader: {
          headerOffset: window.$('.navbar').outerHeight()
        },
        columns: [
          {
            data: {
              _:    "data.display",
              sort: "data.timestamp"
            },
          },
          { data: 'click', },
          { data: 'similarShopClick', },
          { data: 'premiumShopClick', },
        ],
        searching: !!this.props.searching ? this.props.searching : false,
        paging: !!this.props.paging ? this.props.paging : false,
        info: !!this.props.info ? this.props.info : false,
        columnDefs: [ {
          searchable: false,
          orderable: true,
          targets: 0
        } ],
        order: [[ 0, 'desc' ]],
        language: {
          "sEmptyTable": "Nessun dato presente nella tabella",
          "sInfo": "Vista da _START_ a _END_ di _TOTAL_ elementi",
          "sInfoEmpty": "Vista da 0 a 0 di 0 elementi",
          "sInfoFiltered": "(filtrati da _MAX_ elementi totali)",
          "sInfoPostFix": "",
          "sInfoThousands": ".",
          "sLengthMenu": "Visualizza _MENU_ elementi",
          "sLoadingRecords": "Caricamento...",
          "sProcessing": "Elaborazione...",
          "sSearch": "Cerca:",
          "sZeroRecords": "La ricerca non ha portato alcun risultato.",
          "oPaginate": {
            "sFirst": "Inizio",
            "sPrevious": "Precedente",
            "sNext": "Successivo",
            "sLast": "Fine"
          },
          "oAria": {
            "sSortAscending": ": attiva per ordinare la colonna in ordine crescente",
            "sSortDescending": ": attiva per ordinare la colonna in ordine decrescente"
          }
        },
      }
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.$('#dailyClickTable')
      .DataTable()
      .destroy(true);
  }

  componentDidMount() {
    this._isMounted = true;
    window.$.fn.dataTable.ext.errMode = 'none';
    window.$('#dailyClickTable').DataTable(this.state.datatables);
  }

  shouldComponentUpdate(props) {
    const table = window.$('#dailyClickTable').DataTable();
    let clicks = props.clicks
      //.sort((a, b) => b.date - a.date)
      .map((item, key) => {
        item.data = {
          "display": moment(item.date ).format('DD/MM/YYYY'),
          "timestamp": moment(item.date ).unix()
        };
        item.similarShopClick = Math.floor(item.similarShopClick);
        item.premiumShopClick = Math.floor(item.premiumShopClick);
        // item.percentChange = this.percentChange(item);
        return item;
      });

    table.clear();
    table.rows.add(clicks);
    table.draw();
    return false;
  }

  render() {
    return (
      <div className="bgc-white bd bdrs-3 p-20 mB-20 ">
        <h6 className="c-grey-900">Resoconto click giornalieri</h6>
        <div className="">
          <Table id="dailyClickTable" bordered striped hover responsive>
            <thead>
              <tr>
                <th>
                  Data
                </th>

                <th>
                  I click del tuo negozio
                  <UncontrolledTooltip placement="right" target="productClick">
                  Numero dei click del tuo negozio
                  </UncontrolledTooltip>
                  <span
                  className="text-muted"
                  href="#" id="productClick"
                  >
                  &nbsp; <i className="far fa-question-circle"></i>
                  </span>
                </th>

                <th>
                  Media negozi simile al tuo 
                  <UncontrolledTooltip placement="right" target="similarShopClic">
                  &Egrave; la media dei click ricevuti dai negozi che hanno un numero di prodotti in catalogo simili al tuo.
                  </UncontrolledTooltip>
                  <span
                  className="text-muted"
                  href="#" id="similarShopClic"
                  >
                  &nbsp; <i className="far fa-question-circle"></i>
                  </span>
                </th>

                <th>
                  Media negozi premium 
                  <UncontrolledTooltip placement="right" target="premiumShopClick">
                  La media dei click dei negozi <span className="text-warning font-weight-bold">premium</span>
                  </UncontrolledTooltip>
                  <span
                  className="text-muted"
                  href="#" id="premiumShopClick"
                  >
                  &nbsp; <i className="far fa-question-circle"></i>
                  </span>
                </th>

              </tr>
            </thead>
          </Table>
        </div>
      </div>
    )
  }
  
}

export default ClickTable;