import React, { Component } from 'react';
import { Alert } from 'reactstrap';

import { integrationService } from '../../_services/integration.service';


export default class BackLinkImage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      integration: false,
      checking: false,
    }

    this.checkIntegration = this.checkIntegration.bind(this);
  }

  async componentDidMount() {
    if (!!this.props.user.id && !!this.props.shop.id) {      
      await this.checkIntegration();
    }
    window.removeLoader();
  }

  async checkIntegration() {
    try {
      this.setState({checking: true});
      const response = await integrationService.backlink(this.props.user.id, this.props.shop.id);
      this.setState({ integration: response.integrated });
      this.setState({checking: false});
    } catch (error) {
      this.setState({ integration: false }); 
      this.setState({checking: false});
    }
  }

  render() {
    return (
      <div>
        {
          !!!this.props.shop.id
            ? <Alert color="warning"><i className="fas fa-surprise"/> Non hai ancora un negozio associato.</Alert>
            : null
        }
        {
          this.state.checking
            ? <Alert color="info"><i className="fas fa-spinner fa-spin"/> Aggiornamento informazioni.</Alert>
            : !!this.state.integration
              ? <Alert color="success"><i className="fas fa-check" /> Il tuo sito ha questa funzionalità integrata.</Alert>
              : <Alert color="warning"><i className="fas fa-surprise" /> Sembra che il tuo sito non implementi ancora questa funzionalità.</Alert>
        }
        <div className="bgc-white bd bdrs-3 p-20 mB-20 ">
          <div className="row" >        
            <div className="col-12">
              <h1>Segnala ai tuoi visitatori che il tuo ecommerce è su PrezziFarmaco</h1>            

              <div className="row">

                <div className="col-10">
                  <p>
                    Per inserire il link è necessario copiare il codice html riportato qui sotto.
                    Consigliamo di rendere visibile il bannerino su tutte le pagine del sito.
                    E' possibile personalizzare il bannerino per adattarlo alla grafica del vostro sito ma è obbligatorio rispettare le seguenti regole:            
                  </p>
                  <ul>
                    <li>Il link ( tag a href= ) deve essere sempre indirizzato verso la pagina del vostro negozio online: {this.props.shop.id ? `https://www.prezzifarmaco.it/scheda-negozio/${this.props.shop.name.split(" ").join("-").toLowerCase()}-${this.props.shop.id}` : 'https://www.prezzifarmaco.it'}</li>
                    <li>Il link ( tag a href= ) deve sempre avere il parametro rel="nofollow" </li>
                  </ul>
                  <br/>
                </div>

                      
            </div>   
            <h5>Codice</h5>     
            <div className="row">

              <div className="col-2 text-center">
                <div className="border border border-info rounded bg-light p-15 text-secondary">
                  <a title="Presente su prezzifarmaco.it" alt="Presente su prezzifarmaco.it" href={this.props.shop.id ? `https://www.prezzifarmaco.it/scheda-negozio/${this.props.shop.name.split(" ").join("-").toLowerCase()}-${this.props.shop.id}` : 'https://www.prezzifarmaco.it'} rel="noopener noreferrer nofollow" target="_blank" >
                    <img alt="Presente su prezzifarmaco.it" src="https://static.prezzifarmaco.it/submodules/prezzifarmaco/assets/images/presente%20su%20prezzifarmaco%20108x55.jpg" />
                  </a>
                </div>
              </div>
              <div className="col-10">
                <pre className="border border border-info rounded bg-light p-15 text-secondary">
                  <code>
                    {`<a title="Presente su prezzifarmaco.it" alt="Presente su prezzifarmaco.it" href="${this.props.shop.id ? `https://www.prezzifarmaco.it/scheda-negozio/${this.props.shop.name.split(" ").join("-").toLowerCase()}-${this.props.shop.id}` : 'https://www.prezzifarmaco.it'}" rel="noopener noreferrer nofollow" target="_blank">
    <img alt="Presente su prezzifarmaco.it" src="https://static.prezzifarmaco.it/submodules/prezzifarmaco/assets/images/presente%20su%20prezzifarmaco%20108x55.jpg" />
  </a>`}
                  </code>
                </pre>
              </div>
            </div>
            <div className="row">

              <div className="col-2 text-center">
                <div className="mT-10 border border border-info rounded bg-light p-15 text-secondary">
                  <a title="Presente su prezzifarmaco.it" alt="Presente su prezzifarmaco.it" href={this.props.shop.id ? `https://www.prezzifarmaco.it/scheda-negozio/${this.props.shop.name.split(" ").join("-").toLowerCase()}-${this.props.shop.id}` : 'https://www.prezzifarmaco.it'} rel="noopener noreferrer nofollow" target="_blank" >
                    <img alt="Presente su prezzifarmaco.it" src="https://static.prezzifarmaco.it/submodules/prezzifarmaco/assets/icons/icon-57.png" />
                  </a>
                </div>
              </div>
              <div className="col-10">
                <pre className="border border border-info rounded bg-light p-15 text-secondary">
                  <code>
                    {`<a title="Presente su prezzifarmaco.it" alt="Presente su prezzifarmaco.it" href="${this.props.shop.id ? `https://www.prezzifarmaco.it/scheda-negozio/${this.props.shop.name.split(" ").join("-").toLowerCase()}-${this.props.shop.id}` : 'https://www.prezzifarmaco.it'}" rel="noopener noreferrer nofollow" target="_blank">
              <img alt="Presente su prezzifarmaco.it" src="https://static.prezzifarmaco.it/submodules/prezzifarmaco/assets/icons/icon-57.png" />
              </a>`}
                  </code>
                </pre>
              </div>
              </div>  
          </div>
        </div>
      </div>
      </div>
    )
  }

}

