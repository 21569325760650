import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Table, UncontrolledTooltip } from 'reactstrap';

export default class ECommerceTable extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      own: this.props.own,
      other: this.props.other,
      table: undefined,
      datatables: {
        config: {
          fixedHeader: {
            headerOffset: window.$('.navbar').outerHeight(),
          },
          searching: !!this.props.searching ? this.props.searching : true,
          paging: !!this.props.paging ? this.props.paging : true,
          info: !!this.props.info ? this.props.info : true,
          dom: 'Bfrtip',
          buttons: [
              'copy', 'csv', 'excel', 'pdf'
          ],
          columnDefs: [
            {
              searchable: false,
              orderable: true,
              targets: 0,
            },
          ],
          // order: [[ 2, 'desc' ]],
          language: {
            sEmptyTable: 'Nessun dato presente nella tabella',
            sInfo: 'Vista da _START_ a _END_ di _TOTAL_ elementi',
            sInfoEmpty: 'Vista da 0 a 0 di 0 elementi',
            sInfoFiltered: '(filtrati da _MAX_ elementi totali)',
            sInfoPostFix: '',
            sInfoThousands: '.',
            sLengthMenu: 'Visualizza _MENU_ elementi',
            sLoadingRecords: 'Caricamento...',
            sProcessing: 'Elaborazione...',
            sSearch: 'Cerca:',
            sZeroRecords: 'La ricerca non ha portato alcun risultato.',
            oPaginate: {
              sFirst: 'Inizio',
              sPrevious: 'Precedente',
              sNext: 'Successivo',
              sLast: 'Fine',
            },
            oAria: {
              sSortAscending: ': attiva per ordinare la colonna in ordine crescente',
              sSortDescending: ': attiva per ordinare la colonna in ordine decrescente',
            },
          },
        },
        rows: [],
      },
    };

    this.reinitialiseDataTables = this.reinitialiseDataTables.bind(this);
    this.renderDataTables = this.renderDataTables.bind(this);
    this.renderType = this.renderType.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    this.reinitialiseDataTables();
    this.renderDataTables();
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.$(`#ecommerceTable_${this.props.name}`).DataTable().destroy(true);
  }

  componentDidUpdate() {
    let table = this.state.table;
    if (!!!table) return;
    table.draw();
  }

  reinitialiseDataTables() {
    if (window.$.fn.dataTable.isDataTable(`#ecommerceTable_${this.props.name}`)) {
      let table = window.$(`#ecommerceTable_${this.props.name}`).DataTable();
      table.destroy();
      return;
    }
  }

  renderDataTables() {
    let datatables = this.state.datatables;
    datatables.rows = this.props.data;

    this.setState({ datatables: datatables });
    let table = window.$(`#ecommerceTable_${this.props.name}`).DataTable(this.state.datatables.config);
    table.fixedHeader.enable();
    table.draw();
    this.setState({ table });
  }

  renderType(shopStatProducts) {
    let text = '-';
    if (!!!shopStatProducts) return text;

    switch (shopStatProducts.type) {
      case '0':
        text = 'Miglior prezzo';
        break;

      case '1':
        text = 'Vicino al miglior prezzo';
        break;

      case '2':
        text = 'Vicino al prezzo medio';
        break;

      case '3':
        text = 'Vicino al prezzo massimo';
        break;
    }

    return text;
  }

  render() {
    const { name, data, own, other, type } = this.props;

    return (
      <div className="row">
        <div className="col-12">
          <Table id={`ecommerceTable_${name}`} bordered striped hover responsive>
            <thead>
              <tr>
                <th>Posizione</th>
                <th>
                  Prodotto
                  <UncontrolledTooltip placement="right" target="productInfo">
                    Le informazioni del prodotto
                  </UncontrolledTooltip>
                  <span className="text-muted" href="#" id="productInfo">
                    &nbsp; <i className="far fa-question-circle"></i>
                  </span>
                </th>
                <th>Statistica prezzo</th>
                <th>Il tuo prezzo</th>
                <th>Prezzo minimo</th>
                <th>Prezzo medio</th>
                <th>Prezzo massimo</th>
                <th>
                  Interesse
                  <UncontrolledTooltip placement="right" target="productInterest">
                    I numeri rappresentano l'interesse di ricerca rispetto al punto più alto del grafico in relazione
                    alla regione e al periodo indicati. Il valore 100 indica la maggiore frequenza di ricerca del
                    termine, 50 indica la metà delle ricerche. Un punteggio pari a 0, invece, indica che non sono stati
                    rilevati dati sufficienti per il termine.
                  </UncontrolledTooltip>
                  <span className="text-muted" href="#" id="productInterest">
                    &nbsp; <i className="far fa-question-circle"></i>
                  </span>
                </th>
                <th>Numero offerte</th>
              </tr>
            </thead>

            <tbody>
              {data
                .filter(
                  (item) =>
                    (!!item.shop_price && !!own && !!!type) ||
                    (!!!item.shop_price && !!other && !!!type) ||
                    (!!item.shop_price && !!own && !!item.shopStatProducts && item.shopStatProducts.type === type)
                )
                .map((item, key) => {
                  return !!item.product ? (
                    <tr key={key}>
                      <td className="text-center align-middle">{item.pos + 1}</td>
                      <th className=" align-middle" scope="row">
                        <Link to={`/analytics/product/${item.product.codice_ministeriale}`}>
                          <span>{item.product.nome_prodotto}</span>
                          {!!item.product.descrizione_forma ? (
                            <span className="small">&nbsp; {item.product.descrizione_forma.toLowerCase()}</span>
                          ) : null}
                          <span className="small text-muted">&nbsp; ({item.product.codice_ministeriale})</span>
                          <br />
                          <span className="small">{item.product.descrizione_ditta_1_produttrice}</span>
                        </Link>
                      </th>
                      <td className="text-center align-middle">{this.renderType(item.shopStatProducts)}</td>
                      <td className="text-center align-middle">{!!item.shop_price ? `${item.shop_price}€` : '-'}</td>
                      <td className="text-center align-middle">{item.minprice}€</td>
                      <td className="text-center align-middle">{item.avgprice}€</td>
                      <td className="text-center align-middle">{item.maxprice}€</td>
                      <td className="text-center align-middle">{item.views}%</td>
                      <td className="text-center align-middle">{item.totoffer}</td>
                    </tr>
                  ) : null;
                })}
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}
