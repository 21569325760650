import { authHeader } from '../_helpers/auth-header';
import Resource from '../_helpers/resource';
const resource = new Resource(process.env.REACT_APP_API_URL);

export const commerceProductsService = {

  get: (product) => {

    let url = `commerce/products`;

    if(!!product) {
      url = `${url}/${product}`
    }

    return resource.get(
      url,
      authHeader()
    ).then(products => {
      return products;
    });
  },

  getTier: (tier) => {

    let url = `commerce/tiers/${tier}`;

    return resource.get(
      url,
      authHeader()
    ).then(products => {
      return products;
    });
  },

}
