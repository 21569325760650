import React, { Component } from 'react';


export default class Qapla extends Component {

  render() {
    return (
      <div className="row">
        Qapla
      </div>
    );
  }
}
