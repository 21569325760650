import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, Alert } from 'reactstrap';
import moment from 'moment';
import { commerceUserProductsService } from '../../_services/commerceUserProducts.service';
import { commerceProductsService } from '../../_services/commerceProducts.service';

import { PaymentCreditButton } from '../Payment/PaymentCreditButton';
import { SuspendCampaign } from '../Payment/SuspendCampaign';

export default class PayPerClick extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formVisible: false,
      campaingnCreated: false,
      payperclick: {
        monthclickbudget: 0,
        cpc: 0.08,
      },

      activeCampaign: { amount: 0 },
      monthly: true,
      consumptions: undefined,
      disabled: false,
    };

    this.rangeMax = 2500;

    this.clicksTiers = [
      30, 45, 50, 60, 90, 120, 150, 180, 220, 250, 270, 300, 330, 360, 390, 450,
      500, 550, 600,
    ];

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeMonthly = this.handleChangeMonthly.bind(this);
    this.getConsumption = this.getConsumption.bind(this);
    this.callOnClose = this.callOnClose.bind(this);
    this.toggleFormVisible = this.toggleFormVisible.bind(this);
  }

  async componentDidMount() {
    try {
      if (this.props.user.id && this.props.shop.id) {
        await this.getTier();
        await this.getConsumption();
      }

      window.removeLoader();
    } catch (e) {
      window.removeLoader();
      console.error(e);
    }
  }

  async callOnClose() {
    await this.setState({ campaingnCreated: true });
    setTimeout(
      function () {
        this.getConsumption();
      }.bind(this),
      60000
    );

    await this.toggleFormVisible();
    await this.getConsumption();
  }

  async getConsumption() {
    await this.setState({ consumptions: undefined });
    const consumptions = (
      await commerceUserProductsService.consumptions(
        this.props.user.id,
        'payperclick'
      )
    ).data;

    await this.setState({ consumptions });
    let now = moment().valueOf();
    let [activeCampaign] = consumptions.filter(function (consumption, index) {
      if (
        consumption.status === 'completed' &&
        consumption.to >= now &&
        consumption.from <= now
      ) {
        return consumption;
      }

      return false;
    });

    await this.setState({ activeCampaign: activeCampaign });
    if (!!this.state.activeCampaign) {
      await this.setState({ campaingnCreated: false });
    }

    let payperclick = this.state.payperclick;
    if (!!activeCampaign && !!activeCampaign.amount) {
      payperclick.monthclickbudget = parseInt(
        Math.floor(activeCampaign.amount)
      );
      this.setState({ payperclick });
    }
  }

  async getTier() {
    try {
      let payperclick = this.state.payperclick;
      let tier = !!this.state.monthly
        ? 'payperclick-consumption-monthly'
        : 'payperclick-consumption';

      let data = await commerceProductsService.getTier(tier);
      payperclick.cpc = data.amount.price;
    } catch (error) {
      console.error(error);
    }
  }

  async toggleFormVisible() {
    this.setState({ formVisible: !this.state.formVisible });
  }

  handleChange(event) {
    let payperclick = this.state.payperclick;
    payperclick.monthclickbudget = parseInt(event.target.value);
    this.setState({ payperclick });
  }

  async handleChangeMonthly(event) {
    let monthly = event.target.checked;
    await this.setState({ monthly });
    await this.getTier();
  }

  comsumptionStatus(consumption) {
    switch (consumption.status) {
      case 'revoked':
        return 'Terminata';

      case 'completed':
        if (consumption.to < moment().valueOf()) {
          return 'Terminata';
        } else {
          return 'In corso';
        }

      default:
        return '';
    }
  }

  render() {
    return (
      <div className="bgc-white bd bdrs-3 p-20 mB-20">
        <div className="row">
          <div className="col-12">
            <h2>Campagna Offerte Sponsorizzate PayPerClick</h2>
          </div>
        </div>

        <div className="row mT-25" id="buyblock">
          <div className="col-md-4">
            {!this.props.user.id || !this.props.shop.id ? (
              <Alert color="warning">
                Non hai ancora un negozio associato.{' '}
                <Link to="/shop">Vai al form di creazione.</Link>
              </Alert>
            ) : (
              <div>
                {!!this.state.activeCampaign ? (
                  <div className="">
                    {!this.state.formVisible && (
                      <div>
                        <h4>Campagna Attiva</h4>
                        <p className="text-left">
                          {this.state.activeCampaign.amount === 99999 ? (
                            <span>Non è impostato un limite di spesa</span>
                          ) : (
                            <span>
                              Spende un massimo di{' '}
                              {(
                                this.state.activeCampaign.amount *
                                this.state.activeCampaign.price
                              ).toFixed(2)}
                              €{' '}
                            </span>
                          )}
                          &nbsp;
                          {this.state.activeCampaign.to !== 1924991999999 ? (
                            <span>
                              al mese e reimposta la campagna ogni mese con lo
                              stesso budget
                            </span>
                          ) : (
                            <span>
                              la campagna termina al raggiungimento del budget
                            </span>
                          )}
                        </p>
                      </div>
                    )}
                    {!this.state.formVisible && (
                      <div className="row">
                        <div className="col-6">
                          <SuspendCampaign
                            activeCampaign={this.state.activeCampaign}
                            disabled={!!!this.state.activeCampaign}
                            onClose={this.getConsumption}
                            {...this.props}
                          ></SuspendCampaign>
                        </div>
                        <div className="col-6">
                          <button
                            className="btn btn-sm btn-success"
                            onClick={this.toggleFormVisible}
                          >
                            Modifica campagna
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    {!this.state.formVisible && (
                      <div className="row">
                        <div className="col-6">
                          <SuspendCampaign
                            activeCampaign={this.state.activeCampaign}
                            disabled={!!!this.state.activeCampaign}
                            onClose={this.callOnClose}
                            {...this.props}
                          ></SuspendCampaign>
                        </div>
                        <div className="col-6">
                          {!this.state.campaingnCreated ? (
                            <button
                              className="btn btn-sm btn-success"
                              onClick={this.toggleFormVisible}
                            >
                              Crea una campagna
                            </button>
                          ) : (
                            <span>
                              {' '}
                              La campagna è in fase di attivazione, sono
                              necessari alcuni minuti, attendi la creazione
                              della campagna.
                              <button
                                className="btn btn-sm btn-info"
                                onClick={this.getConsumption}
                              >
                                aggiorna
                              </button>{' '}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {this.state.formVisible && (
                  <form className="">
                    <div className="form-group row">
                      <div className="col-12">
                        <h4>Attiva Campagna</h4>
                      </div>
                      <div className="col-5 font-weight-bold">
                        Costo per click
                      </div>
                      <div className="col-7">{`${this.state.payperclick.cpc.toFixed(
                        2
                      )} €`}</div>
                      <label
                        htmlFor="monthclickbudget"
                        className="col-5 col-form-label font-weight-bold"
                      >
                        Imposta budget
                      </label>
                      <div className="col-7 pT-10">
                        <select
                          value={this.state.payperclick.monthclickbudget}
                          onChange={this.handleChange}
                          name="monthdaybudget"
                          aria-describedby="monthdaybudgetHelpBlock"
                          required="required"
                          className="custom-select"
                        >
                          <option value="0">
                            Zero ( servizio non attivo ){' '}
                          </option>
                          {this.clicksTiers.map((tier) => (
                            <option
                              value={Math.floor(
                                tier / this.state.payperclick.cpc
                              )}
                              key={tier}
                            >
                              {`${tier}€ (${Math.floor(
                                tier / this.state.payperclick.cpc
                              )} click)`}
                            </option>
                          ))}
                        </select>
                        {!!this.state.payperclick.monthclickbudget &&
                        this.state.payperclick.monthclickbudget !== 99999 ? (
                          <span
                            id="monthdaybudgetHelpBlock"
                            className="form-text text-muted"
                          >
                            Importo di{' '}
                            {(
                              this.state.payperclick.monthclickbudget *
                              this.state.payperclick.cpc
                            ).toFixed(2)}
                            € per {this.state.payperclick.monthclickbudget}{' '}
                            click{' '}
                          </span>
                        ) : (
                          <span
                            id="monthdaybudgetHelpBlock"
                            className="form-text text-muted"
                          >
                            Indica un limite di spesa massimo che non vuoi
                            superare
                          </span>
                        )}
                      </div>
                      <div className="col-5 mT-20 font-weight-bold">
                        Rinnovo mensile
                      </div>
                      <div className="col-7 mT-20 ">
                        <span className="switch switch-xs">
                          <input
                            type="checkbox"
                            className="switch"
                            id={`switch_monthly`}
                            name="active"
                            checked={this.state.monthly}
                            onChange={this.handleChangeMonthly}
                          />
                          <label htmlFor={`switch_monthly`}></label>
                        </span>
                        {!!this.state.monthly ? (
                          <span className="form-text text-muted">
                            Reimposta la campagna ogni mese con lo stesso budget
                          </span>
                        ) : (
                          <span className="form-text text-muted">
                            La campagna termina al raggiungimento del budget
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-12 text-right">
                        <PaymentCreditButton
                          tier={
                            !!this.state.monthly
                              ? 'payperclick-consumption-monthly'
                              : 'payperclick-consumption'
                          }
                          expire_to={
                            !!this.state.monthly
                              ? moment().endOf('month').valueOf()
                              : 1924991999999
                          }
                          quantity={this.state.payperclick.monthclickbudget}
                          disabled={
                            !!this.state.disabled ||
                            !!!this.state.payperclick.monthclickbudget
                          }
                          onClose={this.callOnClose}
                          toggleFormVisible={this.toggleFormVisible}
                          {...this.props}
                        />
                      </div>
                    </div>
                  </form>
                )}
              </div>
            )}
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-12">
                <p className="lead">
                  Indica quando credito vuoi spendere per la campagna.
                  <br />
                </p>
                <ul className="lead">
                  <li>
                    L'importo indicato impostato verrà consumato in base ai
                    click ricevuti
                  </li>
                  <li>
                    La campagna si <b>ferma automaticamente</b> al
                    raggiungimento del buget indicato
                  </li>
                  <li>
                    Rinnovo mensile: il budget indicato viene ripristinato{' '}
                    <b>il primo giorno del mese</b> successivo
                  </li>
                  <li>
                    Puoi aumentare, diminuire o sospndere la campagna{' '}
                    <b>in ogni momento</b>{' '}
                  </li>
                </ul>
                <br />
              </div>
            </div>
          </div>
        </div>
        {!!this.state.consumptions ? (
          <div className="row">
            <div className="col-12">
              <h5>Consumi </h5>
              <Table id="dailyClickTable" bordered striped hover responsive>
                <thead>
                  <tr>
                    <th>Limite Spesa</th>
                    <th>Dal</th>
                    <th>Al</th>
                    <th>Click Ricevuti</th>
                    <th>PPC</th>
                    <th>Totale</th>
                    <th>Stato</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.consumptions.map((consumption, key) => (
                    <tr key={key}>
                      <td title={'id:' + consumption.transaction}>
                        {consumption.amount === 99999 ? (
                          <span>nessuno</span>
                        ) : (
                          <span>
                            {!!consumption.price ? (
                              `${(
                                consumption.amount * consumption.price
                              ).toFixed(2)} € ( ${consumption.amount} click ) `
                            ) : (
                              <strong>Gratis</strong>
                            )}
                          </span>
                        )}
                      </td>
                      <td>{moment(consumption.from).format('DD/MM/YYYY')}</td>
                      <td>
                        {consumption.to === 1924991999999
                          ? 'limite spesa'
                          : moment(consumption.to).format('DD/MM/YYYY')}
                      </td>
                      <td>{consumption.used}</td>
                      <td>
                        {!!consumption.price ? (
                          `${consumption.price.toFixed(2)} €`
                        ) : (
                          <strong>Gratis</strong>
                        )}
                      </td>
                      <td>
                        {!!consumption.price ? (
                          `${(consumption.used * consumption.price).toFixed(
                            2
                          )} €`
                        ) : (
                          <strong>Gratis</strong>
                        )}
                      </td>
                      <td>{this.comsumptionStatus(consumption)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        ) : null}
        <div className="col-md-12">
          <div className="row">
            <div className="col-12">
              <p className="lead">
                Aiuta i clienti a trovare e acquistare i prodotti che vendi su
                con aumentando la visibilità delle offerte nella pagina di ogni
                prodotto.
                <br />
              </p>
              <ul className="lead">
                <li>Massima visibilità</li>
                <li>Scegli la soglia massima di click mensile</li>
                <li>Nessuna configurazione aggiuntiva, pensiamo a tutto noi</li>
                <li>
                  Ottieni le prime posizioni, a parità di prezzo, grazie alla
                  sponsorizzazione
                </li>
              </ul>
              <br />
            </div>
          </div>
        </div>

        <div className="row mT-25">
          <div className="col-md-6">
            <h4>Le Offerte Sponsorizzate vengono visualizzate per prime</h4>
            <img
              src="/static/images/ppc/offerte-sponsorizzate-suggeriti.png"
              alt="Esempio di offerte Sponsorizzate"
              className="img-fluid"
            />
          </div>
          <div className="col-md-6">
            <h4>Acquisisci Traffico</h4>
            <p className="lead">
              Otteni più traffico targhettizzato verso il tuo E-commerce. Le
              nostre campagne sono impostate per massimizzare i click e le
              conversioni portando ad un aumento delle vendite.
            </p>
            <h4>Controlla i costi</h4>
            <p className="lead">
              La tariffazione degli annunci pubblicitari è di tipo costo per
              clic, quindi paghi solo quando i clienti fanno clic sui tuoi
              annunci pubblicitari. Puoi controllare quanto spendi impostando il
              tuo budget massimo e monitorando il numero di click ricevuti.
            </p>
            <h4>Aumenta le vendite</h4>
            <p className="lead">
              Il programma Offerte Sponsorizzate ti aiuta a{' '}
              <b>incrementare le vendite</b> raggiungendo i clienti che cercano
              prodotti e indirizzandoli alla tua <b>tuo e-commerce</b>.
            </p>
            <h4>Fatti trovare</h4>
            <p className="lead">
              Gli annunci pubblicitari sono visualizzati dove i clienti potranno
              vederli: nella pagina delle offerte disponibili per ogni prodotto,
              fornendo un incremento istantaneo della visibilità.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
