import { verify as jwtVerify } from 'jwt-then';
import { decode } from 'jsonwebtoken';
import { authService } from '../_services/auth.service';

let timeout = undefined;

export const authJWTVerify = async () => {

  if (!!timeout) {
    clearTimeout(timeout);
  }

  let auth = localStorage.getItem('auth');
  if(!!!auth) {
    throw new Error('JWT token not found');
  }

  auth = JSON.parse(auth);
  let certificate;

  try {
    certificate = await authService.retrivePublicCertificate();
  } catch (error) {
    throw new Error('No public certificate');
  }

  try {

    const [data, currentTime ] = [
      await jwtVerify(auth.jwt, certificate.public),
      Math.floor(Date.now() / 1000),
    ];

    const sleepTime = Math.floor((data.exp - currentTime) * process.env.REACT_APP_JWT_RENEW_WINDOW);

    if (currentTime >= (data.exp - sleepTime)) {
      renewNow();
    }

    timeout = setTimeout(() => {
      try {
        return renewNow();
      } catch (error) {
        authService.logout();
      }
    }, sleepTime * 1000);

  } catch (error) {

    clearTimeout(timeout);
    authService.logout();

    try {
      window.location.replace('/user/login');
    } catch (error) {
      console.error(error);
    }          
    throw new Error(error);
  }
}

export const decodeJWT = () => {
  let auth = localStorage.getItem('auth');
  if(!!!auth) {
    throw new Error('JWT token not found');
  }

  auth = JSON.parse(auth);

  return decode(auth.jwt);
}

const renewNow = async()  => {
  try {
    authService.renew();
  } catch (error) {
    console.error(error);
  }
  return authJWTVerify();
}

