import React, { Component } from 'react';
import { Link } from 'react-router-dom';

let section;

class Sidebar extends Component {
  componentDidMount() {
    window.$('.sidebar .sidebar-menu li a').on('click', function () {
      const $this = window.$(this);

      if ($this.parent().hasClass('open')) {
        $this
          .parent()
          .children('.dropdown-menu')
          .slideUp(200, () => {
            $this.parent().removeClass('open');
          });
      } else {
        $this.parent().parent().children('li.open').children('.dropdown-menu').slideUp(200);
        $this.parent().parent().children('li.open').children('a').removeClass('open');
        $this.parent().parent().children('li.open').removeClass('open');
        $this
          .parent()
          .children('.dropdown-menu')
          .slideDown(200, () => {
            $this.parent().addClass('open');
          });
      }
    });
  }

  render() {
    return (
      <div className="sidebar">
        <div className="sidebar-inner">
          <div className="sidebar-logo">
            <div className="peers ai-c fxw-nw">
              <div className="peer peer-greed">
                <a className="sidebar-link td-n" href="/">
                  <div className="peers ai-c fxw-nw">
                    <div className="peer logocnt">
                      <div className="logo">
                        <img alt="" className="logoimgmini" src="/static/images/logo_miniv1.png" />
                        <img alt="" className="logoimg" src="/static/images/logov1.png" />
                      </div>
                    </div>
                  </div>
                </a>
              </div>

              <div className="peer">
                <div className="mobile-toggle sidebar-toggle">
                  <a href="/" className="td-n">
                    <i className="fas fa-arrow-circle-left" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <ul className="sidebar-menu scrollable pos-r">
            <li className="nav-item mT-50" onClick={(e) => (section = undefined)}>
              <Link to="/dashboard">
                <span className="icon-holder">
                  <i className="c-green-500 fas fa-home"></i>
                </span>
                <span className="title">Dashboard</span>
              </Link>
            </li>

            <li className="nav-item" onClick={(e) => (section = undefined)}>
              <Link to="/payperclick">
                <span className="icon-holder">
                  <i className="c-red-500 fas fa-chart-line"></i>
                </span>
                <span className="title">Pay per Click</span>
              </Link>

              <Link to="/google-shopping">
                <span className="icon-holder">
                  <img width={'18px'} alt="" src="/static/images/google-shopping.ico" />
                </span>
                <span className="title">Google Shopping</span>
              </Link>
            </li>

            <li className="nav-item" onClick={(e) => (section = undefined)}>
              <Link to="/analytics/products">
                <span className="icon-holder">
                  <i className="c-gray-500 fas fa-mouse-pointer"></i>
                </span>
                <span className="title">Analisi Click</span>
              </Link>
            </li>

            <li className={`nav-item dropdown ${section === 'ecommerce' ? 'open' : null}`}>
              <a
                className="dropdown-toggle"
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  section = 'ecommerce';
                }}
              >
                <span className="icon-holder">
                  <i className="c-blue-500 fas fa-shopping-cart" />
                </span>
                <span className="title">Analisi e-commerce</span>
                <span className="arrow">
                  <i className="fas fa-angle-right" />
                </span>
              </a>

              <ul className="dropdown-menu">
                <li className="nav-item dropdown">
                  <Link to="/analytics/ecommerce/market">
                    <span className="icon-holder fa-xs">
                      <i className="c-blue-500 fas fa-shopping-cart"></i>
                    </span>
                    <span className="title small"> Market</span>
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link to="/analytics/ecommerce/incomes">
                    <span className="icon-holder fa-xs">
                      <i className="c-blue-500 fas fa-shopping-cart"></i>
                    </span>
                    <span className="title small"> Volumi Fatturato</span>
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link to="/analytics/ecommerce/products">
                    <span className="icon-holder fa-xs">
                      <i className="c-blue-500 fas fa-shopping-cart"></i>
                    </span>
                    <span className="title small"> Analisi Catalogo</span>
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link to="/analytics/ecommerce/stats-history">
                    <span className="icon-holder fa-xs">
                      <i className="c-blue-500 fas fa-shopping-cart"></i>
                    </span>
                    <span className="title small"> Storia Catalogo</span>
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link to="/analytics/ecommerce/competitors">
                    <span className="icon-holder fa-xs">
                      <i className="c-blue-500 fas fa-shopping-cart"></i>
                    </span>
                    <span className="title small"> Prezzi dei Prodotti</span>
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link to="/analytics/ecommerce/competitors-custom">
                    <span className="icon-holder fa-xs">
                      <i className="c-blue-500 fas fa-shopping-cart"></i>
                    </span>
                    <span className="title small"> Analisi dei prezzi</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li className="nav-item" onClick={(e) => (section = undefined)}>
              <Link to="/analytics/transactions">
                <span className="icon-holder">
                  <i className="c-red-500 fas fa-dollar-sign"></i>
                </span>
                <span className="title">Conversioni</span>
              </Link>
            </li>

            <li className="nav-item" onClick={(e) => (section = undefined)}>
              <Link to="/shop">
                <span className="icon-holder">
                  <i className="c-blue-500 fas fa-store-alt"></i>
                </span>
                <span className="title">Negozio</span>
              </Link>
            </li>

            <li className="nav-item" onClick={(e) => (section = undefined)}>
              <Link to="/banner">
                <span className="icon-holder">
                  <i className="c-teal-500 fas fa-vector-square"></i>
                </span>
                <span className="title">Banner</span>
              </Link>
            </li>

            <li className="nav-item" onClick={(e) => (section = undefined)}>
              <Link to="/coupon">
                <span className="icon-holder">
                  <i className="c-blue-500 fas fa-money-check-alt"></i>
                </span>
                <span className="title">Coupon</span>
              </Link>
            </li>

            <li className="nav-item" onClick={(e) => (section = undefined)}>
              <Link to="/feed">
                <span className="icon-holder">
                  <i className="c-red-500 fas fa-file-import"></i>
                </span>
                <span className="title">File feed</span>
              </Link>
            </li>

            <li className={`nav-item dropdown ${section === 'service' ? 'open' : null}`}>
              <a
                className="dropdown-toggle"
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  section = 'service';
                }}
              >
                <span className="icon-holder">
                  <i className="fas fa-box-open"></i>
                </span>
                <span className="title">Servizi per Ecommerce</span>
                <span className="arrow">
                  <i className="fas fa-angle-right"></i>
                </span>
              </a>

              <ul className="dropdown-menu">
                <li className="nav-item dropdown">
                  <Link to="/partners/feedaty">
                    <span>Feedaty</span>
                  </Link>
                </li>

                {/*<li className="nav-item dropdown">
                    <Link to="/partners/qapla">
                      <span>Qapla</span>
                    </Link>
                  </li>
                   */}
              </ul>
            </li>

            <li className={`nav-item dropdown ${section === 'integrations' ? 'open' : null}`}>
              <a
                className="dropdown-toggle"
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  section = 'integrations';
                }}
              >
                <span className="icon-holder">
                  <i className="fas fa-cogs" />
                </span>
                <span className="title">Integrazioni</span>
                <span className="arrow">
                  <i className="fas fa-angle-right" />
                </span>
              </a>

              <ul className="dropdown-menu">
                <li className="nav-item dropdown">
                  <Link to="/integrations/analytics">
                    <span className="icon-holder fa-xs">
                      <i className="fas fa-cog" />
                    </span>
                    <span className="title small"> Analytics</span>
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link to="/integrations/productsTrack">
                    <span className="icon-holder fa-xs">
                      <i className="fas fa-cog" />
                    </span>
                    <span className="small"> Tracciamento acquisti</span>
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link to="/integrations/Tagmanager">
                    <span className="icon-holder fa-xs">
                      <i className="fas fa-cog" />
                    </span>
                    <span className="small"> Google TagManager</span>
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link to="/integrations/prestashopIntegration">
                    <span className="icon-holder fa-xs">
                      <i className="fas fa-cog" />
                    </span>
                    <span className="small"> Modulo PrestaShop</span>
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link to="/integrations/back-link">
                    <span className="icon-holder fa-xs">
                      <i className="fas fa-cog" />
                    </span>
                    <span className="small"> Presente su PrezziFarmaco</span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Sidebar;
