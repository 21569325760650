import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';

import FeedForm from './FeedForm';
import FeedChart from './FeedChart';
import FeedLastImport from './FeedLastImport';
import { feedService } from '../../_services/feed.service';
import { shopDetailService } from '../../_services/shopdetail.service';
import moment from 'moment';

class Feed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      feed: {},
      shopdetail: {
        details: {
          lowerprice: 0.01,
          availability: null,
          addutm: false,
        },
        id: null,
        updatedAt: null,
      },
      _details: {},
      history: [],
      isShopSubmitDisabled: true,
    };

    this.updateFeed = this.updateFeed.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const { _details } = this.state;

    if (_details.lowerprice <= 0) {
      this.setState({
        _details: {
          lowerprice: 0.01,
        },
      });
    }

    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    let feedrules = Object.assign({}, _details, { [event.target.name]: value });

    this.setState({
      _details: feedrules,
    });
  }

  async handleSubmit(event) {
    const { user, shop, notify } = this.props;
    const { _details, shopdetail } = this.state;

    event.preventDefault();

    if (_details.lowerprice <= 0) {
      this.setState({
        _details: {
          lowerprice: 0.01,
        },
      });
    }

    let _shopdetail = {
      details: {
        lowerprice: !!_details.lowerprice ? _details.lowerprice : shopdetail.details.lowerprice,
        availability: !!_details.availability ? _details.availability : shopdetail.details.availability,
        addutm: _details.addutm !== undefined ? _details.addutm : shopdetail.details.addutm,
      },
    };

    try {
      let tempShopdetail = null;

      if (shopdetail.id) {
        tempShopdetail = await shopDetailService.update(user.id, shop.id, 'feedrules', _shopdetail);
      } else {
        tempShopdetail = await shopDetailService.create(user.id, shop.id, 'feedrules', _shopdetail);
      }

      this.setState({ shopdetail: tempShopdetail });
      notify.success(`Le informazioni sono state aggiornate.`);
    } catch (error) {
      console.error(error);
      notify.error(error.message);
    }
  }

  async componentDidMount() {
    const { user, shop, from, to } = this.props;

    try {
      if (user.id && shop.id) {
        let feed = await feedService.get(user.id, shop.id);
        this.setState({ feed: feed });

        let history = await feedService.history(user.id, shop.id, from.toISOString(), to.toISOString());

        history.push(feed);
        this.setState({ history: history });

        let shopdetail = await shopDetailService.get(user.id, shop.id, 'feedrules');
        if (!!!shopdetail.details) {
          shopdetail.details = {};
        }
        if (!!!shopdetail.details.availability) {
          shopdetail.details.availability = 1;
        }
        if (!!!shopdetail.details.lowerprice) {
          shopdetail.details.lowerprice = 0.01;
        }

        if (!!!shopdetail.details.addutm) {
          shopdetail.details.addutm = false;
        }

        this.setState({ shopdetail: shopdetail });
        window.removeLoader();
      }
    } catch (e) {
      console.error(e);
      window.removeLoader();
    }

    window.removeLoader();
  }

  updateFeed(newFeed) {
    const { user, shop, feed, notify } = this.props;

    let resource = () => feedService.create(user.id, shop.id, newFeed);

    if (feed.id) {
      resource = () => feedService.update(user.id, shop.id, feed.id, newFeed);
    }

    resource()
      .then((feed) => {
        this.setState({ feed });
        notify.success(`Le informazioni sono state aggiornate e saranno pubblicate dopo l'approvazione dello staff.`);
      })
      .catch((error) => {
        console.error(error);
        notify.error(error.message);
      });
  }

  render() {
    const { user, shop } = this.props;
    const { feed, _details, shopdetail, history } = this.state;

    return (
      <div>
        <div className="row mT-25">
          <div className="col-12 col-md-8 order-md-4">
            {!user.id || !shop.id ? (
              <Alert color="warning">
                Non hai ancora un negozio associato. <Link to="/shop">Vai al form di creazione.</Link>
              </Alert>
            ) : (
              <div>
                <FeedForm user={user} shop={shop} feed={feed} updateFeed={this.updateFeed} />

                <Alert color="warning">
                  La variazione del file di catalogo è un'operazione complessa che viene gestita manualmente dal nostro
                  Team
                  <br />
                  Il nostro Team riceverà la richiesta di variazione e procederà alla verifica e implementazione del
                  nuovo file di catalogo.
                  <br />
                  Fino alla termine delle attività di implementazione del nuovo file di catalogo resterà attivo quello
                  precedente.
                  <br />
                  <br />
                  Non saranno pubblicati: <br />
                  <ul>
                    <li>Prodotti senza Autorizzazione Ministeriale ( AIC ) </li>
                    <li>Prodotti con prezzo 0</li>
                  </ul>
                </Alert>

                <Alert>
                  Convenzioni sulla disponibilità di un prodotto indicata nel file catalogo prodotti: <br />
                  <ul>
                    <li>
                      "disponibile", "in stock", "available": indica quantità 1 o superiore in magazzino ( disponibilità
                      maggiore di 0 ) ;
                    </li>
                    <li>
                      "in arrivo": indica quantità 0 in magazzino, il prodotto è ordinabile velocemente ( 1 o 2 giorni )
                      ( disponibilità uguale a 0 );
                    </li>
                    <li>
                      "non disponibile": il prodotto non è in magzzino e non è ordinabile ( disponibilità minore di 0 );
                    </li>
                  </ul>
                </Alert>

                <Alert>
                  <p>
                    <br />
                    <span className="h5">Riepilogo dei filtri attivi sul file di catalogo prodotti</span> <br />
                    <span>
                      Sono pubblicati i prodotti con prezzo maggiore o uguale a: {shopdetail.details.lowerprice}&euro;{' '}
                      <br />
                    </span>
                    {shopdetail.details.availability === 0 ? (
                      <span>
                        Sono pubblicati tutti i prodotti senza limite di disponibilità <br />{' '}
                      </span>
                    ) : (
                      <span>
                        Sono pubblicati i prodotti con disponibilità maggiore o uguale a:{' '}
                        {shopdetail.details.availability} <br />
                      </span>
                    )}
                    {!!!!shopdetail.details.addutm ? (
                      <span>
                        Aggiunge i parametri UTM per il tracking su analytics <br />{' '}
                      </span>
                    ) : (
                      <span>
                        Non aggiunge i parametri UTM per il tracking su analytics <br />
                      </span>
                    )}
                    <br />
                    {!!!shopdetail.details && !!shopdetail.updatedAt ? (
                      <span>
                        Ultimo aggiornamento {`${moment(shopdetail.updatedAt).format('DD/MM/YYYY HH:mm:ss')}`}{' '}
                      </span>
                    ) : (
                      <span></span>
                    )}
                  </p>
                </Alert>
              </div>
            )}
          </div>

          <div className="col-12 col-md-4 order-md-8 mB-30 mB-md-0">
            {!!feed.lastImport ? (
              <div>
                <FeedLastImport lastImport={feed.lastImport} />
                <div className="bgc-white bd bdrs-3 p-20 mB-20">
                  <h6 className="c-grey-900">Cambia regole di filtro del catalogo prodotti</h6>
                  <form className="" onSubmit={this.handleSubmit}>
                    <span id="lowerpriceHelpBlock" className="form-text text-muted">
                      Imposta il prezzo minimo di pubblicazione dei prodotti.
                      <br />
                      Prodotti con il prezzo inferiore a quello indicato <b>non saranno pubblicati</b>
                    </span>
                    <div className="form-group row">
                      <label htmlFor="lowerprice" className="col-8 col-form-label">
                        Pubblica se prezzo maggiore o uguale a:
                      </label>
                      <div className="col-4">
                        <input
                          min="0.01"
                          step="0.01"
                          value={!!_details.lowerprice ? _details.lowerprice : shopdetail.details.lowerprice}
                          onChange={this.handleChange}
                          name="lowerprice"
                          placeholder="0.00"
                          type="number"
                          className="form-control"
                          aria-describedby="lowerpriceHelpBlock"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="availability" className="col-8 col-form-label">
                        Pubblica se disponibilità maggiore o uguale a:
                      </label>
                      <div className="col-4">
                        <input
                          min="-100"
                          step="1"
                          defaultValue={
                            !!_details.availability ? _details.availability : shopdetail.details.availability
                          }
                          onChange={this.handleChange}
                          name="availability"
                          placeholder="0.00"
                          type="number"
                          className="form-control"
                          aria-describedby="availabilityHelpBlock"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        title="Aggiunge ?utm_source=prezzifarmaco&utm_medium=cpc&utm_campaign=direct_link alla fine di ogni url"
                        htmlFor="addutm"
                        className="col-8 col-form-label"
                      >
                        Aggiungi i parametri UTM per il tracking su analytics:
                      </label>
                      <div className="col-4">
                        <input
                          value="1"
                          checked={_details.addutm !== undefined ? _details.addutm : shopdetail.details.addutm}
                          onChange={this.handleChange}
                          name="addutm"
                          placeholder=""
                          type="checkbox"
                          className="form-control"
                          aria-describedby="availabilityHelpBlock"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-12">
                        <button name="submit" type="submit" className="btn btn-primary">
                          Invia la Richiesta
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            ) : (
              <div>
                <Alert color="warning">Non sono presenti dati per relativi all'ultimo import</Alert>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 ">{!user.id || !shop.id ? null : <FeedChart history={history} />}</div>
        </div>
      </div>
    );
  }
}

export default Feed;
