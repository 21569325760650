import { authHeader } from '../_helpers/auth-header';
import Resource from '../_helpers/resource';

const resource = new Resource(process.env.REACT_APP_API_URL);

export const paymentInvoiceService = {
  get: (userId, id) => {
    return resource.get(
      `user/${userId}/payment/invoice/${id}`,
      authHeader()
    ).then(invoice => {
      if (invoice.data) {
        return invoice.data;
      }
      
      return invoice;
    });
  },
};
