import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip, Alert } from 'reactstrap';

import moment from 'moment';

import { analyticsService } from '../../_services/analytics.service';
import { feedService } from '../../_services/feed.service';
import { shopTaxDetailService } from '../../_services/shopTaxDetail.service';

import ClickChart from './ClickChart';
import ClickTable from './ClickTable';
import Blockquote from '../Blockquote/Blockquote';
import Products from '../Analytics/Products/Widget';


class Dashboard extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      clicks: [],
      transactions: [],
      isTaxDetailSet: {},
      stats: {
        productsClicks: {}
      },
      feed: {
        lastImport: {
          valid: 0
        }
      }
    };

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async componentDidMount() {
    this._isMounted = true;

    let averageClick = {data: []};
    let click = {data: []};
    let similarShopClick = {data: []};
    let premiumShopClick = {data: []};
    
    if (!!this.props.user.id) {
      
      try {
        averageClick = await analyticsService.averageClick(this.props.from.toISOString(), this.props.to.toISOString());
        averageClick.data = averageClick.data.sort((a, b) => moment(a.date).diff(moment(b.date)));
      } catch(error) {
        console.error(error);
        averageClick = {data: []}
      }

      try {
        premiumShopClick = await analyticsService.premiumShopClick(this.props.from.toISOString(), this.props.to.toISOString());
        premiumShopClick.data = premiumShopClick.data.sort((a, b) => moment(a.date).diff(moment(b.date)));
      } catch(error) {
        console.error(error);
        premiumShopClick = {data: []}
      }
    }

    if (!!this.props.user.id && !!this.props.shop.id) {
      try {
        click = await analyticsService.click(this.props.user.id, this.props.shop.id, this.props.from.toISOString(), this.props.to.toISOString());
        click.data = click.data.sort((a, b) => moment(a.date).diff(moment(b.date)));
      } catch(error) {
        console.error(error);
        click = {data: []}
      }

      try {
        similarShopClick = await analyticsService.similarShopClick(this.props.user.id, this.props.shop.id, this.props.from.toISOString(), this.props.to.toISOString());
        similarShopClick.data = similarShopClick.data.sort((a, b) => moment(a.date).diff(moment(b.date)));
      } catch(error) {
        console.error(error);
        similarShopClick = {data: []}
      }

      try {
        let feed = await feedService.get(this.props.user.id, this.props.shop.id, this.props.from.toISOString(), this.props.to.toISOString());
        this.setState({feed: feed});
      } catch (error) {
        console.error(error);
      }

      try {
        let transactionsTotals = await analyticsService.transactionsTotals(this.props.user.id, this.props.shop.id, this.props.from.toISOString(), this.props.to.toISOString());
        this.setState({transactionsTotals: transactionsTotals.data});
      } catch (error) {
        console.error(error);
      }

      try {
        let isTaxDetailSet = await shopTaxDetailService.get(this.props.user.id, this.props.shop.id);
        this.setState({isTaxDetailSet: isTaxDetailSet});
      } catch (error) {
        delete this.state.isTaxDetailSet;
        console.error(error);
      }
    }

    if (this._isMounted) {
      let data = [];

      // let _from = moment(this.props.from).add(moment(this.props.from).utcOffset(), 'minute');
      // let _to = moment(this.props.to).add(moment(this.props.to).utcOffset(), 'minute');

      let _from = moment(this.props.from).startOf('day');
      let _to = moment(this.props.to).endOf('day');
      
      while (_from.isSameOrBefore(_to)) {
        data.push({          
          click:
            !!window._.find(click.data, {date: _from.format('YYYY-MM-DD')}) && !!window._.find(click.data, {date: _from.format('YYYY-MM-DD')}).click
              ? window._.find(click.data, {date: _from.format('YYYY-MM-DD')}).click
              : 0,
          similarShopClick:
            !!window._.find(averageClick.data, {date: _from.format('YYYY-MM-DD')}) && !!window._.find(averageClick.data, {date: _from.format('YYYY-MM-DD')}).avgClick
              ? window._.find(averageClick.data, {date: _from.format('YYYY-MM-DD')}).avgClick
              : 0,
          avgClick:
            !!window._.find(similarShopClick.data, {date: _from.format('YYYY-MM-DD')}) && !!window._.find(similarShopClick.data, {date: _from.format('YYYY-MM-DD')}).similarShopClick
              ? window._.find(similarShopClick.data, {date: _from.format('YYYY-MM-DD')}).similarShopClick
              : 0,
          premiumShopClick:
            !!window._.find(premiumShopClick.data, {date: _from.format('YYYY-MM-DD')}) && !!window._.find(premiumShopClick.data, {date: _from.format('YYYY-MM-DD')}).premiumShopClick
              ? window._.find(premiumShopClick.data, {date: _from.format('YYYY-MM-DD')}).premiumShopClick
              : 0,
          date: _from.format('YYYY-MM-DD'),
        });
        _from.add(1, 'day');
      }

      data = data
        .sort((a, b) => moment(a.date).diff(moment(b.date)));

      this.setState({clicks: data});
      let totalClicks = this.state.clicks.reduce((sum, value) => 
        sum + value.click
      , 0);

      this.setState({totalClicks: totalClicks});
    }

    window.removeLoader();
  } 

  render() {



    
    const Allerts = () => {
      return (
        <div className="sticky-top">
          {!this.state.isTaxDetailSet && (
            <Alert color="warning" style={{ width: '60%' }}>
              Non hai ancora inserito i tuo dati fiscali!&nbsp;
              <Link to="/shop/tax-detail">Fai Click qui per aggiornarli</Link>
            </Alert>
          )}
        </div>
      );
    };

    const ClickEval = () => {
      let num = this.state.totalClicks / this.state.feed.lastImport.valid * 100
      if(num <5){
          return ( <span>Valutazione: <b>migliorabile</b> meglio superiore a 6 punti</span> );
      }
          
      if(num >5 && num < 9){
          return ( <span>Valutazione: <b>Buono</b> il catalogo produce click</span> );
      }
          
      if(num >9){
          return (<span>Valutazione: <b>Ottimo</b> pricing del catalogo</span>);
      }
      return num;
    }

    const formatNumber = (q) => {
      return q.toLocaleString('it-IT', {
          style: 'currency',
          currency: 'EUR'
      })
     } 

    return (
      <div>
        <Allerts />
        <div className="row">
          <UncontrolledTooltip placement="right" target="click">
              Indica il numero di click totali del tuo negozio nel periodo selezionato
          </UncontrolledTooltip> 
          <UncontrolledTooltip placement="right" target="elements">
              Indica il numero di prodotti pubblicati su PrezziFarmaco
          </UncontrolledTooltip> 
          <UncontrolledTooltip placement="right" target="rapporto">
              Indica il rapporto tra numero click ricevuti nel periodo selezionato e il numero di prodotti presenti in catalogo.
              Indica quanti click riceve in generale un prodotto del catalogo.
          </UncontrolledTooltip> 
          <UncontrolledTooltip placement="right" target="conversioni">
              Indica il rapporto tra numero click ricevuti nel periodo selezionato e il numero di transazioni ricevute grazie a PrezziFarmaco.
          </UncontrolledTooltip> 
          <UncontrolledTooltip placement="right" target="transazioni2">
            Indica il numero totale di ordini ricevuti dal tuo negozio inclusi quelli ricevuti da PrezziFarmaco
          </UncontrolledTooltip> 
          <UncontrolledTooltip placement="right" target="ordini">
            Indica le vendite generate grazie a PrezziFarmaco e il totale complessivo dei diversi carrelli.
          </UncontrolledTooltip>

          <div className="col-sm-6 col-md-6 col-lg-4 mT-10">
            <Blockquote color="primary" icon="mouse-pointer">
              <h6>I click del tuo negozio</h6>
              <div>
                <b>{ this.state.totalClicks }</b> click nel perido selezionato
              </div>              
              <Link className="small" to="/analytics/products">
                Visualizza dettaglio <i className="fas fa-angle-right" />
              </Link>
              <span className="text-info tootTipTopRight" href="#" id="click">
                <i className="far fa-question-circle"></i>
              </span>
            </Blockquote>
          </div>          

          <div className="col-sm-6 col-md-6 col-lg-4 mT-10">
            <Blockquote color="success" icon="file-export">
              <h6>Prodotti in catalogo</h6>
              <div>
                <b>
                  { !!this.state.feed.elements ? this.state.feed.elements : 0 } {this.state.feed.elements === 1 ? 'prodotto' : 'prodotti'}
                </b>
              </div>
              <Link className="small" to="/feed">
                Visualizza dettaglio <i className="fas fa-angle-right" />
              </Link>
              <span className="text-info tootTipTopRight" href="#" id="elements">
                <i className="far fa-question-circle"></i>
              </span>
            </Blockquote>
          </div>

          {
            <div className="col-sm-6 col-md-6 col-lg-4 mT-10">
                  <Blockquote color="warning" icon="comment-dollar">
                    <h6>Transazioni</h6>
                    {!!this.state.transactionsTotals
                    ? 
                      <div>
                        <div>
                          <b>{ this.state.transactionsTotals.order_count }</b> per un totale di&nbsp;
                          <b>{ this.state.transactionsTotals.value_total.toLocaleString('it-IT', {style: 'currency',currency: 'EUR'})} €</b>
                        </div>
                        <Link className="small" to="/analytics/transactions">
                          Visualizza dettaglio <i className="fas fa-angle-right" />
                        </Link>

                      </div>
                      : 
                      <Link to="/integrations/productsTrack">
                        Attiva il tracciamento acquisti
                      </Link>
                    } 
                    <span
                      className="text-info tootTipTopRight"
                      href="#" id="transazioni2"
                      ><i className="far fa-question-circle"></i>
                    </span>
                  </Blockquote>
            </div>
          }

          <div className="col-sm-6 col-md-6 col-lg-4 mT-10">
            <Blockquote color="secondary" icon="calculator">
              <h6>Rapporto tra click e prodotti</h6>
              {
                !!this.state.clicks && !!this.state.feed && !!this.state.feed.lastImport && !!this.state.feed.lastImport.valid && this.state.totalClicks
                  ?<div>
                      <b>{(this.state.totalClicks / this.state.feed.lastImport.valid * 100 ).toFixed(1)} &nbsp;</b> 
                      <ClickEval/> <br/>
                      <small>il rapporto è un indicatore del posizionamento dell'ecommerce sui comparatori</small>
                  </div>
                  : 0 
              }
              <span className="text-info tootTipTopRight" href="#" id="rapporto">
                <i className="far fa-question-circle"></i>
              </span>
            </Blockquote>
          </div>

          <div className="col-sm-6 col-md-6 col-lg-4 mT-10">
            <Blockquote color="primary" icon="dollar-sign">
              <h6>Conversione dei click</h6>
              {
                this.state.transactionsTotals && this.state.totalClicks && this.state.transactionsTotals.pf_order_count && this.state.transactionsTotals.pf_order_count>0
                ? 
                <b>
                  Il {(( this.state.transactionsTotals.pf_gs_order_count / this.state.totalClicks ) *100 ).toFixed(2)}% dei click genera acquisti<br/>
                  <small>{this.state.totalClicks} click hanno generato {this.state.transactionsTotals.pf_gs_order_count} ordini</small>
                </b>
                : <span>Attiva il tracciamento acquisti</span>
              }
              <span className="text-info tootTipTopRight" href="#" id="conversioni">
                <i className="far fa-question-circle"></i>
              </span>
            </Blockquote>
          </div>

          <div className="col-sm-6 col-md-6 col-lg-4 mT-10">
            <Blockquote color="info" icon="handshake">
              <h6>Contribuzione PrezziFarmaco </h6>
              { this.state.transactionsTotals  && this.state.transactionsTotals.order_count > 0 
              ? <div>
                    <span>
                      <b>{ (( this.state.transactionsTotals.pf_gs_order_count / this.state.transactionsTotals.order_count  )*100 ).toFixed(0) }</b>% degli acquisti<br/>
                      <small>{this.state.transactionsTotals.pf_gs_order_count} su {this.state.transactionsTotals.order_count} Acquirenti hanno visitato anche prezzifarmaco</small>
                    </span>
                </div>
              :<span>Attiva il tracciamento acquisti</span>
              }
              <span className="text-info tootTipTopRight" href="#" id="ordini">
                <i className="far fa-question-circle"></i>
              </span>
            </Blockquote>
          </div>

        </div>

        <div className="row">
          <div className="col-md-12 mT-25">
            <ClickChart
              from={ this.props.from }
              to={ this.props.to } 
              clicks={this.state.clicks}
            />
          </div>
          <div className="col-md-6 mT-25">
            <ClickTable
              from={ this.props.from }
              to={ this.props.to } 
              clicks={this.state.clicks}
              paging
              info
            />
          </div>
          <div className="col-md-6 mT-25">
            <Products 
              user={ this.props.user }
              shop={ this.props.shop }
              searching={ false }
              from={ this.props.from }
              to={ this.props.to } 
              limit={ 5 }
              // setStats={this.setStats}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default Dashboard;