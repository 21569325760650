import React from 'react';
import { Link } from 'react-router-dom';

import Products from './Products';

export default (props) => (
  <div className="row">
    <div className="col-12">
      <div className="bgc-white bd bdrs-3 p-20 mB-20">
        <h6 className="c-grey-900">Dettaglio dei click ricevuti</h6>
        <div className="">
          <Products 
            { ...props } />
          <div className="mT-10" >
            <Link to="/analytics/products">Vedi tutti.</Link>
          </div>          
        </div>
      </div>
    </div>
  </div>      
)
