import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, Alert } from 'reactstrap';
//import { shopDetailService } from '../../_services/shopdetail.service';
import moment from 'moment';
//import { PaymentCreditButton } from '../Payment/PaymentCreditButton';
import { commerceUserProductsService } from '../../_services/commerceUserProducts.service';
import { commerceProductsService } from '../../_services/commerceProducts.service';

import { SuspendCampaign } from '../Payment/SuspendCampaign';


export default class GShopping extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formVisible: false,
      campaingnCreated:false,
      googleshopping: {
        monthclickbudget: 0,
        cpc: 0.08
      },

      activeCampaign: { amount: 0 },

      monthly: true,
      consumptions: undefined,
      disabled: false,
    };

    this.rangeMax = 2500;

    this.clicksTiers = [
      30, 45, 50, 60, 90, 120, 150, 180, 220, 250, 270, 300, 330, 360, 390, 450, 500, 550, 600
    ];

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeMonthly = this.handleChangeMonthly.bind(this);
    this.getConsumption = this.getConsumption.bind(this);

  }

  async componentDidMount() {
    try{
      if (this.props.user.id && this.props.shop.id) {        
        await this.getTier();
        await this.getConsumption();
      }
      window.removeLoader();
    } catch(e) {
      window.removeLoader();
      console.error(e);
    }
  }

  async callOnClose(){
    await this.setState({campaingnCreated: true});
    setTimeout(function () {
      this.getConsumption();
    }.bind(this), 60000);
    await this.toggleFormVisible();
    await this.getConsumption();

  }

  async getConsumption() {
    await this.setState({consumptions: undefined});
    const consumptions = (await commerceUserProductsService.consumptions(this.props.user.id,'googleshopping')).data
    await this.setState({consumptions});
    let now = moment().valueOf();
    let [activeCampaign] = consumptions.filter (function(consumption, index){ 
      if(
          consumption.status=== 'completed' 
          && consumption.to >= now
          && consumption.from <= now
        ){
          return consumption; 
        }
        return false;
    });

    await this.setState({ activeCampaign: activeCampaign });
    if( !!this.state.activeCampaign )
      await this.setState({campaingnCreated: false});

    let googleshopping = this.state.googleshopping;
    if(!!activeCampaign && !!activeCampaign.amount ){
      googleshopping.monthclickbudget = parseInt(Math.floor(activeCampaign.amount));
      this.setState({ googleshopping });   
    }
  }

  async getTier() {
    try {
      let googleshopping = this.state.googleshopping;
      let tier = !!this.state.monthly ? 'googleshopping-consumption-monthly' : 'googleshopping-consumption';
      let data = await commerceProductsService.getTier(tier);
      googleshopping.cpc = data.amount.price;
    } catch (error) {
      console.error(error);
    }
  }


  handleChange(event) {
    let googleshopping = this.state.googleshopping;
    googleshopping.monthclickbudget = parseInt(event.target.value);
    this.setState({ googleshopping });   
  }

  async handleChangeMonthly(event) {
    let monthly = event.target.checked;
    await this.setState({ monthly });
    await this.getTier();
  }

  
  comsumptionStatus(consumption) {
    switch (consumption.status) {
      case 'revoked':
        return 'Terminata'
      case 'complete':
        if(consumption.to<moment().valueOf())
          return 'Terminata'
        else
          return 'In corso'
      case 'completed':
        if(consumption.to<moment().valueOf())
          return 'Terminata'
        else
          return 'In corso'
      default:
        return "";
    }
  }

  render() {
    return (
      <div className="bgc-white bd bdrs-3 p-20 mB-20">
        <div className="row">
          <div className="col-12">
            <h2>
              Potenzia le tue vendite pubblicando le tue offerte su Google
              </h2>
          </div>
        </div>

        <div className="row mT-25" id="buyblock">
          <div className="col-md-4">
            {
              !this.props.user.id || !this.props.shop.id
                ? <Alert color="warning">
                  Non hai ancora un negozio associato. <Link to="/shop">Vai al form di creazione.</Link>
                </Alert>
                : 
                <div>
                {
                  !!this.state.activeCampaign 
                    ? 
                      <div className="">
                        { !this.state.formVisible &&
                        <div>
                          <h4>Campagna Attiva</h4>
                          <p className="text-left">
                            {
                              this.state.activeCampaign.amount===99999
                              ? <span>Non è impostato un limite di spesa</span>
                              : <span>Spende un massimo di {(this.state.activeCampaign.amount * this.state.activeCampaign.price).toFixed(2) }€ </span>
                            }
                            &nbsp;
                            {
                              this.state.activeCampaign.to!==1924991999999
                              ? <span>al mese e reimposta la campagna ogni mese con lo stesso budget</span>
                              : <span>la campagna termina al raggiungimento del budget</span>
                            }
                          </p>
                        </div>
                        }
                       { /* !this.state.formVisible && 
                        <div className="row">
                          <div className="col-6">
                            <SuspendCampaign 
                              activeCampaign = { this.state.activeCampaign }
                              disabled = { !!!this.state.activeCampaign }
                              onClose = { this.getConsumption }
                              { ... this.props }
                            >
                            </SuspendCampaign>
                          </div>
                          <div className="col-6">
                            { <button className="btn btn-sm btn-success" onClick={this.toggleFormVisible}>Modifica campagna</button> }
                          </div>
                        </div>
                       */ }
                      </div>
                    : <div>
                        { !this.state.formVisible && 
                        <div className="row">
                          <div className="col-6">
                            <SuspendCampaign 
                              activeCampaign = { this.state.activeCampaign }
                              disabled = { !!!this.state.activeCampaign }
                              onClose = { this.callOnClose }
                              { ... this.props }
                            >
                            </SuspendCampaign> 
                          </div>
                          <div className="col-6">
                            {/* 
                            !this.state.campaingnCreated
                                ? <button className="btn btn-sm btn-success" onClick={this.toggleFormVisible}>Crea una campagna</button>  
                                : <span> La campagna è in fase di attivazione, sono necessari alcuni minuti, attendi la creazione della campagna. 
                                  <button className='btn btn-sm btn-info' onClick={this.getConsumption}>aggiorna</button> </span>
                              */}
                          </div>
                        </div>
                        }
                      </div>
                }
                { 
                  this.state.formVisible &&
                  <form className="">
                      {/* 
                      <div className="form-group row">
                        <div className="col-12">
                          <h4>Attiva Campagna Google Shopping </h4>
                        </div>

                        <label htmlFor="monthclickbudget" className="col-5 col-form-label font-weight-bold">Imposta budget</label>
                        
                        <div className="col-7 pT-10">
                            <select value={ this.state.googleshopping.monthclickbudget } onChange={this.handleChange} name="monthdaybudget" aria-describedby="monthdaybudgetHelpBlock" required="required" className="custom-select">
                              <option value="0" >Zero ( servizio non attivo ) </option>
                              {
                                this.clicksTiers.map(tier => (
                                  <option 
                                    value={ Math.floor(tier / this.state.googleshopping.cpc) } 
                                    key={ tier } > 
                                      { `${tier}€`} 
                                  </option>
                                ))
                              }
                            </select>
                            {
                              !!this.state.googleshopping.monthclickbudget && this.state.googleshopping.monthclickbudget!==99999
                                ? <span id="monthdaybudgetHelpBlock" className="form-text text-muted"></span>
                                : <span id="monthdaybudgetHelpBlock" className="form-text text-muted">Indica un limite di spesa massimo che non vuoi superare</span>
                            }
                            
                        </div>

                        <div className="col-5 mT-20 font-weight-bold">Rinnovo mensile</div>
                        <div className="col-7 mT-20 ">
                          <span className="switch switch-xs">
                            <input type="checkbox" className="switch" id={`switch_monthly`}
                              name="active"
                              checked={ this.state.monthly }
                              onChange={ this.handleChangeMonthly }
                              />
                            <label htmlFor={`switch_monthly`}></label>
                          </span>
                          {
                            !!this.state.monthly
                            ? <span className="form-text text-muted">Reimposta la campagna ogni mese con lo stesso budget</span>
                            : <span className="form-text text-muted">La campagna termina al raggiungimento del budget</span>
                          }
                        </div>
                        
                      </div>

                  
                      <div className="form-group row">
                        <div className="col-12 text-right">
                          <PaymentCreditButton
                            tier={ !!this.state.monthly ? 'googleshopping-consumption-monthly' : 'googleshopping-consumption' }
                            expire_to={ !!this.state.monthly ? moment().endOf('month').valueOf() : 1924991999999 }
                            quantity={ this.state.googleshopping.monthclickbudget }                          
                            disabled={ !!this.state.disabled || !!!this.state.googleshopping.monthclickbudget }
                            onClose = { this.getConsumption }
                            { ... this.props }
                          /> 
                        </div>
                      </div>*/}
                  </form>
                }
                </div>
            }
          </div>
          <div className="col-md-8">
            <div className="row">              
              <div className="col-12">
                <p className="lead">
                  Indica quando credito vuoi spendere per la campagna.<br />
                </p>
                <ul className="lead">
                  <li>L'importo indicato impostato verrà consumato in base ai click ricevuti</li>
                  <li>La campagna si <b>ferma automaticamente</b> al raggiungimento del buget indicato</li>
                  <li>Rinnovo mensile: il budget indicato viene ripristinato <b>il primo giorno del mese</b> successivo</li>
                  <li>Puoi aumentare, diminuire o sospndere la campagna <b>in ogni momento</b> </li>
                </ul>
              </div>
            </div>
          </div>
          
        </div>
        {
          !!this.state.consumptions
            ? <div className="row">
                <div className="col-12">
                  <h5>Consumi </h5>
                  <Table id="dailyClickTable" bordered striped hover responsive>
                    <thead>
                      <tr>
                        <th>Limite Spesa</th>
                        <th>Dal</th>
                        <th>Al</th>
                        
                        <th>Stato</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.consumptions.map((consumption, key) => (
                          <tr key={key}>
                            <td title={ "id:"+consumption.transaction }>
                            { 
                              consumption.amount===99999?
                              <span>nessuno</span>:
                              <span>{
                              !!consumption.price 
                                ? `${(consumption.amount * consumption.price).toFixed(2)} €`
                                : <strong>Gratis</strong>
                              }</span>
                              }
                            </td>
                            <td>{moment(consumption.from).format('DD/MM/YYYY')}</td>
                            <td>{
                              consumption.to === 1924991999999
                              ? "limite spesa"
                              : moment(consumption.to).format('DD/MM/YYYY')
                            }</td>
                            
                            <td>{ 
                              this.comsumptionStatus(consumption)
                            }</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                </div>
              </div>
            : null
        }
        <div className="row " id="buyblock">
          <div className="mT-25 col-7">
            <h5>
              Aiuta gli utenti scoprire il tuo e-commerce ed acquistare i tuoi prodotti su Google Shopping Ads
            </h5>
            <br />

            <ul className="lead">
              <li>Scegli il butget mensile, non ci sono costi aggiuntivi</li>
              <li>Nessuna configurazione aggiuntiva, pensiamo a tutto noi</li>
              <li>Zero pensieri, solo vantaggi. Gestione automatizzata delle campagne</li>
              <li>Risparmia. Pubblicando i tuoi annunci attraverso PrezziFarmaco risparmierai fino al 20% </li>
            </ul>

            <br />
            
            <p>
              {/*<!-- <img alt="Partner Google Shoppping" src="/static/images/gs/google-shopping-partner.jpg"/> -->*/}
            </p>
          </div>
          <div className="col-5">
            <img className="img-fluid" alt="Esempio di offerte su Google Shopping " src="/static/images/gs/gs1.png" />
          </div>
        </div>
        <div className="row mT-25">
          <div className="col-4">
            <h4>
              Acquisisci Traffico
            </h4>
            <p className="lead">
              Otteni più traffico targhettizzato verso il tuo E-commerce. Le nostre campagne sono impostate per massimizzare i click e le conversioni portando ad un aumento delle vendite.
            </p>
          </div>
          <div className="col-4">
            <h4>
              Esperti del settore
            </h4>
            <p className="lead">
              PrezziFarmaco si occupa del settore Farmacia, Parafarmacia e salute online.<br />
              Le nostre campagne sono controllate e verificare da personale Farmacisti e personale specializzato.
            </p>
          </div>
          <div className="col-4">
            <h4>
              Risparmia senza rischi
            </h4>
            <p className="lead">
              Ottieni fino al 20% di sconto sul costo dei click senza bloccare le tue campagne google Ads.
              E' possibile tenere attive sia le campagne google Ads e contemporaneamente le campagne google Shopping di PrezziFarmaco ottenendo comunque lo <b>sconto sul costo per click.</b>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h3 className="mT-25">
              Pubblica le offerte dei tuoi prodotti negli spazi pubblicitari di Google in un click.
            </h3>
          </div>
        </div>
        <div className="row mT-25">
          <div className="col-5">
            <img src="/static/images/gs/gs3.png" alt="Esempio di offerte su Google Shopping" className="img-fluid" />
          </div>
          <div className="col-7">
            <h5>Cosa è Google Shopping ?</h5>
            <p className="lead mT-25">
              Google Shopping è un servizio di Google che consente agli utenti di cercare prodotti su diversi siti e confrontare i prezzi tra differenti fornitori.<br />
              Molti siti web sfruttano questo servizio, ma in pochi approfittano di CSS esterni.<br />
              Un Comparison Shopping Service (CSS), <b>come PrezziFarmaco.it</b>, è un sito web che raccoglie le offerte di prodotti da rivenditori online e rimanda gli utenti ai siti web dei rivenditori per effettuare un acquisto.<br />
            </p>
            <h5>Perché sfruttare un CSS esterno? Anche se ho già Google Shopping?</h5>
            <p className="lead">
              Esistono numerosi vantaggi:</p>
            <ul className="lead">
              <li>Un CSS esterno può aiutarti ad aumentare la visibilità dei tuoi prodotti. I CSS modificano i titoli e le descrizioni dei prodotti, quindi non si compete all'asta sugli stessi termini</li>
              <li>Inoltre, puoi accedere a diverse strategie di bidding e pagare i clic al prezzo più basso</li>
              <li>Puoi accedere a Comparison Listing Ads (CLA), a cui solo i CSS hanno accesso</li>
              <li>Il costo sarà sempre per un solo bid, quindi non corri alcun rischio a livello di CPA</li>
              <li>Aumenta la possibilità che tu o uno dei tuoi CSS vinca la gara contro i tuoi competitors</li>
              <li>Aumenti il ​​numero di impressions e click e benefici dell’aumento del CTR attraverso traffico complementare</li>
            </ul>
            <p className="lead">
              <a rel="noopener noreferrer" href="https://support.google.com/merchants/answer/7558973?hl=it" target="_blank">Il sistema di calcolo del prezzo</a> pagato per click previene l'aumento dei costi.<br />
              Offerte all'asta diverse sui tuoi prodotti <b>non aumenteranno mai il costo dei tuoi click</b>. Solo le offerte dei competitors possono rendere il prezzo dei click più costoso.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            <a className="btn btn-success btn-md btn-arrow m-t-20" href="#buyblock" >
              Richiedi l'attivazione al nostro staff <i className="fa fa-arrow-right"></i>
            </a>
          </div>
        </div>
        <div className="row ">
          <div className="col-4 text-center mT-25">
            <img alt="Partner Google Shopping" width="300px;" className="mT-25 rounded img-shadow img-fluid" src="/static/images/gs/google-css-partner-badge-lg.jpg" />
          </div>
          <div className="col-8 mT-25">
            <div className="text-box mT-25">
              <h2>Siamo Partner Certificato Google CSS</h2>
              <p className="lead">Un "CSS" o "Servizio di Comparazione Prezzi" è un sito Web che raccoglie i dati dei tuoi prodotti e reindirizza gli annunci commerciali al tuo sito Web di e-commerce.</p>
              <p className="lead">
                I nostri partner possono sfruttare al meglio la nostra tecnologia e la nostra esperienza per amplificare la portata dei loro prodotti per il nuovo formato di annungi Google Shopping.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
