import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import moment from 'moment';

import { shopDetailService } from '../../_services/shopdetail.service';

export default class Feedaty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      feedaty: {},
    };

    this.requestFeedaty = this.requestFeedaty.bind(this);
    this.cancelRequestFeedaty = this.cancelRequestFeedaty.bind(this);
  }

  async componentDidMount() {
    try {
      if (this.props.user.id && this.props.shop.id) {
        let shopdetail = await shopDetailService.get(this.props.user.id, this.props.shop.id, 'feedaty');
        this.setState({
          feedaty: {
            requesttime: shopdetail.details.requesttime,
            id: shopdetail.id,
          },
        });
      }

      window.removeLoader();
    } catch (e) {
      console.error(e);
      window.removeLoader();
    }
  }

  cancelRequestFeedaty() {
    let shopdetail = {
      details: {
        requesttime: null,
      },
    };

    this.setFeedaty(shopdetail);
  }

  requestFeedaty() {
    let shopdetail = {
      details: {
        requesttime: new Date(),
      },
    };

    this.setFeedaty(shopdetail);
  }

  async setFeedaty(shopdetail) {
    let resource = () => shopDetailService.create(this.props.user.id, this.props.shop.id, 'feedaty', shopdetail);

    if (this.state.feedaty.id) {
      resource = () => shopDetailService.update(this.props.user.id, this.props.shop.id, 'feedaty', shopdetail);
    }

    try {
      let shopdetail = await resource();
      this.setState({
        feedaty: {
          requesttime: shopdetail.details.requesttime,
          id: shopdetail.id,
        },
      });

      this.props.notify.success(`Le informazioni sono state aggiornate e inviate allo staff.`);
    } catch (error) {
      console.error(error);
      this.props.notify.error(error.message);
    }
  }

  render() {
    return (
      <div className="bgc-white bd bdrs-3 p-20 mB-20">
        <div className="row">
          <div className="col-12">
            <h2>Feedaty: recensioni nel tuo e-commerce</h2>
          </div>
        </div>

        <div className="row " id="requestForm">
          <div className="mT-25 col-5">
            <h5>Feedaty: recensioni certificate</h5>
            <br />
            <p className="lead">
              Vantaggi
              <br />
            </p>

            <ul className="lead">
              <li>Avrai una percezione più chiara di punti di forza o debolezza del tuo servizio</li>
              <li>
                Avrai un sigillo di "Opinioni Certificate" che garantirà la tua trasparenza e l'autenticità delle
                recensioni da te raccolte
              </li>

              <li>
                Migliorerai la tua visibilità sui motori di ricerca grazie alla raccolta delle recensioni sulle Pagine
                Certificate di Feedaty
              </li>

              <li>Potrai contare sul supporto costante del team Feedaty, in tutte le fasi di utilizzo del servizio</li>
            </ul>
            <br />

            {!this.props.user.id || !this.props.shop.id ? (
              <Alert color="warning">
                Non hai ancora un negozio associato. <Link to="/shop">Vai al form di creazione.</Link>
              </Alert>
            ) : (
              <div>
                {this.state.feedaty.requesttime ? (
                  <p>
                    Richiesta inviata il {`${moment(this.state.feedaty.requesttime).format('DD/MM/YYYY HH:mm:ss')}`}
                    <br />
                    <button onClick={this.cancelRequestFeedaty} className="btn btn-warning btn-md btn-arrow m-t-20">
                      Cancella la richiesta <i className="fa fa-ban"></i>
                    </button>
                  </p>
                ) : (
                  <p>
                    <button onClick={this.requestFeedaty} className="btn btn-success btn-md btn-arrow m-t-20">
                      Richiedi l'attivazione al nostro staff <i className="fa fa-arrow-right"></i>
                    </button>
                  </p>
                )}
              </div>
            )}

            <p className="lead">
              Feedaty propone a tutti gli shop dotati di una presenza digitale di esternalizzare la gestione dei
              feedback degli utenti, e quindi della reputazione online del brand, in modo da aumentare la trasparenza
              nel processo di raccolta delle recensioni. L'outsourcing diventa una garanzia dell'imparzialità dei
              commenti rilasciati sul web.
            </p>
          </div>

          <div className="col-7">
            <img
              alt="Feedaty Esempio Recensione"
              className="rounded img-shadow img-responsive"
              src="/static/images/feedaty/Feedaty-Zoorate.jpg"
            />
          </div>
        </div>

        <div className="row mT-25">
          <div className="col-12 text-center">
            <h2>Opinioni e Recensioni certificate</h2>
          </div>
        </div>

        <div className="row ">
          <div className="col-4 text-center ">
            <img
              alt="Opinioni e Recensioni Certificate"
              width="300px;"
              className="mT-25 rounded img-shadow img-responsive"
              src="/static/images/feedaty/miosito2-1.jpg"
            />
          </div>

          <div className="col-8 mT-25 ">
            <div className="text-box">
              <p className="lead">
                Il commerciante ha la possibilità di ottenere un servizio migliore e, nel contempo, di migliorare la
                propria offerta.
                <br /> Una volta ricevuto un commento negativo lo comunichiamo al negozio interessato dal contenuto. La
                recensione negativa rimane congelata per due settimane prima di essere pubblicata, in modo da dare la
                possibilità al merchant di sentire il cliente insoddisfatto,
                <br /> capire cosa non ha funzionato e, infine, provare a risolvere il problema causato all'acquirente.
                <br /> Se l'acquirente vorrà potrà modificare la recensione nella quale attaccava lo shop prima che gli
                altri abbiano modo di visualizzarla.
              </p>
            </div>
          </div>
        </div>

        <div className="row mT-25">
          <div className="col-12 text-center">
            <a className="btn btn-success btn-md btn-arrow m-t-20" href="#requestForm">
              Richiedi l'attivazione al nostro staff <i className="fa fa-arrow-right"></i>
            </a>
          </div>
        </div>

        <div className="row mT-25">
          <div className="col-4">
            <h4>Semplice e Professionale</h4>
            <p className="lead">
              Feedaty ti consente la gestione professionale di feedback e opinioni certificate dei tuoi clienti,
              migliorando il tuo posizionamento, la tua reputazione e le tue vendite online.
            </p>
          </div>

          <div className="col-8">
            <iframe
              title="Feedaty"
              src="https://player.vimeo.com/video/80549362"
              width="640"
              height="360"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            />
          </div>
        </div>

        <div className="row mT-25">
          <div className="col-12 text-center">
            {/* <h4>Listino</h4>
            <img
              alt="Listino"
              className="rounded img-shadow img-responsive"
              src="/static/images/feedaty/listino_feedaty_2019.png"
            /> */}

            <p>
              <a className="btn btn-success btn-md btn-arrow m-t-20" href="#requestForm">
                Richiedi l'attivazione al nostro staff per ottenere i vantaggi <i className="fa fa-arrow-right"></i>
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
