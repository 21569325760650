import { authHeader } from '../_helpers/auth-header';
import Resource from '../_helpers/resource';
const resource = new Resource(process.env.REACT_APP_API_URL);

export const bannerService = {

  stats: (userId, shopId) => {
    return resource.get(
      `user/${userId}/shop/${shopId}/banner/stats`,
      authHeader()
    )
  },

  list: (userId, shopId) => {
    return resource.get(
      `user/${userId}/shop/${shopId}/banner`,
      authHeader()
    )
  },

  create: (userId, shopId, body={}) => {
    return resource.post(
      `user/${userId}/shop/${shopId}/banner`,
      body,
      authHeader()
    )
  },

  update: (userId, shopId, bannerId, body={}) => {
    return resource.put(
      `user/${userId}/shop/${shopId}/banner/${bannerId}`,
      body,
      authHeader()
    )
  },

  delete: (userId, shopId, bannerId) => {
    return resource.delete(
      `user/${userId}/shop/${shopId}/banner/${bannerId}`,
      authHeader()
    )
  },

  views: (userId, shopId, id, from, to, limit) => {
    let api = `user/${userId}/shop/${shopId}/analytics/banner/${id}/views`;
    let queryString = new URLSearchParams();

    if(!!from) {
      queryString.set('from', from);
    }

    if(!!to) {
      queryString.set('to', to);
    }

    if(!!limit) {
      queryString.set('limit', limit);
    }

    api = `${api}?${queryString.toString()}`;   

    return resource.get(
      api,
      authHeader()
    )
  },

  clicks: (userId, shopId, id, from, to, limit) => {
    let api = `user/${userId}/shop/${shopId}/analytics/banner/${id}/clicks`;
    let queryString = new URLSearchParams();

    if(!!from) {
      queryString.set('from', from);
    }

    if(!!to) {
      queryString.set('to', to);
    }

    if(!!limit) {
      queryString.set('limit', limit);
    }

    api = `${api}?${queryString.toString()}`;   

    return resource.get(
      api,
      authHeader()
    )
  },

}
