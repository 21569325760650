import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Alert } from 'reactstrap';
import classnames from 'classnames';

import { analyticsService } from '../../../_services/analytics.service';
import ECommerceTable from './ECommerceTable';

export default class Competitors extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      products: null,
      activeTab: '1',
    };

    this.retrieveAnalyticsECommerce = this.retrieveAnalyticsECommerce.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.retrieveAnalyticsECommerce();
    window.removeLoader();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async retrieveAnalyticsECommerce() {
    const { user, shop } = this.props;
    const self = this;

    if (!!user.id && !!shop.id) {
      try {
        let { data: products } = await analyticsService.ecommerce(user.id, shop.id);

        if (self._isMounted) {
          this.setState({ products });
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  render() {
    const { user, shop, searching, paging, info } = this.props;
    const { products, activeTab } = this.state;

    const payLoad = {
      products,
      searching,
      paging,
      info,
      activeTab,
    };

    const toggle = (tab) => {
      if (activeTab !== tab) {
        this.setState({ activeTab: tab });
      }
    };

    const renderNavLink = (activeTab, tabId, title) => {
      return (
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === tabId,
              'text-primary': activeTab === tabId,
            })}
            onClick={() => {
              toggle(tabId);
            }}
            href="#"
          >
            {title}
          </NavLink>
        </NavItem>
      );
    };

    const renderTable = (payLoad, tabId, name, own, other, type) => {
      return (
        <TabPane tabId={tabId}>
          <Row>
            <Col sm="12" className="mT-20">
              {!!payLoad.products && payLoad.activeTab === tabId ? (
                <ECommerceTable
                  name={name}
                  own={own}
                  other={other}
                  type={type}
                  data={payLoad.products}
                  searching={payLoad.searching}
                  paging={payLoad.paging}
                  info={payLoad.info}
                />
              ) : (
                <div>
                  <i className="fas fa-spinner fa-spin" /> Caricamento dati
                </div>
              )}
            </Col>
          </Row>
        </TabPane>
      );
    };

    return !user.id || !shop.id ? (
      <Alert color="warning">
        Non hai ancora un negozio associato. <Link to="/shop">Vai al form di creazione.</Link>
      </Alert>
    ) : (
      <div className="row">
        <div className="col-12 mT-10">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title text-muted">Analisi dei prezzi dei prodotti nel mercato online</h4>
              <h6>La consultazione è possibile solo sui primi 1000 prodotti ordinati per interesse di ricerca.</h6>

              <Nav tabs>
                {renderNavLink(activeTab, '1', 'Tutti i prodotti')}
                {renderNavLink(activeTab, '2', 'I tuoi prodotti')}
                {renderNavLink(activeTab, '3', 'I prodotti dei competitor')}
              </Nav>

              <Nav tabs>
                {renderNavLink(activeTab, '4', 'I tuoi prodotti, miglior prezzo')}
                {renderNavLink(activeTab, '5', 'I tuoi prodotti, vicino al miglior prezzo')}
                {renderNavLink(activeTab, '6', 'I tuoi prodotti, vicino al prezzo medio')}
                {renderNavLink(activeTab, '7', 'I tuoi prodotti, vicino al prezzo massimo')}
              </Nav>

              <TabContent activeTab={activeTab}>
                {renderTable(payLoad, '1', 'all', true, true)}
                {renderTable(payLoad, '2', 'own', true, false)}
                {renderTable(payLoad, '3', 'other', false, true)}
                {renderTable(payLoad, '4', 'bestPrice', true, false, '0')}
                {renderTable(payLoad, '5', 'nearBestPrice', true, false, '1')}
                {renderTable(payLoad, '6', 'nearAvgPrice', true, false, '2')}
                {renderTable(payLoad, '7', 'nearMaxPrice', true, false, '3')}
              </TabContent>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
