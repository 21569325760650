import { authHeader } from '../_helpers/auth-header';
import Resource from '../_helpers/resource';
const resource = new Resource(process.env.REACT_APP_API_URL);

export const feedService = {

  get: (userId, shopId) => {
    const api = `user/${userId}/shop/${shopId}/feed`;

    return resource.get(
      api,
      authHeader()
    );
  },

  create: (userId, shopId, request) => {
    return resource.post(
      `user/${userId}/shop/${shopId}/feed`,
      request,
      authHeader()
    );
  },

  update: (userId, shopId, id, request) => {
    return resource.put(
      `user/${userId}/shop/${shopId}/feed/${id}`,
      request,
      authHeader()
    );
  },

  history: (userId, shopId, from, to, limit) => {
      let api = `user/${userId}/shop/${shopId}/feedhistory`;
      let queryString = new URLSearchParams();

      if(!!from) {
        queryString.set('from', from);
      }
  
      if(!!to) {
        queryString.set('to', to);
      }
  
      if(!!limit) {
        queryString.set('limit', limit);
      }
  
      api = `${api}?${queryString.toString()}`; 

      return resource.get(
          api,
          authHeader()
      );
  },
}
