import React, { Component } from 'react';

import { Alert } from 'reactstrap';
import { Link } from 'react-router-dom';

import moment from 'moment-timezone';

import { couponService } from '../../_services/coupon.service';
import { commerceUserProductsService } from '../../_services/commerceUserProducts.service';


import CouponElement from './CouponElement';

class Coupon extends Component {

  constructor(props) {
    super(props);

    this.state = {
      subscription: undefined,
      products: undefined,
      userProducts: undefined,
      coupons: [],
      adding: false,
      activeCoupons: 0,
    };

    this.updateCoupons = this.updateCoupons.bind(this);
    this.deleteCoupon = this.deleteCoupon.bind(this);
    this.addCoupon = this.addCoupon.bind(this);
    this.closeAddCoupon = this.closeAddCoupon.bind(this);

  }

  addCoupon() {
    this.setState({adding: true});
  }

  updateCoupons(coupon) {
    let coupons = this.state.coupons;
    if (this.state.coupons.find((element, key) => {
      if (element.id === coupon.id) {
        coupons[key] = coupon;
        return true;
      }
      return false;
    }) === undefined) {
      coupons.push(coupon);
    }
    
    this.setState({coupons: coupons});
  }

  deleteCoupon(id) {
    this.setState({
      coupons: this.state.coupons.filter( coupon => coupon.id !== id )
    });
  }

  closeAddCoupon(coupon = undefined) {
    this.setState({adding: false});
    if (coupon !== undefined) {
      const coupons = this.state.coupons;
      this.setState({coupons: coupons});
    }
  }

  async componentDidMount() {

    if(this.props.user.id && this.props.shop.id) {

      let [coupons, userProducts] = [        
        await couponService.list(this.props.user.id, this.props.shop.id),
        await commerceUserProductsService.userProducts(this.props.user.id)
      ];

      coupons = coupons.map(coupon => {
        if (!!!coupon.pending) {
          coupon.pending = moment().isAfter(coupon.to);
        }
        return coupon;
      });

      this.setState({ coupons });
      this.setState({ userProducts });
      window.removeLoader();
    }else{
      window.removeLoader();
    }
  }

  render() {
    return (
      <div>
        {
          this.props.shop.id
            ? <div className="row">
                <div className="col-12 col-md-4 order-md-8 mB-30 mB-md-0">
        
                  <div>
                    <button className="btn btn-outline-primary btn-block" 
                      onClick={this.addCoupon}
                      >
                      <i className="fas fa-plus-circle"></i>&nbsp;
                      Aggiungi coupon
                    </button>
                  </div>
        
                </div>
                <div className="col-12 col-md-8 order-md-4">
                  <div className="row">
                    {
                      this.state.adding
                        ? <div className="col-12 mB-20">
                            <CouponElement
                              userProducts={ this.state.userProducts }
                              user={ this.props.user }
                              shop={ this.props.shop }
                              from={ this.props.from }
                              to={ this.props.to } 
                              activeCoupons={ this.state.coupons.filter(value => !!value.active).length }
                              notify={ this.props.notify }
                              coupon={{}}
                              add={ true }
                              closeAddCoupon={ this.closeAddCoupon }
                              updateCoupons={ this.updateCoupons } />
                          </div>
                        : ''
                    }
                    {
                      this.state.coupons.length
                        ? this.state.coupons
                            .sort((a, b) => a.from - b.from)
                            .sort((a, b) => a.pending - b.pending)
                            // .sort((a, b) => b.active - a.active)
                            .map((coupon, key) => 
                            <div className="col-12 mB-20" key={coupon.id ? coupon.id : key}>
                              <CouponElement
                                userProducts={ this.state.userProducts }
                                user={ this.props.user }
                                shop={ this.props.shop }
                                from={ this.props.from }
                                to={ this.props.to } 
                                activeCoupons={ this.state.coupons.filter(value => !!value.active).length }
                                notify={ this.props.notify }
                                coupon={ coupon }
                                updateCoupons={ this.updateCoupons }
                                deleteCoupon={ this.deleteCoupon } />
                            </div>
                          )                  
                        : <div className="col-12 mB-20">
                            <Alert color="info">Crea ora il tuo primo coupon.</Alert>
                            <CouponElement
                              userProducts={ this.state.userProducts }
                              user={ this.props.user }
                              shop={ this.props.shop }
                              from={ this.props.from }
                              to={ this.props.to } 
                              activeCoupons={ this.state.coupons.filter(value => !!value.active).length }
                              notify={ this.props.notify }
                              coupon={{}}
                              add={ true }
                              force={ true }
                              closeAddCoupon={ this.closeAddCoupon }
                              updateCoupons={ this.updateCoupons } />
                          </div>
                    }
                  </div>
                </div>                
              </div> 
            : 
              <div className="row">
                <div className="col">
                  <Alert color="warning">
                    Non hai ancora un negozio associato. <Link to="/shop">Vai al form di creazione.</Link>
                  </Alert>
                </div>
              </div>
        }       
      </div>
    )
  }
}

export default Coupon;