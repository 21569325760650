import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { PrivateRoute } from '../../_components/PrivateRoute';
import Container from '../Container';
import Signin from '../Signin';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      shop: {},
      notify: toast,
    };

    this.setUser = this.setUser.bind(this);
    this.setShop = this.setShop.bind(this);
  }

  setUser(user) {
    this.setState({ user });
  }

  setShop(shop) {
    this.setState({ shop });
  }

  render() {
    return (
      <div>
        <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} autoClose={3000} pauseOnFocusLoss={false} />
        <Router>
          <Switch>
            <Route
              path="/user/login"
              render={(props) => <Signin setUser={this.setUser} notify={this.state.notify} {...props} />}
            />

            <Route
              path="/user/reset"
              render={(props) => <Signin setUser={this.setUser} notify={this.state.notify} {...props} />}
            />

            <PrivateRoute
              path="/"
              location={window.location}
              component={Container}
              user={this.state.user}
              setUser={this.setUser}
              shop={this.state.shop}
              setShop={this.setShop}
              api={this.state.api}
              notify={this.state.notify}
              {...this.props}
            />

            <Redirect from="*" to="/dashboard" />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
