import React, { Component } from 'react';

class FeedForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      catalogUrl: this.props.feed.catalogUrl,
      isShopSubmitDisabled: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleErrorForm = this.handleErrorForm.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.submitIsDisabled = this.submitIsDisabled.bind(this);
  }

  submitIsDisabled(form, force = false) {
    for (let button of form.querySelectorAll('button[type=submit]')) {
      button.disabled = !form.checkValidity() || force;
    }
  }
  
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
    this.handleErrorForm(event.target, event.target.parentElement, event.target.form);
  }

  handleErrorForm(element, parent, form) {    
    if (form) {
      for (let el of form.querySelectorAll('[equal]')) {
        this.areEqual(el, el.getAttribute('equal'));
      }
    }

    for (let message of parent.querySelectorAll('.validation-message')) {
      parent.removeChild(message);      
    }

    if (element.validationMessage) {
      const message = document.createElement('small');
      message.setAttribute('class', 'validation-message text-danger');
      message.innerHTML = element.validationMessage;
      parent.appendChild(message);
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    const feed = {
      catalogUrl: this.state.catalogUrl,
    };
    this.props.updateFeed(feed);
    this.submitIsDisabled(e.target, true);
  }

  render() {

    return(
      <div className="bgc-white bd bdrs-3 p-20 mB-20">
        <h6 className="c-grey-900">File feed</h6>
        <div className="">
                      
          <form id="feedForm" onSubmit={ this.handleSubmit } onChange={ e => this.submitIsDisabled(e.target.form) }>

            <div className="form-group row">
              <label htmlFor="catalogUrl" className="col col-form-label">Indirizzo catalogo</label>
              <div className="col-10">
                <input
                  name="catalogUrl"
                  type="url"
                  className="form-control"
                  id="catalogUrl"
                  required
                  placeholder="Indirizzo catalogo"
                  pattern="^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$"
                  size="30"
                  defaultValue={ this.props.feed.catalogUrl }
                  onSelect={(val) => this.props.feed.catalogUrl = val}
                  onChange={e => this.handleChange(e)} />
                <small className="form-text text-muted">L'indirizzo web del tuo catalogo.</small>
              </div>
            </div>

            <div className="form-group row">
              <div className="col-sm-12">
                <button type="submit" className="btn btn-primary" disabled={  true  } >Aggiorna</button>
              </div>
            </div>

          </form>

        </div>
      </div>
    )
  }
}

export default FeedForm;
