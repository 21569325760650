import { authHeader } from '../_helpers/auth-header';
import Resource from '../_helpers/resource';
const resource = new Resource(process.env.REACT_APP_API_URL);

export const paymentService = {
  cratePayment: (userId, request = {}) => {
    return resource.post(`user/${userId}/payment/create`, request, authHeader()).then((payment) => payment);
  },

  payment: (userId, provider, paymentId) => {
    return resource
      .get(`user/${userId}/payment/${provider}/payment-id/${paymentId}`, authHeader())
      .then((payment) => payment);
  },

  findOneSubsription: (userId) => {
    return resource.get(`user/${userId}/payment/subsription`, authHeader()).then((payment) => payment);
  },

  deleteSubsription: (userId, request = {}) => {
    return resource.post(`user/${userId}/payment/delete/subsription`, request, authHeader()).then((payment) => payment);
  },
};
