import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';

import { searchService } from '../../_services/search.service';

class ShopSocialUrls extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      value: !!this.props.social && !!this.props.social.name ? this.props.social.name : '',
      suggestions: [],
    };

    this.onChange = this.onChange.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.getSuggestionValue = this.getSuggestionValue.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
  }

  onChange(event, { newValue, method }) {
    this.setState({
      value: newValue
    });
  }

  
  async onSuggestionsFetchRequested({ value }) {

    if (value.length < 3) {
      return;
    }

    let suggestions = await searchService.getGooglePlaceId(this.props.user.id, this.props.shop.id, value);

    this.setState({
      suggestions: suggestions.results.splice(0, 5)
    });
  };

  onSuggestionsClearRequested() {
    this.setState({
      suggestions: []
    });
  };

  getSuggestionValue(suggestion) {
    this.props.updateSocialUrl(this.props.type, suggestion)
    return suggestion.name;
  }

  renderSuggestion(suggestion) {
    return (
      <div className="row">        
        <div className="col">
          <span>{ suggestion.name }</span>
          <br/>
          <span className="small">{ suggestion.formatted_address }</span>
        </div>
      </div>
    );
  }


  render() {
    const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: `Cerca il tuo prodotto`,
      className: 'form-control',
      value,
      onChange: this.onChange
    };

    return (
      <div className="form-group row">
        <label htmlFor={`socialUrl-${this.props.type}`} className="col col-form-label">
          { this.props.type ==='google_mybusiness' ? 'Nome attività' : 'Indirizzo pagina' }&nbsp;
          <strong>
            { this.props.type ==='facebook' ? 'Facebook' : null }
            { this.props.type ==='trovaprezzi' ? 'TrovaPrezzi' : null }
            { this.props.type ==='ekomi' ? 'Ekomi' : null }
            { this.props.type ==='feedaty' ? 'Feedaty' : null }
            { this.props.type ==='certire' ? 'Certire' : null }
            { this.props.type ==='trustpilot' ? 'TrustPilot' : null }
            { this.props.type ==='trustedshop' ? 'TrustedShop' : null }
            { this.props.type ==='topnegozi' ? 'TopNegozi' : null }
            { this.props.type ==='amazon' ? 'Amazon' : null }
            { this.props.type ==='google_mybusiness' ? 'Google myBusiness' : null }
          </strong>
        </label>

        <div className="col-10">
          {
            this.props.type ==='google_mybusiness'
              ? <div>
                  <Autosuggest                    
                    suggestions={ suggestions }
                    onSuggestionsFetchRequested={ this.onSuggestionsFetchRequested }
                    onSuggestionsClearRequested={ this.onSuggestionsClearRequested }
                    getSuggestionValue={ this.getSuggestionValue }
                    renderSuggestion={ this.renderSuggestion }
                    inputProps={inputProps}
                    ref={el => this.suggest = el}
                  />
                  <small className="form-text text-muted">
                    Il nome della tua attività su <strong>Google myBusiness</strong>.
                  </small>
                </div>
              : <div>
                  <input name={`socialUrl-${this.props.type}`} type="url" className="form-control" 
                    id={`socialUrl-${this.props.type}`} placeholder="L'indirizzo del social"
                    onChange={ e => this.props.updateSocialUrl(this.props.type, e.target.value) }
                    defaultValue={ !!this.props.social ? this.props.social.socialUrl : null }
                    />
                  <small className="form-text text-muted">
                    L'indirizzo della tua pagina&nbsp;
                    <strong>
                      { this.props.type ==='facebook' ? 'Facebook' : null }
                      { this.props.type ==='trovaprezzi' ? 'TrovaPrezzi' : null }
                      { this.props.type ==='ekomi' ? 'Ekomi' : null }
                      { this.props.type ==='feedaty' ? 'Feedaty' : null }
                      { this.props.type ==='certire' ? 'Certire' : null }
                      { this.props.type ==='trustpilot' ? 'TrustPilot' : null }
                      { this.props.type ==='trustedshop' ? 'TrustedShop' : null }
                      { this.props.type ==='topnegozi' ? 'TopNegozi' : null }
                      { this.props.type ==='amazon' ? 'Amazon' : null }                          
                    </strong>.
                  </small>
                </div>
          }
          
        
        </div>                    
      </div>
    );
  }
}

export default ShopSocialUrls;