import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Alert } from 'reactstrap';

import { orderService } from '../../_services/order.service';
import { commerceProductsService } from '../../_services/commerceProducts.service';


export class PaymentCreditButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      expire_to: 1924991999999,
      tier: this.props.to,
      quantity: 0,
      price: 0,
      errors: [],
    };

    this.toggle = this.toggle.bind(this);
    this.payment = this.payment.bind(this);
    this.runToggleFormVisible = this.runToggleFormVisible.bind(this);
  }

  setAlert(message, level='info') {
    let errors = this.state.errors
    errors.push({
      level,
      message,
    });
    this.setState({ errors });
  }
  
  async toggle() {
    
    await this.setState({ errors: [] });

    if( !!(!this.state.modal) ) {
      let tier = await commerceProductsService.getTier(this.props.tier);
      this.setState({ 
        quantity: !!this.props.quantity ? this.props.quantity : 0,
        price: !!tier.amount.price ? tier.amount.price : 0,
        expire_to: this.props.expire_to,
        tier
      });
    }

    await this.setState({modal: !this.state.modal});    
    await this.execCallback();
  }

  async execCallback() {
    try {      
      if (!!this.props.onClose && !!!this.state.modal) {    
        await this.props.onClose();
      }
    } catch (error) {
      console.warn('Error in toggle function callback');
    }
  }

  async runToggleFormVisible(){
    await this.props.toggleFormVisible();
  }

  async payment() {
    this.setState({ errors: [] });
    try {
      await orderService.create(
        this.props.user.id,
        {
          tier: this.props.tier,
          price: this.props.price,
          expire_to: this.props.expire_to,
          quantity: this.state.quantity,
        }
      );
      
      this.props.notify.success('Acquisto avvenuto correttamente.');
      await this.setState({modal: !this.state.modal});
      await this.execCallback();
  
    } catch (error) {

      let code;      

      try {
        code = error.message.code;
      } catch (error) {
        code = undefined;
      }

      switch (code) {
        case 'NOT_ENOUGH_MONEY':

          let alertText = (<div>
            Non hai credito sufficiente per completare l'acquisto.<br />
            <Link to="/payment">Acquista credito.</Link>              
          </div>);

          this.setAlert(alertText, 'warning');

          break;
      
        default:
          console.error(error);          
          break;
      }

      this.props.notify.warn('Si è verificato un problema durante il processo di acquisto.');
    }
    
  }

  render() {
    return (
      <div>
        <div className="row text-center">
          <div className="col-6">
          <Button className="btn btn-default"  onClick={this.runToggleFormVisible}>Indietro</Button>
          </div>
          <div className="col-6">
          <Button 
            color="primary" 
            className="btn btn-primary" 
            onClick={this.toggle} 
            disabled={ this.props.disabled }
            >
              Attiva
          </Button>
          </div>
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Conferma</ModalHeader>

          <ModalBody>

            {
              this.state.errors.map((alert, key) => (
                <Alert color={ alert.level } key={key}>
                  { alert.message }
                </Alert>
              ))
            }

            <div className="row">

              <div className="col-5 font-weight-bold">Prodotto:</div>
          <div className="col-7 text-right">{ !!this.state.tier ? this.state.tier.name : null }</div>
            </div>

            {
              !!this.state.quantity
                ? <div className="row">                                      
                    <div className="col-5 font-weight-bold">Prezzo unitario:</div>
                    <div className="col-7 text-right">{ this.state.price } € </div>

                    <div className="col-5 font-weight-bold">Quantità:</div>
                    <div className="col-7 text-right">{ this.state.quantity }</div>
                  </div>
                :  null
            }

            <div className="row">
              <div className="col-12">
                <br/>
              </div>
            </div>

            <div className="row">
              <div className="col-5 font-weight-bold">Totale:</div>
              <div className="col-7 text-right">{ (this.state.price *  this.state.quantity).toFixed(2) } €</div>
            </div>

          </ModalBody>

        
            <ModalFooter>
              <Button color="primary" onClick={ this.payment }>Procedi</Button>
              <Button color="secondary" onClick={ this.toggle }>Annulla</Button>
            </ModalFooter>
          
        </Modal>
      
      </div>
    );
  }
}