import React, { Component } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

import moment from 'moment';

class FeedLastImport extends Component {

  render() {
    const { lastImport } = this.props;

    return(
      <div className="bgc-white bd bdrs-3 p-20 mB-20">
        <h6 className="c-grey-900">Statistiche ultimo import</h6>
        <div className="">
          <div className="row">

            <div className="col-5">Inizio:</div>
            <div className="col-7 text-right">
              { `${moment(lastImport.duration.start).format('DD/MM/YYYY HH:mm:ss')}` }
            </div>

            <div className="col-5">Fine:</div>
            <div className="col-7 text-right">
              { `${moment(lastImport.duration.end).format('DD/MM/YYYY HH:mm:ss')}` }
            </div>

            <div className="col-5">Durata:</div>
            <div className="col-7 text-right">
              { `${lastImport.duration.value} ${lastImport.duration.unit}` }            
            </div>

            <div className="col-12">&nbsp;</div>

            <div className="col-9">
              <UncontrolledTooltip placement="right" target="tooltipManaged">
                Numero dei prodotti presenti nel file di catalogo
              </UncontrolledTooltip>
              Prodotti presenti <span
                className="text-info"
                href="#" id="tooltipManaged"
                ><i className="far fa-question-circle"></i>:
              </span>
            </div>
            <div className="col-3 text-right">
              { `${lastImport.managed}` }
            </div>

            <div className="col-9">
              <UncontrolledTooltip placement="right" target="tooltipValid">
                Numero dei prodotti riconosciuti
              </UncontrolledTooltip>
              Prodotti validi <span
                className="text-info"
                href="#" id="tooltipValid"
                ><i className="far fa-question-circle"></i>:
              </span>
            </div>
            <div className="col-3 text-right">
              { `${lastImport.valid}` }
            </div>

            <div className="col-9">
              <UncontrolledTooltip placement="right" target="tooltipPublished">
                  Numero di offerte pubblicate
              </UncontrolledTooltip>
              Offerte pubblicate <span
              className="text-info"
              href="#" id="tooltipPublished"
                  ><i className="far fa-question-circle"></i>:
          </span>
              </div>
              <div className="col-3 text-right">
                  { `${lastImport.published}` }
          </div>
          <div className="col-9">
              <UncontrolledTooltip placement="right" target="tooltipExclusion">
                  Numero di offerte escluse dai filtri sul catalogo
              </UncontrolledTooltip>
              Offerte escluse <span
              className="text-info"
              href="#" id="tooltipExclusion"
                  ><i className="far fa-question-circle"></i>:
          </span>
              </div>
              <div className="col-3 text-right">
                  { `${lastImport.exclusion}` }
          </div>

          </div>
        </div>
      </div>
    )
  }
}

export default FeedLastImport;