// import { decodeJWT } from '../_helpers/auth-jwt';
import { authHeader } from '../_helpers/auth-header';
import Resource from '../_helpers/resource';
const resource = new Resource(process.env.REACT_APP_API_URL);

export const userService = {

  me: () => {
    return resource.get(
      'user',
      authHeader()
    )
  },

  update: (request) => {
    return resource.put(
      'user',
      request,
      authHeader()
    )
  }

}
