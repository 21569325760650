import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import Color from 'color';

import moment from 'moment';

class IncomeChartsPerc extends Component {
  constructor(props) {
    super(props);

    this.chartColors = {
      red: 'rgb(255, 99, 132)',
      orange: 'rgb(255, 159, 64)',
      yellow: 'rgb(255, 205, 86)',
      green: 'rgb(75, 192, 192)',
      blue: 'rgb(54, 162, 235)',
      purple: 'rgb(153, 102, 255)',
      grey: 'rgb(201, 203, 207)',
    };

    this.state = {
      rendered: false,

      chartReference: undefined,

      chart: {
        labels: [],
        datasets: [
          {
            label: 'Fatturato Totale',
            fill: false,
            borderColor: this.chartColors.blue,
            backgroundColor: Color(this.chartColors.blue).lighten(0.2).rgb(),
          },
          {
            label: 'Fatturato del tuo negozio',
            fill: false,
            hidden: false,
            borderColor: this.chartColors.yellow,
            backgroundColor: Color(this.chartColors.yellow).lighten(0.2).rgb(),
          },
        ],
      },

      options: {
        responsive: true,
        title: {
          display: true,
          text: 'Fatturato in %',
        },
        legend: {
          position: 'bottom',
          // onClick: this.chartLabelAction,
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        hover: {
          mode: 'nearest',
          intersect: true,
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Giorno',
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Fatturato',
              },
            },
          ],
        },
      },
    };

    this.chartLabelAction = this.chartLabelAction.bind(this);
  }

  componentDidUpdate() {
    if (!!!this.props.statsHistory.length || this.state.rendered) {
      return;
    }

    this.setState({ rendered: true });

    let chart = this.state.chart;
    let statsHistory = this.props.statsHistory.sort((a, b) => moment.utc(a.data).diff(moment.utc(b.data)));

    chart.labels = statsHistory.map((x) => moment.utc(x.data).local().format('DD/MM/YYYY h:mm'));

    chart.datasets[0].data = statsHistory.map((x) => parseInt(x.total));
    chart.datasets[1].data = statsHistory.map((x) => parseInt(x.shop));

    this.setState({ chart });
  }

  chartLabelAction(e, legendItem) {}

  render() {
    return (
      <div className="bgc-white bd bdrs-3 p-20 mB-20 ">
        <h6 className="c-grey-900">Volume di fatturato espresso in percentuale</h6>
        <div>
          <Line
            ref={(reference) => (this.chartReference = reference)}
            data={this.state.chart}
            options={this.state.options}
            height={80}
          />
        </div>
        <p>Come leggere i dati visualizzati:</p>
        <p>
          I valori visualizzati sono indicativi dell'andamento del fatturato e non del valore. <br />
          Il valore visualizzato è proporzionale al massimo nel periodo visualizzato.
        </p>
        <p>
          <b>
            Obiettivo di questo grafico è fornire una rappresentazione dell'andamento del Fatturato Totale e di quello
            del prorpio e-commerce in termini qualitativi e non quantitativi.
          </b>
        </p>
      </div>
    );
  }
}

export default IncomeChartsPerc;
