import React, { useState, useEffect } from 'react';
import { Alert } from 'reactstrap';
import { shopTaxDetailService } from '../../_services/shopTaxDetail.service';

const FormInput = ({ taxDetail, errors, handleChange, inputData, required }) => {
  return (
    <div className="form-group row">
      <label htmlFor={inputData.name} className="col col-form-label">{inputData.placeholder}</label>
      <div className="col-10">
        <input
          id={inputData.name}
          className="form-control b-5"
          name={inputData.name}
          type={inputData.type || 'text'}
          maxLength={inputData.maxLength || 255}
          placeholder={inputData.placeholder}
          pattern={inputData.pattern}
          defaultValue={taxDetail[inputData.name] || null}
          onBlur={(e) => handleChange(e, e.target)}
          required={required}
        />
        <small className="form-text text-muted">
          {inputData.text}
          {required && <strong> Campo obbligatorio</strong>}
        </small>
        <small className="validation-message text-danger">{errors[inputData.name]}</small>
      </div>
    </div>
  );
};

const Label = ({ text }) => {
  return (
    <div>
      <label className="col-form-label text-uppercase font-weight-bold">{text}</label>
    </div>
  );
};

const Inputs = ({ taxDetail, errors, handleChange, pecOrSdiRequired }) => {
  return (
    <>
      <Label text="Dati fiscali" />
      <FormInput
        taxDetail={taxDetail}
        errors={errors}
        required={true}
        handleChange={handleChange}
        inputData={{
          name:'paese_fiscale',
          placeholder: `Paese`,
          pattern: '[a-zA-Z]{2,}',
          text: `Il paese dell'azienda.`,
        }}
      />
      <FormInput
        taxDetail={taxDetail}
        errors={errors}
        handleChange={handleChange}
        required={true}
        inputData={{
          name:'partita_iva',
          placeholder: `Partita Iva`,
          pattern: '[a-zA-Z0-9]{7,13}',
          maxLength: "13",
          text: `La Partita Iva dell'azienda.`,
        }}
      />
      <div className="form-group row">
        <label htmlFor="no_iva" className="col col-form-label">Soggetto esente IVA</label>
        <div className="col-10">
          <select
            id="no_iva"
            className="form-control b-5"
            name="no_iva"
            value={(taxDetail["no_iva"] === undefined ? "" : Number(taxDetail["no_iva"]))}
            onChange={(e) => handleChange(e, e.target)}
            required
          >
            <option value="">Seleziona</option>
            <option value="0">No, Non sono un soggetto esente IVA</option>
            <option value="1">Si, Sono un soggetto esente IVA</option>
          </select>
          <small className="form-text text-muted">
            Soggetto esente IVA
            <strong> Campo obbligatorio</strong>
          </small>
          <small className="validation-message text-danger">{errors["no_iva"]}</small>
        </div>
      </div>
      <FormInput
        taxDetail={taxDetail}
        errors={errors}
        handleChange={handleChange}
        required={false}
        inputData={{
          name:'codice_fiscale',
          placeholder: `Codice Fiscale dell'azienda`,
          pattern: '[a-zA-Z0-9]{10,16}',
          maxLength: "16",
          text: `Il Codice Fiscale dell'azienda.`,
        }}
      />
      <Label text="Dati anagrafici" />
      <FormInput
        taxDetail={taxDetail}
        errors={errors}
        required={true}
        handleChange={handleChange}
        inputData={{
          name:'denominazione',
          placeholder: `Denominazione sociale`,
          pattern: '.{3,}',
          text: `La Denominazione sociale dell'azienda.`,
        }}
      />
      <FormInput
        taxDetail={taxDetail}
        errors={errors}
        required={true}
        handleChange={handleChange}
        inputData={{
          name:'paese_sede',
          placeholder: `Paese`,
          pattern: '[a-zA-Z]{2,}',
          text: `Il paese dove si trova l'azienda.`,
        }}
      />
      <FormInput
        taxDetail={taxDetail}
        errors={errors}
        handleChange={handleChange}
        required={true}
        inputData={{
          name:'cap',
          placeholder: `CAP`,
          pattern: '[0-9]{5}',
          maxLength: "5",
          text: `Il CAP.`,
        }}
      />
      <FormInput
        taxDetail={taxDetail}
        errors={errors}
        required={true}
        handleChange={handleChange}
        inputData={{
          name:'provincia',
          placeholder: `Provincia`,
          pattern: '[a-zA-Z]{2,}',
          text: `La Provincia (se non disponibile indicare la sigla del paese).`,
        }}
      />
      <FormInput
        taxDetail={taxDetail}
        errors={errors}
        required={true}
        handleChange={handleChange}
        inputData={{
          name:'comune',
          placeholder: `Comune`,
          pattern: '[a-zA-Z]{3,}',
          text: `Il Comune per esteso.`,
        }}
      />
      <FormInput
        taxDetail={taxDetail}
        errors={errors}
        required={true}
        handleChange={handleChange}
        inputData={{
          name:'indirizzo',
          placeholder: `Indirizzo`,
          pattern: '.{5,}',
          text: `L'indirizzo dell'azienda.`,
        }}
      />
      <Label text="Riferimento destinatario" />
      <FormInput
        taxDetail={taxDetail}
        errors={errors}
        handleChange={handleChange}
        required={pecOrSdiRequired.pec}
        inputData={{
          name:'pec',
          placeholder: `Inserisci qui la pec dell'aziendale`,
          pattern: '.{5,}',
          text: `L'indirizzo PEC dell'azienda.`,
          type:'email',
        }}
      />
      <FormInput
        taxDetail={taxDetail}
        errors={errors}
        handleChange={handleChange}
        required={pecOrSdiRequired.sdi}
        inputData={{
          name:'sdi',
          placeholder: `Il Codice destinatario dell'azienda (SDI).`,
          pattern: '[a-zA-Z0-9]{7}',
          maxLength: "7",
          text: `Se il codice SDI non è disponibile, inserire “0000000” (7 zeri)`,
        }}
      />
      <Label text="Contatti per fatturazione/amministrazione" />
      <FormInput
        taxDetail={taxDetail}
        errors={errors}
        handleChange={handleChange}
        required={true}
        inputData={{
          name:'email',
          placeholder: `Email`,
          pattern: '.{5,}',
          text: `L'indirizzo email dell'amministrazione.`,
          type:'email',
        }}
      />
      <FormInput
        taxDetail={taxDetail}
        errors={errors}
        handleChange={handleChange}
        required={false}
        inputData={{
          name:'telefono',
          placeholder: `Telefono`,
          pattern: '.{9,14}',
          maxLength: "14",
          text: `Il numero di telefono dell'amministrazione.`,
        }}
      />
    </>
  );
};

const ShopTaxDetail = (props) => {
  const { shop, user, notify } = props;

  const [taxDetail, setTaxDetail] = useState({});
  const [errors, setErrors] = useState({});
  const [hasError, setHasError] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [pecOrSdiRequired, setPecOrSdiRequired] = useState({sdi: true, pec: true});

  // eslint-disable-next-line
  useEffect(() => {fetchData()}, []);
  useEffect(() => {
    if (taxDetail.pec) return setPecOrSdiRequired({...pecOrSdiRequired, sdi: false});
    if (taxDetail.sdi) return setPecOrSdiRequired({...pecOrSdiRequired, pec: false});

    setPecOrSdiRequired({sdi: true, pec: true});
  }, [taxDetail]);

  const setCleenedData = obj => {
    delete obj.user;
    delete obj.updatedAt;
    delete obj.createdAt;
    setTaxDetail(obj);
  };

  const fetchData = async () => {
    try {
      if (user.id && shop.id) {
        let correntTaxDetail = await shopTaxDetailService.get(user.id, shop.id, false);
        setCleenedData(correntTaxDetail);
        window.removeLoader();
      }
    } catch(e) {
      console.error(e); 
      window.removeLoader();
    }

    window.removeLoader();
  };

  const handleChange = (e, t) => {
    setTaxDetail({...taxDetail, [t.name]: t.value});
    setErrors({...errors, [t.name]: t.validationMessage});
    handleErrorForm(t, t.form);
  };

  const handleErrorForm = (target, form) => {
    setHasError(false);

    for (let el of form) {
      if (!!el.validationMessage) {
        setHasError(true);
        break;
      }
    }

    if (target.validationMessage === '') {
      let { [target.name]: foo, ...noEmpty } = errors;
      setErrors({...noEmpty});
    }
  };

  const showAllErrors = (e) => {
    for (let el of e.target.form) {
      if (!!el.validationMessage) {
        setErrors(errors => {
          return {...errors, [el.name]: el.validationMessage}
        });
      }
    }
  };

  const handleSubmit = (e) => {
    setIsSubmit(true);
    showAllErrors(e);
    if (!hasError) {
      let resource = () => shopTaxDetailService.create(shop.id, taxDetail)
        .then((shopTaxDetail) => shopTaxDetail);

      if (taxDetail.id) {
        resource = () => shopTaxDetailService.update(user.id, shop.id, taxDetail);
      }

      resource()
        .then(shopTaxDetail => {
          setCleenedData(shopTaxDetail)
          notify.success(`Le informazioni sono state aggiornate con successo!`);
        })
        .catch(error => {
          console.error('error:', error);
          notify.error(`C'è stato un problema nel salvataggio delle informazioni!`);
        });

      setIsSubmit(false);
    }
  };

  const Allerts = () => {
    return (
      <div className="sticky-top">
        <Alert color="warning">
          Ci sono errori nel form. Corregili per poter salvare le informazioni.                     
        </Alert>
      </div>
    );
  };

  return (
    <div>        
      <div className="row">
        <div className="col-12 mT-10">
          <div className="bgc-white bd bdrs-3 p-20 mB-20">
            <h6 className="c-grey-900">Dati di fatturazione del tuo negozio</h6>
            <div className="">
              <form id="shopTaxDetailForm" onSubmit={handleSubmit} >
                {(isSubmit && hasError) && <Allerts />}
                <Inputs taxDetail={taxDetail} errors={errors} handleChange={handleChange} pecOrSdiRequired={pecOrSdiRequired}/>
                <div className="form-group row">
                  <div className="col-sm-12 text-right">
                    <button className="btn btn-primary" type="button" onClick={handleSubmit}>Aggiorna</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopTaxDetail;
