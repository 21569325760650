import React, { Component } from 'react';

class Subscription extends Component {
  render() {
    return (
      <h1>Subscription</h1>
    );
  }
}

export default Subscription;