import React, { Component } from 'react';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { Nav, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, Modal, ModalBody } from 'reactstrap';
import Autosuggest from 'react-autosuggest';
import moment from 'moment';
import TagManager from 'react-gtm-module';

import { authService } from '../../_services/auth.service';
import { prezziFarmacoService } from '../../_services/prezzifarmaco.service';
import { developService } from '../../_services/develop.service';

import DataPicker from '../../_components/DataPicker';
import { creditService } from '../../_services/credit.service';

const tagManagerArgs = { gtmId: 'GTM-PW6XXLRZ' };
TagManager.initialize(tagManagerArgs);

class Header extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      switchUserModal: false,
      _developShops: [],
      value: '',
      suggestions: [],
    };

    this.toggle = this.toggle.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.switchUser = this.switchUser.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.getSuggestionValue = this.getSuggestionValue.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
    this.renderSidebarToggle();
  }

  componentDidMount() {
    this._isMounted = true;

    if (this.props.user.id) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'set_user_id',
          userId: this.props.user.id,
        },
      });
    }

    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        page: window.location.pathname.substring(1),
      },
    });

    if (this.props.user.id && this.props.user.developer) {
      developService._getUserShop().then((users) => {
        this.setState({ _developShops: users });
      });
    }

    this.getUserCredits();
    this.renderSidebarToggle();
  }

  async getUserCredits() {
    if (this._isMounted) {
      try {
        let response = await creditService.amount();
        this.setState({
          credits: response.amount,
          creditUpdatedAt: response.updatedAt,
        });
      } catch (error) {}
    }
  }

  switchUser(e) {
    this.setState((prevState) => ({
      switchUserModal: !prevState.switchUserModal,
    }));
  }

  handleSwitchUser(id) {
    developService._developSwitchUser(id).then(() => {
      window.location.reload();
    });
  }

  async handleLogout(e) {
    e.preventDefault();

    try {
      authService.logout();
    } catch (error) {
      console.error(error);
    }

    this.props.setUser({});
    this.props.setShop({});

    try {
      const { from } = this.props.location.state || { from: { pathname: '/' } };
      this.props.history.push(from);
    } catch (error) {
      console.error(error);
    }
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  renderSidebarToggle() {
    window.$('.sidebar-toggle').on('click', (e) => {
      window.$('#root').toggleClass('is-collapsed');
      e.preventDefault();
    });
  }

  onChange(event, { newValue, method }) {
    this.setState({
      value: newValue,
    });
  }

  async onSuggestionsFetchRequested({ value }) {
    let suggestions = await prezziFarmacoService.suggest(value);
    try {
      this.setState({
        suggestions: suggestions.prods,
      });
    } catch (error) {
      //alert("Si è verificato un errore durante la ricerca");
      console.log(error);
    }
  }

  onSuggestionsClearRequested() {
    this.setState({
      suggestions: [],
    });
  }

  getSuggestionValue(suggestion) {
    const { from } = this.props.location.state || {
      from: { pathname: `/analytics/product/${suggestion.codice_ministeriale}` },
    };
    this.props.history.push(from);
    window.location.reload();
    return `${suggestion.nome_prodotto}`;
  }

  renderSuggestion(suggestion, { query }) {
    return (
      <div className="row">
        <div className="col-10">
          <span>{suggestion.nome_prodotto}</span>
          <span className="small">&nbsp; ({suggestion.codice_ministeriale})</span>
          <br />
          <span className="small">{suggestion.descrizione_ditta_1_produttrice}</span>
        </div>

        <div className="col-2">
          <img
            alt=" "
            width={30}
            src={`https://static.fogliettoillustrativo.net/ws/m2i/${suggestion.codice_ministeriale}`}
          />
        </div>
      </div>
    );
  }

  render() {
    const { shop, user } = this.props;
    const { value, suggestions } = this.state;

    let creditdStyle = {};
    if (this.state.credits && this.state.credits > 20) {
      creditdStyle = {
        color: `green`,
      };
    } else {
      creditdStyle = {
        color: `red`,
      };
    }

    const inputProps = {
      placeholder: `Cerca il tuo prodotto`,
      value,
      onChange: this.onChange,
    };

    const options = this.state._developShops
      .map((user, ukey) =>
        user.shop.map(function (shop, skey) {
          let o = {
            key: `${ukey}-${skey}`,
            value: shop.id,
            label: `${shop.id} ${shop.name} - ${user.email} - ${user.active ? '' : 'bloccato'}`,
          };

          return o;
        })
      )
      .flat();

    return (
      <div className="header navbar">
        <div className="header-container">
          <ul className="nav-left">
            <li>
              <a id="sidebar-toggle" className="sidebar-toggle" href="/">
                <i className="fas fa-bars"></i>
              </a>
            </li>

            {shop.name ? (
              <li>
                <div className="mT-20">
                  <span className="tuonegozio">Il tuo negozio </span>
                  <a
                    target="_blank"
                    href={`https://www.prezzifarmaco.it/scheda-negozio/${shop.shop_id}`}
                    rel="noopener noreferrer"
                  >
                    {shop.name}
                  </a>
                  &nbsp;-&nbsp;
                  <span>
                    <a
                      title={`Ultimo consumo ${moment(this.state.creditUpdatedAt).format('DD/MM/YYYY H:mm:ss')}`}
                      style={creditdStyle}
                      href="/payment"
                    >
                      Credito: {this.state.credits ? this.state.credits.toFixed(2) : 0}€
                    </a>
                  </span>
                </div>
              </li>
            ) : (
              ''
            )}

            <li className="dpscnt">
              <div className="maindatepicker">
                <DataPicker from={this.props.from} to={this.props.to} changeDates={this.props.changeDates} />
              </div>

              <div className="searchbox">
                <Autosuggest
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                  getSuggestionValue={this.getSuggestionValue}
                  renderSuggestion={this.renderSuggestion}
                  inputProps={inputProps}
                />
              </div>
            </li>
          </ul>

          <Nav className="nav-right">
            <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle}>
              <DropdownToggle nav caret>
                <i className="fas fa-cogs" />
              </DropdownToggle>

              <DropdownMenu>
                {user.developer === true ? (
                  <div>
                    <DropdownItem onClick={this.switchUser}>
                      <i className="fas fa-user-secret  mR-10" />
                      <span>Cambia utente</span>
                    </DropdownItem>
                    <DropdownItem divider />
                  </div>
                ) : (
                  ''
                )}

                <DropdownItem tag={Link} to="/account/">
                  <i className="fas fa-user mR-10" />
                  <span>Account</span>
                </DropdownItem>

                <DropdownItem divider />
                <DropdownItem tag={Link} to="/payment">
                  <i className="fas fa-euro-sign mR-10" />
                  <span>Pagamenti</span>
                </DropdownItem>

                <DropdownItem divider />
                <DropdownItem onClick={(e) => this.handleLogout(e)}>
                  <i className="fas fa-power-off mR-10" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </div>

        {user.developer === true ? (
          <Modal isOpen={this.state.switchUserModal} toggle={this.switchUser}>
            <ModalBody>
              <form>
                <div className="form-group">
                  <label htmlFor="shopid">ID negozio</label>
                  <Select
                    id="shopid"
                    value={undefined}
                    onChange={(event) => {
                      this.handleSwitchUser(event.value);
                    }}
                    options={options}
                  />
                </div>
              </form>
            </ModalBody>
          </Modal>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default Header;
