import React, { Component } from 'react';
import moment from 'moment-timezone';

import BannerElementEdit from './BannerElementEdit';
import BannerChart from './BannerChart';

import { bannerService } from '../../_services/banner.service';

let state = {};

class BannerElement extends Component {

  constructor(props) {
    super(props);

    this.state = window._.merge({
      banner: {
        title: this.props.banner.title ? this.props.banner.title : undefined,
        text: this.props.banner.text ? this.props.banner.text : undefined,
        url: this.props.banner.url ? this.props.banner.url : this.props.shop.base_url,
        active: this.props.banner.active ? this.props.banner.active : this.props.shop.active,
        from: this.props.banner.from ? this.props.banner.from : undefined,
        to: this.props.banner.to ? this.props.banner.to : undefined,
        id: this.props.banner.id ? this.props.banner.id : undefined,
      },
      edit: this.props.add ? true : false,
      _banner: this.props.banner ? JSON.parse(JSON.stringify(this.props.banner)) : {},
      disabled: false,
      showBanner: false,
    }, state);


    this.setBanner = this.setBanner.bind(this);
    this.updateBanner = this.updateBanner.bind(this);
    this.closeBanner = this.closeBanner.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleActivation = this.handleActivation.bind(this);
  }

  componentWillUnmount() {
    this.setState({banner: undefined});
  }

  setBanner(key, value) {
    const banner = this.state.banner;
    banner[key] = value
    this.setState({banner: banner});
  }

  updateBanner(banner) {
    this.setState({_banner: banner});
    const replace = {
      banner: {
        title: banner.title,
        text: banner.text,
        url: banner.url,
        active: banner.active,
        from: banner.from,
        to: banner.to,
      },
    };
    this.setState(replace);
    this.props.updateBanners(banner);
    this.closeBanner(banner);
  }

  closeBanner(banner = undefined) {
    this.setState({
      title: this.state._banner.title,
      text: this.state._banner.text,
      url: this.state._banner.url,
      active: this.state._banner.active,
      from: this.state._banner.from,
      to: this.state._banner.to,
      edit: false
    });

    if (this.props.add) {      
      this.props.closeAddBanner(banner);
    }
  }

  handleEdit(e) {
    e.preventDefault();
    this.setState({edit: !this.state.edit});
    if (!this.state.edit === false) {
      this.closeBanner();
    }
  }

  async handleActivation(banner) {
    this.setState({ disabled: true });
    let target = window.event.target;

    try {
      banner.active = target.checked;
      banner = await bannerService.update(this.props.user.id, this.props.shop.id, banner.id, banner);
    
      if (target.checked !== banner.active) {
        throw new Error('Impossible to activate');
      }
      target.checked = banner.active;
      this.setState({ banner });

      if (banner.pending) {
        this.props.notify.success(`Banner '${banner.title}' ${target.checked ? 'pubblicato' : 'rimosso dalla pubblicazione'} ed è in attesa di approvazione.`);
      } else {
        this.props.notify.success(`Banner '${banner.title}' ${target.checked ? 'pubblicato' : 'rimosso dalla pubblicazione'}.`);
      }

      this.props.updateBanners(banner);
    } catch (error) {
      target.checked = false;
      banner.active = false;
      this.setState({ banner });
      this.props.notify.warn(`Non è stato possibile aggiornare il banner.`);
      console.warn(error);
    }

    this.setState({ disabled: false });
  }

  render() {
    const { banner } = this.props;
    const { logo, name } = this.props.shop;

    const productItemHolder = {
      minHeight: `110px`,
      backgroundColor: `#f8f9fa`,
    }

    let showBanner = this.state.showBanner;

    const BannerHeader = () => {
      return (
        <div className="row mT-5">
          <div className="col-10 col-md-3 order-md-1">
            {banner.pending && banner.pending !== undefined ? (
              <span><i className="fas fa-eye-slash"></i>&nbsp;In attesa di approvazione</span>
            ) :(
              <span className="text-success"><i className="fas fa-eye"></i>&nbsp;Approvato</span>
            )}
          </div>

          <div className="col-2 col-md-1 order-md-4 text-right">
            <a href="/" className="text-success" onClick={this.handleEdit}>
              <i className={`fas fa-lg ${!this.state.edit ? 'fa-pen' : 'fa-times'}`}></i>
            </a>
          </div>

          <div className="col-12 col-md-3 order-md-2">
            <span className="switch switch-xs">
              <input type="checkbox" className="switch" id={`switch_${banner.id}`}
                name="active"
                disabled={ this.state.disabled || moment().isAfter(this.props.banner.to) }
                defaultChecked={ this.state.banner.active && moment().isBefore(this.props.banner.to) }
                onChange={() => this.handleActivation(banner)}
                />
              <label htmlFor={`switch_${banner.id}`}></label>
            </span>
            {this.state.banner.active && moment().isBefore(this.props.banner.to) ? (
              <span className="text-success small">
                <i className="fas fa-check"></i>&nbsp;
                Pubblicato
              </span>
            ) : (
              <span className="text-secondary small">
                <i className="fas fa-times"></i>&nbsp;
                Non pubblicato
              </span>
            )}
          </div>

          <div className="col-12 col-md-5 order-md-3 text-right">
            <i className="far fa-calendar-check"></i>&nbsp;
            { moment(banner.from).format('DD/MM/YYYY') }
            &nbsp; - &nbsp;
            <i className="far fa-calendar-times"></i>&nbsp;
            { moment(banner.to).format('DD/MM/YYYY') }
          </div>                                
        </div>
      );
    };

    const BannerCard = ({title, price}) => {
      return (
        <div className="banner-card h-100 p-0 pt-1 my-4 ml-2 rounded border bg-white shadow3">
          <div className="spnimgbox d-flex align-items-center justify-content-center">
            <img alt="" className="banner-image img-fluid" src="/static/images/black_product.png" />
          </div>
          <div className="product-text px-3 no-undescore">
            <p className="small sptcnt bnntxt align-items-left">{title}</p>
            <p className="price">{price}<span className="euro-symbol">€</span></p>
          </div>
          <div className="links pt-2">
            <button className="fake-btn btn btn-success btn-sm text-white" disabled href="#">Vedi offerta</button>
            <p className="link-p mt-2">Cerca Altri Prezzi</p>
          </div>
        </div>
      );
    };

    const SpnimgBox = () => {
      return (
        <div className="banner-card">
          <div className="spnimgbox d-flex align-items-center justify-content-center">
            {!!logo && !!logo.type && !!logo.data ? (
              <img alt=""
                className="banner-image img-fluid"
                src={`data:${logo.type};base64,${logo.data}`}
                width="45px"
              />
            ) : (
              <img alt="" className="banner-image img-fluid" src="https://via.placeholder.com/200x90" />
            )}
          </div>
          <div className="shop-text px-3">
            <p className="sptcnt bnntxt small"> 
              {this.state.banner.text
                ? this.state.banner.text
                : <span className="text-muted">Testo banner</span>
              }
            </p>
          </div>
          <div className="links pt-2">
            <button className="fake-btn btn btn-success btn-sm text-white" disabled href="#">Cerca Altri Prezzi</button>
          </div>
        </div>
      );
    };

    return (
      <div className={`dashbord-banner card ${this.state.edit ? 'border-success' : ''}`}>
        <div className="card-body">            
          {!this.props.add && <BannerHeader />}
          <div className="row align-items-center border m-5 mT-10" style={productItemHolder}>
            <div className="col-12 mT-10">
              <span className="small text-muted">Sponsorizzata da {name}</span>
            </div>
            <div className="banner-wrapper text-center mT-md-0">
              <div className="row d-flex flex-nowrap text-center mB-md-0">
                <BannerCard title="Prodotto correlato..." price='5,15' />
                <SpnimgBox />
                <BannerCard title="Prodotto simile..." price='33,90' />
                <BannerCard title="Prodotto correlato..." price='17,2' />
                <BannerCard title="Prodotto simile..." price='9,99' />
              </div>
            </div>
          </div>
          {this.state.edit && !!this.props.stats ? (
            <BannerElementEdit
              id={ this.props.banner.id }
              user={ this.props.user }
              shop={ this.props.shop }
              notify={ this.props.notify }
              userProducts={ this.props.userProducts }
              force={ this.props.force}
              activeBanners={this.props.activeBanners}
              stats={this.props.stats}
              create={ !!banner.id }
              banner={ this.state.banner }
              setBanner={ this.setBanner }
              updateBanner={this.updateBanner}
              closeBanner={ this.closeBanner }
              deleteBanner={ this.props.deleteBanner }
              getExpireDate = { this.props.getExpireDate }
            />
          ) : ( 
            <div className="mT-20">
              <hr />
              <a href="/" className="small" onClick={e => {
                e.preventDefault(); 
                this.setState({showBanner: !showBanner});
                state.showBanner = !showBanner;
              }}>
                {showBanner
                  ? <span><i className="fas fa-angle-down"/><i className="fas fa-chart-line"/>Nascondi statistiche</span>
                  : <span><i className="fas fa-angle-right"/><i className="fas fa-chart-line"/>Mostra statistiche</span>
                }
              </a>
              {showBanner && (
                <div className="mT-20">
                  <BannerChart 
                    id={ this.props.banner.id }
                    user={ this.props.user }
                    shop={ this.props.shop }
                    from={ this.props.from }
                    to={ this.props.to } 
                    banner={ this.state.banner }
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default BannerElement;