import React, { Component } from 'react';
import { Alert } from 'reactstrap';
import { Link } from 'react-router-dom';

import { FilePond, File, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';

import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import { shopService } from '../../_services/shop.service';
import ShopSocialUrls from './ShopSocialUrls';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginFileEncode, FilePondPluginFileValidateType);

class Shop extends Component {
  constructor(props) {
    super(props);

    this.socials = [
      'facebook',
      'amazon',
      'google_mybusiness',
      'trovaprezzi',
      'ekomi',
      'feedaty',
      'certire',
      'trustpilot',
      'trustedshop',
      'topnegozi',
    ];

    this.state = {
      name: this.props.shop.name,
      image: [],
      logo: this.props.shop.logo,
      base_url: this.props.shop.base_url,
      email_info: this.props.shop.email_info,
      email_notify: this.props.shop.email_notify,
      info: this.props.shop.info,
      gift: this.props.shop.gift,
      categories: this.props.shop.categories,
      payment_methods: this.props.shop.payment_methods,
      shipping_zones: this.props.shop.shipping_zones,
      shipping_methods: this.props.shop.shipping_methods,
      shipping_time: this.props.shop.shipping_time,
      shipping_cost: this.props.shop.shipping_cost,
      shipping_cost_value: this.props.shop.shipping_cost_value,
      shipping_cost_islands_value: this.props.shop.shipping_cost_islands_value,
      shipping_cost_free_value: this.props.shop.shipping_cost_free_value,
      socialUrls: this.props.shop.socialUrls ? this.props.shop.socialUrls : [],
      isShopSubmitDisabled: true,
      isSubmit: false,
      change: false,
      errors: false,
    };

    this.pond = {};

    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleErrorForm = this.handleErrorForm.bind(this);

    this.handleSubmitShopInfo = this.handleSubmitShopInfo.bind(this);

    this.submitIsDisabled = this.submitIsDisabled.bind(this);
    this.areEqual = this.areEqual.bind(this);
    // this.addSocialUrls = this.addSocialUrls.bind(this);
    this.removeSocialUrl = this.removeSocialUrl.bind(this);
    this.updateSocialUrl = this.updateSocialUrl.bind(this);
  }

  componentDidMount() {
    if (this.props.shop.logo) {
      const logo = this.props.shop.logo;
      this.pond.addFile(`data:${logo.type};base64,${logo.data}`);
    }

    window.ClassicEditor.create(document.querySelector('#gift'), {
      toolbar: ['bold', 'italic', 'underline', 'bulletedList', 'numberedList', '|', 'undo', 'redo'],
    })
      .then((editor) => {
        editor.model.document.on('change:data', (event) => {
          this.setState({ gift: editor.getData() });
        });
      })
      .catch((error) => {
        console.error(error);
      });

    window.removeLoader();
  }

  submitIsDisabled(form, force = false) {
    // for (let button of form.querySelectorAll('button[type=submit]')) {
    //   // button.disabled = !form.checkValidity() || force;
    // }
  }

  areEqual(element, checkName) {
    const form = element.form;
    const checkElement = form.querySelector(checkName);
    element.setCustomValidity('');

    if (element.value.localeCompare(checkElement.value) !== 0) {
      let message = 'I valori non coincidono.';
      let customErrorMessage = element.getAttribute('equalerror');
      element.setCustomValidity(customErrorMessage ? customErrorMessage : message);
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
      change: true,
    });

    this.handleErrorForm(event.target, event.target.parentElement, event.target.form);
  }

  handleFileChange(fileItems) {
    const dataLogo = document.querySelectorAll('div#shopLogo input[name="logo"]')[0];
    const logoValue = !!dataLogo.value ? JSON.parse(dataLogo.value) : undefined;

    this.setState({
      image: fileItems.map((fileItem) => fileItem.file),
      logo: logoValue,
      change: !!!document.getElementById('loader'),
    });

    const element = document.getElementById('shopLogo');
    const form = document.getElementById('shopForm');
    this.handleErrorForm(element.firstChild, element.parentElement, form);
    this.submitIsDisabled(form);
  }

  handleErrorForm(element, parent, form) {
    this.setState({ errors: false });

    if (form) {
      for (let el of form.querySelectorAll('[equal]')) {
        this.areEqual(el, el.getAttribute('equal'));
      }
    }

    for (let message of parent.querySelectorAll('.validation-message')) {
      parent.removeChild(message);
    }

    if (element.validationMessage) {
      const message = document.createElement('small');
      message.setAttribute('class', 'validation-message text-danger');
      message.innerHTML = element.validationMessage;
      parent.appendChild(message);
    }

    for (let el of form) {
      if (!!el.validationMessage) {
        this.setState({ errors: true });
        break;
      }
    }
  }

  handleSubmitShopInfo(e) {
    const { shop, user, notify, setShop } = this.props;
    const {
      base_url,
      name,
      email_info,
      email_notify,
      info,
      gift,
      categories,
      payment_methods,
      shipping_zones,
      shipping_methods,
      shipping_time,
      shipping_cost,
      shipping_cost_value,
      shipping_cost_islands_value,
      shipping_cost_free_value,
      socialUrls,
    } = this.state;

    e.preventDefault();
    this.setState({ isSubmit: true });

    const logoValue = !!e.target.logo ? JSON.parse(e.target.logo.value) : null;

    let url = new URL(base_url);

    const shopInfo = {
      name: name,
      logo: logoValue ? logoValue : '',
      domain: url.hostname,
      base_url,
      email_info,
      email_notify,
      info,
      gift,
      categories,
      payment_methods,
      shipping_zones,
      shipping_methods,
      shipping_time,
      shipping_cost,
      shipping_cost_value,
      shipping_cost_islands_value,
      shipping_cost_free_value,
      socialUrls,
    };

    let resource = () => shopService.create(user.id, shopInfo).then((shop) => shop);

    if (shop.id) {
      resource = () => shopService.update(user.id, shop.id, shopInfo);
    }

    resource()
      .then((shop) => {
        setShop(shop);
        notify.success(`Le informazioni sono state aggiornate e saranno pubblicate dopo l'approvazione dello staff.`);
      })
      .catch((error) => {
        console.error(error);
        notify.error(error.message);
      });

    this.submitIsDisabled(e.target, true);
    this.setState({ change: false });
  }

  // addSocialUrls(event) {
  //   this.state.socialUrls.push({
  //     social: undefined,
  //     socialUrl: undefined,
  //   });

  //   this.setState({ socialUrls: this.state.socialUrls });
  //   this.handleErrorForm(event.target, event.target.parentElement, event.target.form);
  //   this.submitIsDisabled(event.target.form, true);
  // }

  removeSocialUrl(event, index) {
    this.setState({ socialUrls: this.state.socialUrls.filter((social, key) => key !== index) });
    this.handleErrorForm(event.target, event.target.parentElement, event.target.form);
    this.submitIsDisabled(event.target.form);
  }

  updateSocialUrl(type, value) {
    const socialUrls = this.state.socialUrls;

    let key = window._.findKey(this.state.socialUrls, (social) => {
      if (
        social.social.toLowerCase() === type ||
        (type === 'google_mybusiness' && social.social.toLowerCase().trim() === 'google')
      ) {
        return true;
      }

      return false;
    });

    let social = {};

    if (type !== 'google_mybusiness') {
      social = {
        social: type,
        socialUrl: value.trim(),
      };
    } else {
      social = {
        social: type,
        socialUrl: `http://google_place_id/${value.place_id}`,
        name: value.name,
        formatted_address: value.formatted_address,
        place_id: value.place_id,
      };
    }

    if (!!key) {
      socialUrls[key] = social;
    } else {
      socialUrls.push(social);
    }

    this.setState({
      socialUrls: socialUrls,
      change: true,
    });

    // this.handleErrorForm(event.target, event.target.parentElement, event.target.form);
    // this.submitIsDisabled(event.target.form);
  }

  render() {
    const { shop, user } = this.props;
    const { errors, change, socialUrls } = this.state;

    const ButtonForTaxDetail = () => {
      return (
        <div className="mt-3 mx-auto mb-5" style={{ width: '323px' }}>
          <Link to="/shop/tax-detail">
            <button className="btn btn-success p-15" type="button">
              Gestisci qui i dati fiscali e di fatturazione
            </button>
          </Link>
        </div>
      );
    };

    const Allert = () => {
      return (
        <div className="sticky-top">
          {!!errors && (
            <Alert color="warning">Ci sono errori nel form. Corregili per poter salvare le informazioni.</Alert>
          )}
          {!!change && !!!errors && (
            <Alert color="info">
              Le informazioni del tuo negozio sono state modificate.&nbsp;
              <a href="/" onClick={this.handleSubmitShopInfo}>
                Salva
              </a>{' '}
              per rendere definitive.
            </Alert>
          )}
        </div>
      );
    };

    const RenderFormGroup = (
      label,
      name,
      tag,
      type,
      classes,
      placeholder,
      pattern,
      required,
      value,
      tip,
      min,
      step
    ) => {
      return (
        <div className="form-group row">
          <label htmlFor={name} className="col col-form-label">
            {label}
          </label>

          <div className="col-10">
            {tag === 'input' && (type === 'text' || type === 'email') ? (
              <input
                id={name}
                className={`form-control ${classes}`}
                type={type}
                name={name}
                placeholder={placeholder}
                pattern={pattern}
                required={required}
                defaultValue={value}
                maxLength="255"
                onChange={(e) => this.handleChange(e)}
              />
            ) : null}

            {tag === 'input' && type === 'number' ? (
              <input
                id={name}
                className={`form-control ${classes}`}
                type={type}
                name={name}
                pattern={pattern}
                required={required}
                defaultValue={value}
                min={min}
                step={step}
                onChange={(e) => this.handleChange(e)}
              />
            ) : null}

            {tag === 'textarea' && type === 'text' ? (
              <textarea
                id={name}
                className={`form-control ${classes}`}
                type={type}
                name={name}
                pattern={pattern}
                required={required}
                defaultValue={value}
                maxLength="350"
                rows="3"
                onChange={(e) => this.handleChange(e)}
              />
            ) : null}

            <small className="form-text text-muted">
              {tip}
              {required ? <strong> Campo obbligatorio</strong> : null}
            </small>
          </div>
        </div>
      );
    };

    return (
      <div>
        <div className="row">
          <div className="col-12 mT-10">
            <div className="bgc-white bd bdrs-3 p-20 mB-20">
              <ButtonForTaxDetail />
              <h6 className="c-grey-900">Informazioni sul tuo negozio</h6>
              <div className="">
                <form
                  id="shopForm"
                  onSubmit={this.handleSubmitShopInfo}
                  onChange={(e) => this.submitIsDisabled(e.target.form)}
                  ref={(el) => (this.form = el)}
                >
                  {this.state.isSubmit && <Allert />}

                  {RenderFormGroup(
                    `Nome negozio`,
                    'name',
                    'input',
                    'text',
                    'b-5',
                    'Nome negozio',
                    '.{3,}',
                    true,
                    shop.name === 'Non presente.' ? null : shop.name,
                    `Il nome del tuo negozio.`
                  )}

                  <div className="form-group row">
                    <label htmlFor="name" className="col col-form-label">
                      Immagine negozio
                    </label>
                    <div className="col-10">
                      <div className="row">
                        {this.state.logo ? (
                          <div className="col" style={{ msFlex: '0 0 256px', flex: '0 0 256px' }}>
                            <img
                              alt=""
                              style={{ width: '256px' }}
                              src={`data:image/${this.state.logo.type};base64,${this.state.logo.data}`}
                            />
                          </div>
                        ) : (
                          ''
                        )}
                        <div className="col text-center">
                          <FilePond
                            ref={(ref) => (this.pond = ref)}
                            id="shopLogo"
                            name="logo"
                            // required
                            labelIdle='Trascina il tuo file qui o <span className="filepond--label-action"> Cerca </span>'
                            accept-file-types="image/jpg, image/png"
                            allowFileEncode={true}
                            allowImageExifOrientation={true}
                            imagePreviewHeight={150}
                            acceptedFileTypes={['image/*']}
                            onupdatefiles={this.handleFileChange}
                          >
                            {this.state.image.map((file) => (
                              <File key={file} src={file} origin="local" />
                            ))}
                          </FilePond>
                        </div>
                      </div>
                      <small className="form-text text-muted">Il logo del tuo negozio.</small>
                    </div>
                  </div>

                  {RenderFormGroup(
                    `Sito web`,
                    'base_url',
                    'input',
                    'text',
                    '',
                    'Sito web',
                    '^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$',
                    true,
                    shop.base_url === 'Non presente.' ? null : shop.base_url,
                    `L'indirizzo principle del tuo sito internet (EX: https://www.farmacia.it).`
                  )}

                  {RenderFormGroup(
                    `Indirizzo email`,
                    'email_info',
                    'input',
                    'email',
                    '',
                    'Indirizzo email',
                    undefined,
                    true,
                    shop.email_info === 'Non presente.' ? null : shop.email_info,
                    `Indirizzo email che i clienti usano per contattarti.`
                  )}

                  {RenderFormGroup(
                    `Indirizzo email per le notifiche`,
                    'email_notify',
                    'input',
                    'email',
                    '',
                    'Indirizzo email notifiche',
                    undefined,
                    false,
                    shop.email_notify === 'Non presente.' ? null : shop.email_notify,
                    `Indirizzo email nel quale vuoi ricevere le notifiche.`
                  )}

                  {RenderFormGroup(
                    `Categorie Prodotti`,
                    'categories',
                    'input',
                    'text',
                    '',
                    'Solari, Prima Infanzia, Cosmesi, Integratori , Prodotti Di Erboristeria E Sanitari',
                    undefined,
                    true,
                    shop.categories === 'Non presente.' ? null : shop.categories,
                    `Elenco di principali categorie di prodotti venduti.`
                  )}

                  {RenderFormGroup(
                    `Metodo di pagamento`,
                    'payment_methods',
                    'input',
                    'text',
                    '',
                    'Carte Di Credito Mastercard - Visa - Bonifico Bancario - Contrassegno - In Sede - Paypal Bonifico Bancario',
                    undefined,
                    true,
                    shop.payment_methods === 'Non presente.' ? null : shop.payment_methods,
                    `Elenco di modalità di pagamento accettate.`
                  )}

                  {RenderFormGroup(
                    `Zone Di Spedizione Materiale`,
                    'shipping_zones',
                    'input',
                    'text',
                    '',
                    'Italia, Europa',
                    undefined,
                    true,
                    shop.shipping_zones === 'Non presente.' ? null : shop.shipping_zones,
                    `Elenco di paesi dove viene eseguita la spedizione.`
                  )}

                  {RenderFormGroup(
                    `Modalità Di Consegna`,
                    'shipping_methods',
                    'input',
                    'text',
                    '',
                    'Corriere, Corriere Assicurato, Ritiro in loco',
                    undefined,
                    true,
                    shop.shipping_methods === 'Non presente.' ? null : shop.shipping_methods,
                    `Elenco di metodi di consegna utilizzati.`
                  )}

                  {RenderFormGroup(
                    `Tempi Di Consegna`,
                    'shipping_time',
                    'input',
                    'text',
                    '',
                    '24/48 Ore',
                    undefined,
                    true,
                    shop.shipping_time === 'Non presente.' ? null : shop.shipping_time,
                    `Indicazione dei tempi di consegna medi.`
                  )}

                  {RenderFormGroup(
                    `Descrizione spese di spedizione`,
                    'shipping_cost',
                    'input',
                    'text',
                    '',
                    'Gratis Per Ordini Superiori Ai 59 Euro Altrimenti 9 Euro',
                    undefined,
                    true,
                    shop.shipping_cost === 'Non presente.' ? null : shop.shipping_cost,
                    `Descrizione costo di spedizione.`
                  )}

                  {RenderFormGroup(
                    `Spese di spedizione`,
                    'shipping_cost_value',
                    'input',
                    'number',
                    '',
                    '',
                    undefined,
                    true,
                    shop.shipping_cost_value,
                    `Indicazioni sul costo della spedizione.`,
                    0,
                    0.01
                  )}

                  {RenderFormGroup(
                    `Spese di spedizione per le isole`,
                    'shipping_cost_islands_value',
                    'input',
                    'number',
                    '',
                    '',
                    undefined,
                    true,
                    shop.shipping_cost_islands_value,
                    `Indicazioni sul costo della spedizione verso le isole. Impostare a 0 se gratis.`,
                    0,
                    0.01
                  )}

                  {RenderFormGroup(
                    `Spese di spedizione gratuita`,
                    'shipping_cost_free_value',
                    'input',
                    'number',
                    '',
                    '',
                    undefined,
                    true,
                    shop.shipping_cost_free_value === 'Non presente.' ? null : shop.shipping_cost_free_value,
                    `Oltre quale cifra le spese di spedizioni sono gratuite. Impostare a 0 se l'opzione non è abilitata.`,
                    0,
                    0.01
                  )}

                  {RenderFormGroup(
                    `Informazioni`,
                    'info',
                    'textarea',
                    'text',
                    '',
                    'Informazioni negozio',
                    undefined,
                    false,
                    shop.info === 'Non presente.' ? null : shop.info,
                    `Informazioni generali.`
                  )}

                  {RenderFormGroup(
                    `Altri omaggi`,
                    'gift',
                    'textarea',
                    'text',
                    '',
                    'Informazioni omaggi',
                    undefined,
                    false,
                    shop.gift === 'Non presente.' ? null : shop.gift,
                    `Informazioni su altri omaggi.`
                  )}

                  <div className="form-group row mT-30">
                    {/* <label htmlFor="info" className="col col-form-label">Social url</label> */}
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12">
                          {this.socials.map((type, key) => (
                            <div key={key}>
                              <ShopSocialUrls
                                type={type}
                                updateSocialUrl={this.updateSocialUrl}
                                user={user}
                                shop={shop}
                                social={window._.find(socialUrls, (social) => {
                                  if (
                                    social.social.toLowerCase() === type ||
                                    (type === 'google_mybusiness' && social.social.toLowerCase() === 'google')
                                  ) {
                                    return true;
                                  }

                                  return false;
                                })}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-sm-12 text-right">
                      <a
                        className="btn btn-info"
                        target="_blank"
                        href={`https://www.prezzifarmaco.it/scheda-negozio/${shop.shop_id}`}
                        rel="noopener noreferrer"
                      >
                        Vedi la tua scheda negozio
                      </a>
                      &nbsp;
                      <button type="submit" className="btn btn-primary" disabled={false}>
                        Aggiorna
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Shop;
