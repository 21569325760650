import { authHeader } from '../_helpers/auth-header';
import Resource from '../_helpers/resource';

const resource = new Resource(process.env.REACT_APP_API_URL);

export const shopTaxDetailService = {
  get: (userId, id, cache = true) => {
    const url = id ? `user/${userId}/shop/tax-detail/${id}` : `user/${userId}/shop/tax-detail`;

    return resource.get(
      url,
      authHeader(),
      cache
    ).then(shopTaxDetail => {
      if (shopTaxDetail.data) {
        return shopTaxDetail.data;
      }
      
      return shopTaxDetail;
    });
  },

  create: (userId, request) => {
    return resource.post(
      `user/${userId}/shop/tax-detail`,
      request,
      authHeader()
    ).then(shopTaxDetail => shopTaxDetail);
  },

  update: (userId, id, request) => {
    return resource.put(
      `user/${userId}/shop/tax-detail/${id}`,
      request,
      authHeader()
    ).then(shopTaxDetail => shopTaxDetail);
  },
};
