import React, { Component } from 'react';
import {Line} from 'react-chartjs-2';
import Color  from 'color';

import moment from 'moment';

class ClickChart extends Component {
  
  constructor(props) {
    super(props);

    this.chartColors = {
      red: 'rgb(255, 99, 132)',
      orange: 'rgb(255, 159, 64)',
      yellow: 'rgb(255, 205, 86)',
      green: 'rgb(75, 192, 192)',
      blue: 'rgb(54, 162, 235)',
      purple: 'rgb(153, 102, 255)',
      grey: 'rgb(201, 203, 207)'
    };
    
    this.state = {

      rendered: false,

      chartReference: undefined,
      
      chart: {
        labels: [],
        datasets: [
          {
            label: 'I click del tuo negozio',
            fill: false,
            borderColor: this.chartColors.blue,
            backgroundColor: Color(this.chartColors.blue).lighten(0.2).rgb(),            
          },
          {
            label: 'Media dei click',
            fill: false,
            hidden: true,
            borderColor: this.chartColors.red,
            backgroundColor: Color(this.chartColors.red).lighten(0.2).rgb(),
          },
          {
            label: 'Media dei negozi simili al tuo',
            fill: false,
            hidden: true,
            borderColor: this.chartColors.green,
            backgroundColor: Color(this.chartColors.green).lighten(0.2).rgb(),
          },
          {
            label: 'Media negozi premium',
            fill: false,
            hidden: true,
            borderColor: this.chartColors.yellow,
            backgroundColor: Color(this.chartColors.yellow).lighten(0.2).rgb(),
          }
        ],
      },

      options: {
        responsive: true,
        title: {
          display: true,
          text: 'Click giornalieri'
        },
        legend: {
          position: 'bottom',
          // onClick: this.chartLabelAction,
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        hover: {
          mode: 'nearest',
          intersect: true
        },
        scales: {
          xAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Data'
            }
          }],
          yAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Click'
            }
          }]
        }
      },

    };
    
    this.chartLabelAction = this.chartLabelAction.bind(this);
  }

  componentDidUpdate() {

    if (!!!this.props.clicks.length || this.state.rendered) {
      return;
    }

    this.setState({rendered: true});

    let chart = this.state.chart;
    let clicks = this.props.clicks
    .sort((a, b) => moment.utc(a.date).diff(moment.utc(b.date)) );

    chart.labels = clicks.map(x => moment.utc(x.date).local().format('DD/MM/YYYY'));

    chart.datasets[0].data = clicks.map(x => x.click);
    chart.datasets[1].data = clicks.map(x => Math.floor(x.avgClick));
    chart.datasets[2].data = clicks.map(x => Math.floor(x.similarShopClick));
    chart.datasets[3].data = clicks.map(x => Math.floor(x.premiumShopClick));

    this.setState({chart});
  }

  chartLabelAction(e, legendItem) {
  }

  render() {
    return (
      <div className="bgc-white bd bdrs-3 p-20 mB-20 ">
      <h6 className="c-grey-900">Click giornalieri</h6>
      <div className="">
        <Line 
          ref={(reference) => this.chartReference = reference }
          data={this.state.chart}
          options={this.state.options}
          height={100}
          />
      </div>
      </div>
    )
  }
  
}

export default ClickChart;