import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import moment from 'moment-timezone';

import { bannerService } from '../../_services/banner.service';

class BannerElementEdit extends Component {

  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      title: this.props.banner.title ? this.props.banner.title : undefined,
      text: this.props.banner.text ? this.props.banner.text : undefined,
      active: this.props.banner.active ? this.props.banner.active : undefined,
      url: this.props.banner.url ? this.props.banner.url : this.props.shop.base_url,
      from: this.props.banner.from ? moment(this.props.banner.from).toDate() : undefined,
      to: this.props.banner.to ? moment(this.props.banner.to).toDate() : undefined,
      locale: 'it',
      modal: false
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleErrorForm = this.handleErrorForm.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    // this.handleSwitch = this.handleSwitch.bind(this);

    this.submitIsDisabled = this.submitIsDisabled.bind(this);

    this.deleteBanner = this.deleteBanner.bind(this);

    this.changeDates = this.changeDates.bind(this);

    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.inizializeDataRangePicker(); 
  }

  inizializeDataRangePicker() {
    window.moment.locale('it');
    window.$('#reportrange').daterangepicker({
      // minDate: moment(),
      startDate: this.state.from,
      endDate: this.state.to,
      locale: {
        format: 'DD/MM/YYYY',
        separator: ' - ',
        applyLabel: 'Applica',
        cancelLabel: 'Cancella',
        fromLabel: 'Da',
        toLabel: 'A',
        customRangeLabel: 'Personalizzato',
        firstDay: 1
      }
    }, this.changeDates);
  }

  changeDates(start, end) {
    this.setState({ from: moment(start).startOf('day').valueOf() });
    this.setState({ to: moment(end).endOf('day').valueOf() });
  }

  submitIsDisabled(form, force = false) {
    for (let button of form.querySelectorAll('button[type=submit]')) {
      button.disabled = !(form.checkValidity() && !!this.state.from && !!this.state.to) || force;
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
    this.handleErrorForm(event.target, event.target.parentElement);
    this.props.setBanner(event.target.name, event.target.value);
  }

  handleErrorForm(element, parent) {
    for (let message of parent.querySelectorAll('.validation-message')) {
      parent.removeChild(message);      
    }

    if (element.validationMessage) {
      const message = document.createElement('small');
      message.setAttribute('class', 'validation-message text-danger');
      message.innerHTML = element.validationMessage;
      parent.appendChild(message);
    }
  }

  async handleSubmit(e) {
    e.preventDefault();

    if (!!!this.state.from || !!!this.state.to) {
      this.props.notify.error('Inserire una data valida.');
      return;
    }
    
    const banner = {
      title: this.state.title,
      text: this.state.text,
      url: this.state.url,
      active: this.state.active,
      from: moment(this.state.from).valueOf(),
      to: moment(this.state.to).valueOf(),
    };

    let resource = () => bannerService.create(this.props.user.id, this.props.shop.id, banner);

    if (this.props.id) {
      resource = () => bannerService.update(this.props.user.id, this.props.shop.id, this.props.id, banner);
    }

    try {
      let banner = await resource();
      this.props.updateBanner(banner);        
      this.props.notify.success(`Banner '${banner.title}' aggiornato.`);
      if (banner.pending) {
        this.props.notify.warn(`Il banner '${banner.title}' è in attesa di approvazione.`); 
      }
    } catch (error) {
      console.error(error);
      this.props.notify.error(`Non è stato possibile aggiornare il banner '${banner.title}'.`);
    }
      
  }

  deleteBanner() {
    bannerService.delete(this.props.user.id, this.props.shop.id, this.props.id)
      .then(() => {
        this.props.deleteBanner(this.props.id);
      });
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  render() {

    return (
      <form onSubmit={ this.handleSubmit } id="bannerFrom">
        <div className="row mT-20">      
            <div className="col-12">
              {
                this.props.id
                  ? <h3 className="text-success">Aggiorna il tuo banner</h3>
                  : <h3 className="text-success">Crea il tuo banner</h3>
              }
              
            </div>

            <div className="col-12 mT-10">
              <div className="form-group row">
                <label htmlFor="title" className="col col-form-label">Nome banner</label>
                <div className="col-10">
                  <input name="title" type="text" className="form-control" id="title" 
                    required
                    placeholder="Titolo banner"
                    maxLength="25"
                    size="30"
                    defaultValue={ this.state.title }
                    onChange={e => this.handleChange(e)}
                    />
                  <small className="form-text text-muted">Indica il nome del Banner, questo testo non sarà visibile ai navigatori.</small>
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="text" className="col col-form-label">Testo banner</label>
                <div className="col-10">
                  <input name="text" type="text" className="form-control" id="text" 
                    required
                    placeholder="Testo banner"
                    size="30"
                    maxLength="73"
                    defaultValue={ this.state.text }
                    onChange={e => {this.handleChange(e)}}
                    />
                  <small className="form-text text-muted">Il testo del banner.</small>
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="from" className="col col-form-label">Periodo attività</label>                
                <div className="col-10">

                  <div id="reportrange" style={{background: '#fff', cursor: 'pointer', padding: '5px 10px', border: '1px solid #ccc', width: '100%'}}>
                    
                    <i className="fa fa-calendar"></i>&nbsp;
                    <span>
                      { this.state.from ? moment(this.state.from).format('DD/MM/YYYY') : ''}
                      &nbsp;
                      { this.state.to ? moment(this.state.to).format('DD/MM/YYYY') : ''}
                    </span> <i className="fa fa-caret-down"></i>

                  </div>
                  <small className="form-text text-muted">
                    Indica il periodo in cui la campagna è attiva. &nbsp;
                    {
                      !!this.props.getExpireDate()
                        ? `La tua sottoscrizione scade il ${this.props.getExpireDate()}.`
                        : <strong>Attualmente non hai nessun piano attivo.</strong>
                    }
                  </small>
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="base_url" className="col col-form-label">Link banner</label>
                <div className="col-10">
                  <input name="url" type="url" className="form-control" id="url" 
                    required
                    placeholder="Indirizzo banner"
                    pattern="^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$"
                    size="30"
                    defaultValue={ this.state.url }
                    onChange={e => {this.handleChange(e)}}
                    />
                  <small className="form-text text-muted">L'indirizzo a cui punta il banner.</small>
                </div>
              </div>

              <div className="row mT-20">
                {
                  this.props.id
                    ? <div className="col text-left">
                        <Button outline color="danger" onClick={ this.toggle }>
                          Rimuovi
                        </Button>
                        <Modal isOpen={this.state.modal} toggle={this.toggle}>
                          <ModalHeader toggle={this.toggle}>Conferma rimozione banner</ModalHeader>
                          <ModalBody>
                            Confermi di voler rimuovere il banner "{ this.state.title }"?
                          </ModalBody>
                          <ModalFooter>
                            <Button outline color="secondary" onClick={this.toggle}>Annulla</Button>
                            <Button color="danger" onClick={this.deleteBanner}>Conferma</Button>{' '}                            
                          </ModalFooter>
                        </Modal>
                      </div>
                    : ''
                }
                <div className="col text-right">
                  {
                    !this.props.force
                      ? <Button outline color="secondary" onClick={ this.props.closeBanner }>Annulla</Button>
                      : ''
                  }                  
                  &nbsp;
                  <button type="submit" className="btn btn-primary" >
                    {this.props.id ? 'Aggiorna' : 'Crea' }
                  </button>
                </div>
              </div>
            </div>  

        </div>
      </form>
    )
  }
}

export default BannerElementEdit;