import { authHeader } from '../_helpers/auth-header';
import Resource from '../_helpers/resource';
const resource = new Resource(process.env.REACT_APP_API_URL);

export const integrationService = {

  backlink: (userId, shopId) => {
    return resource.get(
      `user/${userId}/shop/${shopId}/integration/backlink`,
      authHeader()
    );
  },

  analytics: (userId, shopId) => {
    return resource.get(
      `user/${userId}/shop/${shopId}/integration/analytics`,
      authHeader()
    );
  },

  productsTrack: (userId, shopId) => {
    return resource.get(
      `user/${userId}/shop/${shopId}/integration/productsTrack`,
      authHeader()
    );
  },

};

