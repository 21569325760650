import { authHeader } from '../_helpers/auth-header';
import Resource from '../_helpers/resource';
const resource = new Resource(process.env.REACT_APP_API_URL);

export const shopDetailService = {

  get: (userId, id, type) => {
    return resource.get(
      `user/${userId}/shop/${id}/shopdetail/${type}`,
      authHeader(),
      false
    ).then(shopdetail => {
      return shopdetail;
    });
  },
  create: (userId, id, type, request) => {
    return resource.post(
      `user/${userId}/shop/${id}/shopdetail/${type}`,
      request,
      authHeader()
    ).then(shopdetail => {
      return shopdetail;
    });
  },
  update: (userId, id, type, request) => {
    return resource.put(
      `user/${userId}/shop/${id}/shopdetail/${type}`,
      request,
      authHeader()
    ).then(shopdetail => {
      return shopdetail;
    });
  },

}
