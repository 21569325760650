import React, { Component } from 'react';
import moment from 'moment';

import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';

import { analyticsService } from '../../../_services/analytics.service';
import CountProdChart from './Charts/CountProdChart';
import AvgPriceChart from './Charts/AvgPriceChart';
// import ShipPriceChart from './Charts/ShipPriceChart';
// import ShipFreeOverChart from './Charts/ShipFreeOverChart';

export default class ECommerceProductsHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statsHistory: undefined,
    };
  }

  async componentDidMount() {
    const { user, shop, from, to } = this.props;

    if (!!user.id && !!shop.id) {
      try {
        const statsHistory = await analyticsService.statsHistory(
          user.id,
          shop.id,
          moment(from).toISOString(),
          moment(to).add(2, 'days').toISOString()
        );

        this.setState({ statsHistory });
      } catch (error) {
        console.error(error);
      }
    }

    window.removeLoader();
  }

  render() {
    const { user, shop } = this.props;
    const { statsHistory } = this.state;

    return !user.id || !shop.id ? (
      <Alert color="warning">
        Non hai ancora un negozio associato. <Link to="/shop">Vai al form di creazione.</Link>
      </Alert>
    ) : (
      <div>
        <div className="row">
          <div className="col-12 mT-20">{<CountProdChart statsHistory={statsHistory} />}</div>
        </div>
        <div className="row">
          <div className="col-12 mT-20">{<AvgPriceChart statsHistory={statsHistory} />}</div>
        </div>
        {/*
          <div className="row">
            <div className="col-12 mT-20">
              {<ShipPriceChart statsHistory={statsHistory} />}
            </div>
          </div>
          <div className="row">
            <div className="col-12 mT-20">
              {<ShipFreeOverChart statsHistory={statsHistory} />}
            </div>
          </div>
         */}
      </div>
    );
  }
}
