import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

import moment from 'moment';

import { authJWTVerify } from '../../_helpers/auth-jwt';
import { userService } from '../../_services/user.service';
import { shopService } from '../../_services/shop.service';

import Payment from '../Payment/Payment';
import Subscription from '../Payment/Subscription';
import Structure from './Structure';
import Dashboard from '../Dashboard/';
import Account from '../Account/';
import Shop from '../Shop/';
import ShopTaxDetail from '../ShopTaxDetail';
import ShopBlocked from '../ShopBlocked';
import Feed from '../Feed/';

import Market from '../Analytics/ECommerce/Market';
import ECommerceProducts from '../Analytics/ECommerce/ECommerceProducts';
import ECommerceIncome from '../Analytics/ECommerce/ECommerceIncome';
import ECommerceProductsHistory from '../Analytics/ECommerce/ECommerceProductsHistory';
import Competitors from '../Analytics/ECommerce/Competitors';
import CompetitorsCustom from '../Analytics/ECommerce/CompetitorsCustom';
import Products from '../Analytics/Products';
import Product from '../Analytics/Product';
import Transactions from '../Analytics/Transactions';
import Banner from '../Banner/';
import Coupon from '../Coupon/';

import PayPerClick from '../PayPerClick/PayPerClick';
import GShopping from '../PayPerClick/GShopping';

import Feedaty from '../Partners/Feedaty';
import Qapla from '../Partners/Qapla';

import AnalyticsIntegration from '../Integrations/Analytics';
import ProductsTrack from '../Integrations/ProductsTrack';
import PrestashopIntegration from '../Integrations/PrestashopIntegration';
import TagManager from '../Integrations/Tagmanager';
import BackLinkImage from '../Integrations/BackLinkImage';

class Container extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      from: moment().subtract(1, 'day').subtract(29, 'days').startOf('day'),
      to: moment().subtract(1, 'day').endOf('days'),
    };

    try {
      let date = localStorage.getItem('datapicker');
      if (!!date) {
        date = JSON.parse(date);
        this.state.from = moment(date.from);
        this.state.to = moment(date.to);
      }
    } catch (error) {
      console.error(error);
    }

    this.changeDates = this.changeDates.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    if (!Object.keys(this.props.user).length) {
      userService
        .me()
        .then((user) => {
          this.props.setUser(user);
          return shopService.get(user.id);
        })
        .then((shop) => {
          if (Array.isArray(shop)) {
            shop = shop.pop();
            if (shop) {
              this.props.setShop(shop);
            }
          }
        });
    }

    try {
      authJWTVerify();
    } catch (error) {
      console.error(error);
    }
  }

  componentDidUpdate() {
    try {
      authJWTVerify();
    } catch (error) {
      console.error(error);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeDates(from, to) {
    if ((!!!this.state.from && !!!this.state.to) || !from.isSame(this.state.from) || !to.isSame(this.state.to)) {
      this.setState({
        from,
        to,
      });
    }
  }

  render() {
    const { user } = this.props;

    return (
      <Router>
        <Switch>
          <Route
            path={`/dashboard`}
            component={() => (
              <Structure
                component={Dashboard}
                changeDates={this.changeDates}
                from={this.state.from}
                to={this.state.to}
                {...this.props}
              />
            )}
          />

          <Route
            path={`/account`}
            component={() => (
              <Structure
                component={Account}
                changeDates={this.changeDates}
                from={this.state.from}
                to={this.state.to}
                {...this.props}
              />
            )}
          />

          <Route
            exact
            path={`/shop`}
            component={() => (
              <Structure
                component={Shop}
                changeDates={this.changeDates}
                from={this.state.from}
                to={this.state.to}
                {...this.props}
              />
            )}
          />

          <Route
            exact
            path={`/shop/tax-detail`}
            component={() => (
              <Structure
                component={ShopTaxDetail}
                changeDates={this.changeDates}
                from={this.state.from}
                to={this.state.to}
                {...this.props}
              />
            )}
          />

          <Route
            path={`/banner`}
            component={() => (
              <Structure
                component={Banner}
                changeDates={this.changeDates}
                from={this.state.from}
                to={this.state.to}
                {...this.props}
              />
            )}
          />

          <Route
            path={`/coupon`}
            component={() => (
              <Structure
                component={Coupon}
                changeDates={this.changeDates}
                from={this.state.from}
                to={this.state.to}
                {...this.props}
              />
            )}
          />

          <Route
            path={`/feed`}
            component={() => (
              <Structure
                component={Feed}
                changeDates={this.changeDates}
                from={this.state.from}
                to={this.state.to}
                {...this.props}
              />
            )}
          />

          <Route
            path={`/analytics/products`}
            component={() => (
              <Structure
                component={user.blocked ? ShopBlocked : Products}
                changeDates={this.changeDates}
                from={this.state.from}
                to={this.state.to}
                {...this.props}
              />
            )}
          />

          <Route
            path={`/analytics/ecommerce/market`}
            component={(props) => (
              <Structure
                component={user.blocked ? ShopBlocked : Market}
                changeDates={this.changeDates}
                from={this.state.from}
                to={this.state.to}
                {...window._.merge(this.props, props)}
              />
            )}
          />

          <Route
            path={`/analytics/ecommerce/products`}
            component={(props) => (
              <Structure
                component={user.blocked ? ShopBlocked : ECommerceProducts}
                changeDates={this.changeDates}
                from={this.state.from}
                to={this.state.to}
                {...window._.merge(this.props, props)}
              />
            )}
          />

          <Route
            path={`/analytics/ecommerce/incomes`}
            component={(props) => (
              <Structure
                component={user.blocked ? ShopBlocked : ECommerceIncome}
                changeDates={this.changeDates}
                from={this.state.from}
                to={this.state.to}
                {...window._.merge(this.props, props)}
              />
            )}
          />

          <Route
            path={`/analytics/ecommerce/stats-history`}
            component={(props) => (
              <Structure
                component={user.blocked ? ShopBlocked : ECommerceProductsHistory}
                changeDates={this.changeDates}
                from={this.state.from}
                to={this.state.to}
                {...window._.merge(this.props, props)}
              />
            )}
          />

          <Route
            path={`/analytics/ecommerce/competitors`}
            component={(props) => (
              <Structure
                component={user.blocked ? ShopBlocked : Competitors}
                changeDates={this.changeDates}
                from={this.state.from}
                to={this.state.to}
                {...window._.merge(this.props, props)}
              />
            )}
          />

          <Route
            path={`/analytics/ecommerce/Competitors-custom`}
            component={(props) => (
              <Structure
                component={user.blocked ? ShopBlocked : CompetitorsCustom}
                changeDates={this.changeDates}
                from={this.state.from}
                to={this.state.to}
                {...window._.merge(this.props, props)}
              />
            )}
          />

          <Route
            exact
            path={`/analytics/product/:ean`}
            component={(props) => (
              <Structure
                component={user.blocked ? ShopBlocked : Product}
                changeDates={this.changeDates}
                from={this.state.from}
                to={this.state.to}
                {...window._.merge(this.props, props)}
              />
            )}
          />

          <Route
            path={`/analytics/transactions`}
            component={() => (
              <Structure
                component={user.blocked ? ShopBlocked : Transactions}
                changeDates={this.changeDates}
                from={this.state.from}
                to={this.state.to}
                {...this.props}
              />
            )}
          />

          <Route
            exact
            path={`/payment`}
            component={() => (
              <Structure
                component={Payment}
                changeDates={this.changeDates}
                from={this.state.from}
                to={this.state.to}
                {...this.props}
              />
            )}
          />

          <Route
            exact
            path={`/payment/subscription`}
            component={() => (
              <Structure
                component={Subscription}
                changeDates={this.changeDates}
                from={this.state.from}
                to={this.state.to}
                {...this.props}
              />
            )}
          />

          <Route exact path={`/partners/feedaty`} component={() => <Structure component={Feedaty} {...this.props} />} />

          <Route
            exact
            path={`/google-shopping`}
            component={() => <Structure component={GShopping} {...this.props} />}
          />

          <Route exact path={`/partners/qapla`} component={() => <Structure component={Qapla} {...this.props} />} />

          <Route
            exact
            path={`/payperclick`}
            component={() => (
              <Structure
                component={PayPerClick}
                changeDates={this.changeDates}
                from={this.state.from}
                to={this.state.to}
                {...this.props}
              />
            )}
          />

          <Route
            exact
            path={`/integrations/analytics`}
            component={() => (
              <Structure
                component={AnalyticsIntegration}
                changeDates={this.changeDates}
                from={this.state.from}
                to={this.state.to}
                {...this.props}
              />
            )}
          />

          <Route
            exact
            path={`/integrations/productsTrack`}
            component={() => (
              <Structure
                component={ProductsTrack}
                changeDates={this.changeDates}
                from={this.state.from}
                to={this.state.to}
                {...this.props}
              />
            )}
          />

          <Route
            exact
            path={`/integrations/back-link`}
            component={() => (
              <Structure
                component={BackLinkImage}
                changeDates={this.changeDates}
                from={this.state.from}
                to={this.state.to}
                {...this.props}
              />
            )}
          />

          <Route
            exact
            path={`/integrations/prestashopIntegration`}
            component={() => (
              <Structure
                component={PrestashopIntegration}
                changeDates={this.changeDates}
                from={this.state.from}
                to={this.state.to}
                {...this.props}
              />
            )}
          />

          <Route
            exact
            path={`/integrations/Tagmanager`}
            component={() => (
              <Structure
                component={TagManager}
                changeDates={this.changeDates}
                from={this.state.from}
                to={this.state.to}
                {...this.props}
              />
            )}
          />

          <Redirect from="/" to="/dashboard" />
        </Switch>
      </Router>
    );
  }
}

export default Container;
