import React from 'react';
import { Route, Redirect } from 'react-router-dom';

// import _ from 'lodash';

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => {
    const _props = window._.merge(props, rest);
    return (
      !!localStorage.getItem('auth')
        ? <Component {..._props} />
        : <Redirect to={{ pathname: '/user/login', state: { from: props.location } }} />
    )
  }} />
)
