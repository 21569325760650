import React, { Component } from 'react';


export default class Details extends Component {
  render() {
    return(
      <div className="row">
        { !!this.props.product.codice_ministeriale 
        ?
        <div className="col-12">
          <img alt={this.props.product.nome_prodotto}
            className="img-fluid float-left mR-30"
            src={`https://static.fogliettoillustrativo.net/ws/it/${this.props.product.nome_immagine}`}
            />
          <span>{this.props.product.nome_prodotto }</span>
          <span className="small">&nbsp; ({ this.props.product.codice_ministeriale })</span>
          <br/>          
          <span className="small">{ this.props.product.descrizione_ditta_1_produttrice }</span><br/>
          <a target='_blank' rel="nofollow noopener noreferrer" href={'https://www.prezzifarmaco.it/'+this.props.product.codice_ministeriale} > Vedi su PrezziFarmaco.it</a>
        </div>
        : ''
        }

      </div>
    );
  }
}