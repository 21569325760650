import React, { Component } from 'react';
//import { Link } from 'react-router-dom'; 
import { Alert } from 'reactstrap';

import { integrationService } from '../../_services/integration.service';


class TagManager extends Component {

  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      integration: false,
      checking: false,
    }

    this.checkIntegration = this.checkIntegration.bind(this);
  }

  async checkIntegration() {
    try {
      this.setState({checking: true});
      const response = await integrationService.productsTrack(this.props.user.id, this.props.shop.id);
      this.setState({ integration: response.integrated });
      this.setState({checking: false});
    } catch (error) {
      this.setState({ integration: false }); 
      this.setState({checking: false});
    }
  }

  async componentDidMount() {
    this._isMounted = true;
    if (!!this.props.user.id && !!this.props.shop.id) {      
      await this.checkIntegration();      
    }
    window.removeLoader();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <div>
        {
          !!!this.props.shop.id
            ? <Alert color="warning"><i className="fas fa-surprise"/> Non hai ancora un negozio associato.</Alert>
            : null
        }
        {
          this.state.checking
            ? <Alert color="info"><i className="fas fa-spinner fa-spin"/> Aggiornamento informazioni.</Alert>
            : !!this.state.integration
              ? <Alert color="success"><i className="fas fa-check" /> Il tuo sito ha questa funzionalità integrata.</Alert>
              : <Alert color="warning"><i className="fas fa-surprise" /> Sembra che il tuo sito non implementi ancora questa funzionalità perchè non abbiamo ricevuto ordini nell'ultima settimana</Alert>
        }        
        <div className="bgc-white bd bdrs-3 p-20 mB-20 ">
          <div className="row">
          {!!this.props.shop.id 
            ? <div className="col-12">
                <h1>Tracciamento Acquisti con Googler TagManager<img className="img-fluid" alt="Googler TagManager" src="/static/images/integrations/gtm.png" /></h1>
                <p>Questa guida consente di attivare il tracciamento delle conversioni in pochi click attraverso google tag manager</p>
                <p>
                  <br/>
                  <a className="btn-info btn btn-sm" href="/static/Prezzifarmaco Tracking con GTM v1.1.pdf" >Scarica la guida in PDF</a>                  
                </p>          
              </div>
          : <div className="col-12">
              <Alert color="warning"><i className="fas fa-surprise"/> Non hai ancora un negozio associato.</Alert>
            </div> 
          }
          </div>
        </div>
        <div className="bgc-white bd bdrs-3 p-20 mB-20 ">
          <div className="row">
            <div className="col-12">
              <h5>Informativa sui Cookie dei servizi di Analytics e Traking di Prezzifarmaco</h5>
              <p>Non preleviamo informazioni di profilazione in alcun modo e non vengono eseguite azioni di profilazione. I dati raccolti sono utilizzati solo a fini statistici in forma aggregata.<br/>
              <br/>
              Il nostro cookie può essere considerato come cookie tecnico di tipo "analytics" secondo la seguente definizione.<br/>
              Cookie analytics: “assimilati ai cookie tecnici laddove utilizzati direttamente dal gestore del sito per raccogliere informazioni, in forma aggregata, sul numero degli utenti e su come questi visitano il sito stesso”<br/>
              <br/>
              Entrambi gli script ( analytics e tracking conversioni ) possono essere eseguiti anche senza consenso dell'utente in quanto eseguono funzioni di marketing o profilazione.<br/>
              Non preleviamo informazioni sull'indirizzo IP del visitatore, il nostro script anonimizza automaticamente  l'indirizzo IP.<br/>
              Cookie gestiti da analytics.prezzifarmaco.it sono denominati: SUI, UID.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TagManager;
