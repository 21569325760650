import { authHeader } from '../_helpers/auth-header';
import Resource from '../_helpers/resource';
const resource = new Resource(process.env.REACT_APP_API_URL);

export const productsService = {
  
  get: (id, from, to, limit) => {
    let api = `products/${id}`;
    let queryString = new URLSearchParams();

    if (!!from) {
      queryString.set('from', from);
    }

    if (!!to) {
      queryString.set('to', to);
    }

    if (!!limit) {
      queryString.set('limit', limit);
    }

    api = `${api}?${queryString.toString()}`; 

    return resource.get(
      api,
      authHeader()
    );
  },

  getProductPrice: (userId, shopId, productId) => {
    let api = `user/${userId}/shop/${shopId}/products/${productId}`;

    return resource.get(
      api,
      authHeader()
    );
  },


}
