import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Table, Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { PaymentButton } from './PaymentButton';
import { creditService } from '../../_services/credit.service';
import { paymentService } from '../../_services/payment.service';
import { paymentInvoiceService } from '../../_services/paymentInvoice.service';

class Payment extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;

    this.state = {
      budget: undefined,
      credits: undefined,
      reloading: false,
      history: undefined,
      paymentStatus: null,
      subsriptions: null,
      isModalOpen: false,
      paymentId: null,
    };

    this.toggle = this.toggle.bind(this);
    this.handleBudget = this.handleBudget.bind(this);
    this.paymentMethod = this.paymentMethod.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.finalizePayment();

    if (this.props.user.id && !this.state.credits) {
      await this.getUserCredits();

      try {
        const { data: history } = await creditService.history(this.props.user.id);
        let historyCalc = history.reduce((acc, val) => acc + val.quantity, 0);

        if (this._isMounted) {
          this.setState({ history });
          this.setState({ historyCalc });
        }
      } catch (error) {
        console.error('error:', error);
      }

      try {
        const subsriptions = await paymentService.findOneSubsription();
        this.setState({ subsriptions });
      } catch (error) {
        console.error('error:', error);
      }

      window.removeLoader();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async toggle(e) {
    this.setState({ isModalOpen: !this.state.isModalOpen });
    this.setState({ paymentId: null });
  }

  async finalizePayment() {
    try {
      const wildCards = new URLSearchParams(this.props.location.search);
      const paymentMethod = wildCards.get('paymentMethod');
      const paymentStatus = wildCards.get('status');
      const subscriptionId = wildCards.get('subscription_id');
      const userId = this.props.user.id;
      let paymentId;

      if (paymentMethod === 'paypal') {
        if (!!!subscriptionId) {
          paymentId = wildCards.get('token');
        }
      }

      if (!!paymentId && userId) {
        await paymentService.payment(userId, paymentMethod, paymentId);
      }

      if ((!!paymentId && userId) || subscriptionId) {
        switch (paymentStatus) {
          case 'success':
            if (!this.props.notify.isActive('payment-success')) {
              this.props.notify.success(
                `Grazie per aver effettuato il pagamento. La transazione è stata completata e sarà elaborata il prima possibile.`,
                { toastId: 'payment-success' }
              );
            }

            break;
          case 'cancel':
            this.props.notify.error(`Il pagamento è stato rifiutato. La transazione non è andata a buon fine.`);
            break;
          default:
            this.props.notify.warn(`La transazione è stata inviata e sarà elaborata il prima possibile.`);
            break;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async handleBudget(event) {
    this.setState({ budget: !!event.target.value && event.target.value });
  }

  async getUserCredits() {
    if (this._isMounted) {
      this.setState({ reloading: true });

      try {
        let response = await creditService.amount();
        this.setState({ credits: response.amount });
      } catch (error) {
        console.error(error);
      }

      this.setState({ reloading: false });
    }
  }

  paymentMethod(method) {
    switch (method.provider) {
      case 'paypal':
        return 'Ricarica eseguita con Paypal.';
      default:
        if (!method.data) return '-';
        if (method.data.length <= 15) return '-';
        let data = JSON.parse(method.data);
        return !!data.description ? data.description : method.provider;
    }
  }

  render() {
    const { user, notify } = this.props;
    const { credits, budget, history, historyCalc, subsriptions, isModalOpen, paymentId } = this.state;

    const ResocontoCrediti = () => {
      return (
        <div className="col-md-6 mT-25">
          <div className="card">
            <h5 className="card-header">Resoconto crediti</h5>
            <div className="card-body">
              <div className="row">
                <div className="col-3">
                  <strong>Conto: </strong>
                </div>
                <div className="col-9 text-right">
                  {credits ? credits.toFixed(2) : 0} € &nbsp;
                  <button onClick={(e) => this.getUserCredits()} type="button" className="btn btn-sm">
                    <i className={`fas fa-redo`}></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };

    const AggiungiCredito = () => {
      return (
        <div className="col-md-6 mT-25">
          <div className="card">
            <h5 className="card-header">Aggiungi credito</h5>
            <div className="card-body">
              <div className="row">
                <label htmlFor="addBudget" className="col-2 col-form-label">
                  <strong>Importo:</strong>
                </label>

                <div className="col-10">
                  <input
                    id="addBudget"
                    className="custom-select"
                    name="addBudget"
                    type="number"
                    placeholder="10.00"
                    defaultValue={budget}
                    onChange={this.handleBudget}
                    min="10"
                    step="0.01"
                    aria-describedby="budget"
                    required="required"
                  />
                  <span id="budget" className="form-text text-muted">
                    Importo da ricaricare. Importo minimo 10€.
                  </span>
                </div>

                <div className="col-10 offset-2 text-right mT-5">
                  <PaymentButton
                    description={`Ricarca credito ${budget}€.`}
                    tier="credits"
                    quantity={1}
                    unitPrice={parseFloat(budget)}
                    disabled={!!!budget || budget < 10}
                    user={user}
                    history={this.props.history}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };

    const ButtonForTaxDetail = () => {
      return (
        <div className="bgc-white bd bdrs-3">
          <div className="mx-auto my-5" style={{ width: '323px' }}>
            <Link to="/shop/tax-detail">
              <button className="btn btn-success p-15" type="button">
                Gestisci qui i dati fiscali e di fatturazione
              </button>
            </Link>
          </div>
        </div>
      );
    };

    const Subscription = () => {
      return (
        <div className="row card mT-25 mL-1 mR-1">
          <div className="col-md-12 mT-10">
            <div className="row">
              <div className="col-12">
                <h5>Pagamenti ricorsivi</h5>
                <div>
                  {subsriptions && subsriptions.length > 0 ? (
                    <>
                      <Table id="dailyClickTable" bordered striped hover responsive>
                        <thead>
                          <tr>
                            <th>Data sottoscrizione</th>
                            <th>Importo</th>
                            <th>Cadenza</th>
                            <th>Prossimo pagamento</th>
                            <th>Azioni</th>
                          </tr>
                        </thead>

                        <tbody>{subsriptions.map((subsription, key) => DisplaySubsriptions(subsription, key))}</tbody>
                      </Table>
                      <small class="form-text text-muted">
                        * La sottoscrizione di un nuovo abbonamento, cancellerà automaticamente quello attivo.
                      </small>
                      <small class="form-text text-muted">
                        ** È possibile effettuare una ricarica normale anche se si ha un abbonamento attivo.
                      </small>
                    </>
                  ) : (
                    <h6 className="p-10">
                      Non ci sono pagamenti ricorsivi Attivi. Togliti il pensiero ed attivane uno!
                    </h6>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };

    const DownloadInvoice = async (user, invoiceId) => {
      if (user && invoiceId) {
        try {
          const invoice = await paymentInvoiceService.get(user, invoiceId);
          const link = document.createElement('a');
          link.href = invoice.file.data;
          link.download = invoice.file.name;
          link.click();
        } catch (error) {
          console.error(error);
          notify.error(`Questa fattura ancora non è stata caricata, se il problema persiste contatta l'assistenza!`);
        }
      }
    };

    const DisplaySubsriptions = (subsription, key) => {
      const { time_created, next_payment_date, amount, payment_cycle, recurring_payment_id } =
        subsription.recurring.data;

      return (
        <tr key={key}>
          <td>{moment(time_created).format('DD/MM/YYYY h:mm')}</td>
          <td>{Number(amount).toFixed(2)} €</td>
          <td>
            {payment_cycle === 'Daily' && 'Giornaliera'}
            {payment_cycle === 'Weekly' && 'Settimanale'}
            {payment_cycle === 'Monthly' && 'Mensile'}
          </td>
          <td>{moment(next_payment_date).format('DD/MM/YYYY h:mm')}</td>
          <td>
            <Link className="text-danger" to="#" onClick={() => openUnsubscribeModel(recurring_payment_id)}>
              Disdici
            </Link>
          </td>
        </tr>
      );
    };

    const openUnsubscribeModel = (paymentId) => {
      this.setState({ isModalOpen: true });
      this.setState({ paymentId });
    };

    const unsubscribe = async () => {
      if (paymentId) {
        try {
          await paymentService.deleteSubsription(this.props.user.id, { paymentId: paymentId });
          this.props.notify.success(
            `La richiesta di cancellazione è stata inoltrata con successo e sarà elaborata il prima possibile.`
          );
        } catch (error) {
          this.props.notify.error(
            `C'è stato un problema nella cancellazione del pagamento ricorsivo, riprova fra qualche minuto!`
          );
        } finally {
          this.toggle();
        }
      }
    };

    const DisplayHistory = (hist, key) => {
      const { createdAt, quantity, provider, id } = hist;

      return (
        <tr key={key}>
          <td>{moment(createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
          <td>{quantity.toFixed(2)} €</td>
          <td>{this.paymentMethod(hist)}</td>
          <td>
            {provider === 'system' || provider === 'paypal' ? (
              <Link to="#" onClick={() => DownloadInvoice(user.id, id)}>
                Scarica
              </Link>
            ) : (
              '-'
            )}
          </td>
        </tr>
      );
    };

    const CustomTable = () => {
      return (
        <Table id="dailyClickTable" bordered striped hover responsive>
          <thead>
            <tr>
              <th>Data</th>
              <th>Importo</th>
              <th>Note</th>
              <th>Fatture</th>
            </tr>
          </thead>
          <tbody>{history.map((hist, key) => DisplayHistory(hist, key))}</tbody>
          <thead>
            <tr>
              <th></th>
              <th>{historyCalc ? historyCalc.toFixed(2) : 0} €</th>
              <th>Credito Residuo</th>
              <th></th>
            </tr>
          </thead>
        </Table>
      );
    };

    const UnsubscribeModel = () => {
      return (
        <Modal isOpen={isModalOpen} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Sei sicuro di voler annullare l'abbonamento?</ModalHeader>
          <ModalBody>
            <p>Con un abbonamento attivo è comunque possibile effettuare una ricarica normale.</p>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={unsubscribe}>
              Disdici
            </Button>
            <Button className="btn btn-block" color="success" onClick={this.toggle}>
              Annulla
            </Button>
          </ModalFooter>
        </Modal>
      );
    };

    return (
      <div>
        <ButtonForTaxDetail />
        <div className="row">
          <ResocontoCrediti />
          {AggiungiCredito()} {/* MUST be like this or it will cause the rerendere of the input every char insert */}
        </div>
        <Subscription />
        <UnsubscribeModel />
        <div className="row card mT-25 mL-1 mR-1">
          <div className="col-md-12 mT-10">
            <div className="row">
              <div className="col-12">
                <h5>Elenco movimenti </h5>
                {!!history && history.length > 0 ? (
                  <CustomTable />
                ) : (
                  <div className="m-10">
                    <Alert color="warning">Storico non presente</Alert>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Payment;
