import React from 'react';

export default (props) => (
  <div className="bgc-white bd bdrs-3 p-20">
    <div className="row">

      <div className="col">
        <div className={` mR-10 mT-10
          p-5 text-white text-center border rounded
          border-${!!props.color ? props.color : 'primary'}
          bg-${!!props.color ? props.color : 'primary'}
          `}
          style={{
            width: '34px',
            float: 'left'
            // minHeight: '20px',
            // marginRight: '7px'
          }}
        >
          <i className={`fas fa-${props.icon} fa-lg`}></i>
        </div>
        <div 
        style={{
          // minHeight: '20px',
          // marginRight: '7px'
        }}
        >
          { props.children}
        </div>
      </div>

    </div>    
  </div>
);