import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// import Helmet from 'react-helmet';
// import DayPicker, { DateUtils } from 'react-day-picker';
// import 'react-day-picker/lib/style.css';

import moment from 'moment';

import { couponService } from '../../_services/coupon.service';

class CouponElementEdit extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      // title: this.props.coupon.title ? this.props.coupon.title : undefined,
      code: this.props.coupon.code ? this.props.coupon.code : undefined,
      text: this.props.coupon.text ? this.props.coupon.text : undefined,
      description: this.props.coupon.description ? this.props.coupon.description : undefined,
      discount: this.props.coupon.discount ? this.props.coupon.discount : undefined,
      active: this.props.coupon.active ? this.props.coupon.active : undefined,
      url: this.props.coupon.url ? this.props.coupon.url : this.props.shop.base_url,
      from: this.props.coupon.from ? moment.utc(this.props.coupon.from).toDate() : undefined,
      to: this.props.coupon.to ? moment.utc(this.props.coupon.to).toDate() : undefined,
      locale: 'it',
      modal: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleErrorForm = this.handleErrorForm.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);

    this.submitIsDisabled = this.submitIsDisabled.bind(this);

    this.changeDates = this.changeDates.bind(this);
    this.deleteCoupon = this.deleteCoupon.bind(this);

    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.inizializeDataRangePicker();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  inizializeDataRangePicker() {
    window.moment.locale('it');
    window.$('#reportrange').daterangepicker(
      {
        // minDate: moment(),
        startDate: this.state.from,
        endDate: this.state.to,
        locale: {
          format: 'DD/MM/YYYY',
          separator: ' - ',
          applyLabel: 'Applica',
          cancelLabel: 'Cancella',
          fromLabel: 'Da',
          toLabel: 'A',
          customRangeLabel: 'Personalizzato',
          firstDay: 1,
        },
      },
      this.changeDates
    );
  }

  changeDates(start, end) {
    this.setState({ from: moment(start).endOf('day').valueOf() });
    this.setState({ to: moment(end).endOf('day').valueOf() });
  }

  submitIsDisabled(form, force = false) {
    for (let button of form.querySelectorAll('button[type=submit]')) {
      button.disabled = !(form.checkValidity() && !!this.state.from && !!this.state.to) || force;
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });

    // this.handleErrorForm(event.target, event.target.parentElement);
    this.props.setCoupon(event.target.name, event.target.value);
  }

  handleErrorForm(element, parent) {
    for (let message of parent.querySelectorAll('.validation-message')) {
      parent.removeChild(message);
    }

    if (element.validationMessage) {
      const message = document.createElement('small');
      message.setAttribute('class', 'validation-message text-danger');
      message.innerHTML = element.validationMessage;
      parent.appendChild(message);
    }
  }

  handleSwitch(elem) {
    this.setState({
      [window.event.target.name]: window.event.target.checked,
    });

    // this.handleErrorForm(window.event.target, window.event.target.parentElement);
  }

  handleSubmit(e) {
    e.preventDefault();

    if (!!!this.state.from || !!!this.state.to) {
      this.props.notify.error('Inserire una data valida.');
      return;
    }

    const coupon = {
      // title: this.state.title,
      code: this.state.code,
      text: this.state.text,
      description: this.state.description,
      discount: this.state.discount,
      url: this.state.url,
      active: this.state.active,
      from: moment(this.state.from).valueOf(),
      to: moment(this.state.to).valueOf(),
    };

    let resource = () => couponService.create(this.props.user.id, this.props.shop.id, coupon);

    if (this.props.id) {
      resource = () => couponService.update(this.props.user.id, this.props.shop.id, this.props.id, coupon);
    }

    resource()
      .then((coupon) => {
        this.props.updateCoupon(coupon);
        this.props.notify.success('Coupon aggiornato.');
      })
      .catch((error) => {
        console.error(error);
        this.props.notify.error('Non è stato possibile aggiornare il coupon.');
      });
  }

  deleteCoupon() {
    couponService.delete(this.props.user.id, this.props.shop.id, this.props.id).then(() => {
      this.props.deleteCoupon(this.props.id);
    });
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    // const { coupon } = this.props;

    return (
      // onChange={ e => this.submitIsDisabled(e.target.form) }
      <form onSubmit={this.handleSubmit} id="couponFrom">
        <div className="row mT-20">
          <div className="col-12">
            {this.props.id ? (
              <h3 className="text-success">Aggiorna il tuo coupon</h3>
            ) : (
              <h3 className="text-success">Crea il tuo coupon</h3>
            )}
          </div>

          <div className="col-12 mT-10">
            {/* <div className="form-group row">
              <label htmlFor="title" className="col col-form-label">
                Titolo coupon
              </label>
              <div className="col-10">
                <input
                  name="title"
                  type="text"
                  className="form-control"
                  id="title"
                  required
                  placeholder="Titolo coupon"
                  maxLength="30"
                  size="30"
                  defaultValue={this.state.title}
                  onChange={(e) => this.handleChange(e)}
                />
                <small className="form-text text-muted">Il titolo del coupon.</small>
              </div>
            </div> */}

            <div className="form-group row">
              <label htmlFor="text" className="col col-form-label">
                Titolo coupon
              </label>
              <div className="col-10">
                <input
                  name="text"
                  type="text"
                  className="form-control"
                  id="text"
                  required
                  placeholder="Titolo coupon"
                  size="30"
                  maxLength="255"
                  defaultValue={this.state.text}
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                />
                <small className="form-text text-muted">Il titolo del coupon.</small>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="description" className="col col-form-label">
                Descrizione coupon
              </label>
              <div className="col-10">
                <input
                  name="description"
                  type="text"
                  className="form-control"
                  id="description"
                  required
                  placeholder="Descrizione coupon"
                  size="30"
                  maxLength="255"
                  defaultValue={this.state.description}
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                />
                <small className="form-text text-muted">La descrizione del coupon.</small>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="discount" className="col col-form-label">
                Sconto coupon
              </label>
              <div className="col-10">
                <input
                  name="discount"
                  type="text"
                  className="form-control"
                  id="discount"
                  required
                  placeholder="Sconto coupon"
                  size="30"
                  maxLength="5"
                  defaultValue={this.state.discount}
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                />
                <small className="form-text text-muted">Esempio 15% oppure 20€.</small>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="code" className="col col-form-label">
                Codice coupon
              </label>
              <div className="col-10">
                <input
                  name="code"
                  type="text"
                  className="form-control"
                  id="code"
                  required
                  placeholder="Codice coupon"
                  size="30"
                  maxLength="20"
                  defaultValue={this.state.code}
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                />
                <small className="form-text text-muted">Il codice del coupon.</small>
              </div>
            </div>

            {/* <div className="form-group row">
              <label htmlFor="text" className="col col-form-label">
                Attivazione
              </label>
              <div className="col-8 pT-5 text-left">
                <span className="switch switch-sm">
                  <input
                    type="checkbox"
                    className="switch"
                    id={`switch_${this.props.id}`}
                    name="active"
                    defaultChecked={coupon.active}
                    onChange={(e) => this.handleSwitch(e)}
                  />
                  <label htmlFor={`switch_${this.props.id}`}></label>
                  {this.state.active ? (
                    <small className="form-text text-muted">Clicca per disattivare.</small>
                  ) : (
                    <small className="form-text text-muted">Clicca per attivare.</small>
                  )}
                </span>
              </div>
              <div className="col-2 pT-10 text-right">
                {this.state.active ? (
                  <small className="text-success">il coupon è attivo.</small>
                ) : (
                  <small className="text-muted">il coupon è disattivato.</small>
                )}
              </div>
            </div> */}

            <div className="form-group row">
              <label htmlFor="from" className="col col-form-label">
                Periodo attività
              </label>
              <div className="col-10">
                <div
                  id="reportrange"
                  style={{
                    background: '#fff',
                    cursor: 'pointer',
                    padding: '5px 10px',
                    border: '1px solid #ccc',
                    width: '100%',
                  }}
                >
                  {/* <input type="text" required name="daterange" /> */}
                  <i className="fa fa-calendar"></i>&nbsp;
                  {/* <span>
                    {moment(this.state.date.from).format('DD/MM/YYYY')} -{' '}
                    {moment(this.state.date.to).format('DD/MM/YYYY')}
                  </span>{' '} */}
                  <span>
                    {this.state.from ? moment(this.state.from).format('DD/MM/YYYY') : ''}
                    &nbsp;
                    {this.state.to ? moment(this.state.to).format('DD/MM/YYYY') : ''}
                  </span>{' '}
                  <i className="fa fa-caret-down"></i>
                </div>

                <small className="form-text text-muted">Indica il periodo in cui la campagna è attiva.</small>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="base_url" className="col col-form-label">
                Link coupon
              </label>
              <div className="col-10">
                <input
                  name="url"
                  type="url"
                  className="form-control"
                  id="url"
                  required
                  placeholder="Indirizzo coupon"
                  pattern="^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$"
                  size="30"
                  defaultValue={this.state.url}
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                />
                <small className="form-text text-muted">L'indirizzo a cui punta il coupon.</small>
              </div>
            </div>

            <div className="row mT-20">
              {this.props.id ? (
                <div className="col text-left">
                  <Button outline color="danger" onClick={this.toggle}>
                    Rimuovi
                  </Button>

                  <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Conferma rimozione coupon</ModalHeader>
                    <ModalBody>Confermi di voler rimuovere il coupon "{this.state.code}"?</ModalBody>
                    <ModalFooter>
                      <Button outline color="secondary" onClick={this.toggle}>
                        Annulla
                      </Button>
                      <Button color="danger" onClick={this.deleteCoupon}>
                        Conferma
                      </Button>{' '}
                    </ModalFooter>
                  </Modal>
                </div>
              ) : null}

              <div className="col text-right">
                {!this.props.force ? (
                  <Button outline color="secondary" onClick={this.props.closeCoupon}>
                    Annulla
                  </Button>
                ) : null}
                &nbsp;
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={false}
                  onClick={(event) => this.handleErrorForm(event.target, event.target.parentElement)}
                >
                  {this.props.id ? 'Aggiorna' : 'Crea'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default CouponElementEdit;
